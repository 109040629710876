import { useRef } from "react";
import {
    useNavigationType,
    useSearchParams,
    NavigationType,
} from "react-router-dom";

export function useIsBackButtonNavigation() {
    const navType = useNavigationType();
    const [searchParams] = useSearchParams();
    const isBackButtonNavigation = useRef(false);
    const urlParamsRef = useRef(searchParams.toString());

    // Only notice back button navigations if url params change as well.
    // Because we only want to process back button once per press.
    isBackButtonNavigation.current =
        urlParamsRef.current !== searchParams.toString() &&
        navType === NavigationType.Pop;

    urlParamsRef.current = searchParams.toString();

    return isBackButtonNavigation.current;
}
