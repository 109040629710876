/**
 * @generated SignedSource<<c787f412d4d0e83820a45dc15fc7bb06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WallboardQuery$variables = {
  backup: boolean;
};
export type WallboardQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HeatTiles_heatTiles" | "ToggleMenu" | "Wallboard_bars">;
};
export type WallboardQuery = {
  response: WallboardQuery$data;
  variables: WallboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "backup"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "backup",
    "variableName": "backup"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ujID",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WallboardQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "Wallboard_bars"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ToggleMenu"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "HeatTiles_heatTiles"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WallboardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserPreferencesUserJourneyGroup",
                "kind": "LinkedField",
                "name": "userJourneyGroups",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "groupID",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hidden",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ujIDs",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showThirdPartyWarnings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showThirdPartyErrors",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Permissions",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deny",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserJourney",
        "kind": "LinkedField",
        "name": "userJourneys",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platformType",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UJHelper",
            "kind": "LinkedField",
            "name": "helper",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "testLabel",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocalState",
            "kind": "LinkedField",
            "name": "localState",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "LocalSamples",
                "kind": "LinkedField",
                "name": "samples",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecentSample",
                    "kind": "LinkedField",
                    "name": "recent",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timestamp",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "allThirdPartyNoCustomerImpact",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "extraWarningsAllThirdPartyNoCustomerImpact",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "inPME",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pageDeliveryTotal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "helperTarget",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "eda3546af818e6093879e002c07f163a",
    "id": null,
    "metadata": {},
    "name": "WallboardQuery",
    "operationKind": "query",
    "text": "query WallboardQuery {\n  ...Wallboard_bars_chi13\n  ...ToggleMenu\n  ...HeatTiles_heatTiles_chi13\n}\n\nfragment HeatTiles_heatTiles_chi13 on Query {\n  userJourneys {\n    ujID\n    helper {\n      testLabel\n      id\n    }\n    id\n  }\n}\n\nfragment ToggleMenu on Query {\n  currentUser {\n    permissions {\n      allow\n      deny\n    }\n    id\n  }\n  ...urlParams_thirdPartyToggleDefaults\n}\n\nfragment Wallboard_bars_chi13 on Query {\n  currentUser {\n    ...userJourneys_userPreferences\n    id\n  }\n  userJourneys {\n    ujID\n    status\n    ...Wallboard_userJourneyRow\n    id\n  }\n  ...urlParams_thirdPartyToggleDefaults\n}\n\nfragment Wallboard_userJourneyRow on UserJourney {\n  ujID\n  name\n  platformType\n}\n\nfragment urlParams_thirdPartyToggleDefaults on Query {\n  currentUser {\n    customer {\n      showThirdPartyWarnings\n      showThirdPartyErrors\n      id\n    }\n    id\n  }\n}\n\nfragment userJourneys_userPreferences on User {\n  preferences {\n    userJourneyGroups {\n      groupID\n      name\n      hidden\n      ujIDs\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e580ba9f1a64e393656535bc3399681";

export default node;
