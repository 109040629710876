/**
 * @generated SignedSource<<754504991bab95e5cc16f1f654002cc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Header_navigation$data = {
  readonly currentUser: {
    readonly impersonator: string | null;
    readonly isAdmin: boolean;
    readonly permissions: {
      readonly allow: ReadonlyArray<string>;
      readonly deny: ReadonlyArray<string>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DropdownMenus_UJPageMenu" | "Header_PossibleImpersonations" | "Header_user" | "UJMenu_user">;
  };
  readonly userJourneys: ReadonlyArray<{
    readonly ujID: number;
    readonly " $fragmentSpreads": FragmentRefs<"Header_SelectedUJCustomerName" | "Header_SelectedUJToolkit" | "UJMenu_UJs">;
  }>;
  readonly " $fragmentType": "Header_navigation";
};
export type Header_navigation$key = {
  readonly " $data"?: Header_navigation$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_navigation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Header_navigation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdmin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "impersonator",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permissions",
          "kind": "LinkedField",
          "name": "permissions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allow",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deny",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Header_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UJMenu_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DropdownMenus_UJPageMenu"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Header_PossibleImpersonations"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserJourney",
      "kind": "LinkedField",
      "name": "userJourneys",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ujID",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UJMenu_UJs"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Header_SelectedUJToolkit"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Header_SelectedUJCustomerName"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "fab0b2d0689d45afdaa0c6c9cefd6b63";

export default node;
