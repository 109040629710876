/**
 * @generated SignedSource<<136e2ac492c264d916ffd4f1dcc0692f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Header_SelectedUJToolkit$data = ReadonlyArray<{
  readonly name: string;
  readonly ujID: number;
  readonly " $fragmentType": "Header_SelectedUJToolkit";
}>;
export type Header_SelectedUJToolkit$key = ReadonlyArray<{
  readonly " $data"?: Header_SelectedUJToolkit$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_SelectedUJToolkit">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Header_SelectedUJToolkit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ujID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "UserJourney",
  "abstractKey": null
};

(node as any).hash = "0aa71c78e3edc1f8a454c3dcf42999e8";

export default node;
