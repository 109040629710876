/**
 * @generated SignedSource<<108b1d7941fa0258b2af185207faf8ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SampleStatus = "DEBUG" | "ERROR" | "OK" | "SLOW" | "UNKNOWN" | "WARNING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type graphUtils_preparedSamples$data = {
  readonly samples: ReadonlyArray<{
    readonly allThirdPartyNoCustomerImpact: boolean;
    readonly extraWarningsAllThirdPartyNoCustomerImpact: boolean | null;
    readonly status: SampleStatus;
    readonly timestamp: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"graphUtils_pmeWindows">;
  readonly " $fragmentType": "graphUtils_preparedSamples";
};
export type graphUtils_preparedSamples$key = {
  readonly " $data"?: graphUtils_preparedSamples$data;
  readonly " $fragmentSpreads": FragmentRefs<"graphUtils_preparedSamples">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "backup"
    },
    {
      "kind": "RootArgument",
      "name": "timeRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "graphUtils_preparedSamples",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "graphUtils_pmeWindows"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "backup",
          "variableName": "backup"
        },
        {
          "kind": "Literal",
          "name": "pmeOn",
          "value": false
        },
        {
          "kind": "Variable",
          "name": "timeRange",
          "variableName": "timeRange"
        }
      ],
      "concreteType": "BasicSample",
      "kind": "LinkedField",
      "name": "samples",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allThirdPartyNoCustomerImpact",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "extraWarningsAllThirdPartyNoCustomerImpact",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserJourney",
  "abstractKey": null
};

(node as any).hash = "955f476ff142a73b3c073f8759378ace";

export default node;
