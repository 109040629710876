/**
 * @generated SignedSource<<8aa2cfd70f0d7890bf20167bc35ca9cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SimpleMutationResult = "OK" | "%future added value";
export type loggingLogErrorMutation$variables = {
  componentStack?: string | null;
  message: string;
  stack?: string | null;
  url: string;
};
export type loggingLogErrorMutation$data = {
  readonly logError: SimpleMutationResult | null;
};
export type loggingLogErrorMutation = {
  response: loggingLogErrorMutation$data;
  variables: loggingLogErrorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "componentStack"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "message"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stack"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "url"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "componentStack",
        "variableName": "componentStack"
      },
      {
        "kind": "Variable",
        "name": "message",
        "variableName": "message"
      },
      {
        "kind": "Variable",
        "name": "stack",
        "variableName": "stack"
      },
      {
        "kind": "Variable",
        "name": "url",
        "variableName": "url"
      }
    ],
    "kind": "ScalarField",
    "name": "logError",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "loggingLogErrorMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "loggingLogErrorMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "490634b8396e6704d53e97d2ce9092aa",
    "id": null,
    "metadata": {},
    "name": "loggingLogErrorMutation",
    "operationKind": "mutation",
    "text": "mutation loggingLogErrorMutation(\n  $url: String!\n  $message: String!\n  $stack: String\n  $componentStack: String\n) {\n  logError(url: $url, message: $message, stack: $stack, componentStack: $componentStack)\n}\n"
  }
};
})();

(node as any).hash = "b9a398bf485c4376861bbc34538c80ab";

export default node;
