/**
 * @generated SignedSource<<8ea1ed24384eabc9eb11679a2d001122>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntercomBooter_data$data = {
  readonly currentUser: {
    readonly customer: {
      readonly customerID: number;
      readonly name: string;
    };
    readonly displayName: string;
    readonly email: string | null;
    readonly intercomUserHash: string;
    readonly login: string | null;
    readonly roleID: string;
    readonly userID: number;
  };
  readonly environment: string;
  readonly impersonatorUser: {
    readonly customer: {
      readonly customerID: number;
      readonly name: string;
    };
    readonly displayName: string;
    readonly email: string | null;
    readonly intercomUserHash: string;
    readonly login: string | null;
    readonly roleID: string;
    readonly userID: number;
  } | null;
  readonly " $fragmentType": "IntercomBooter_data";
};
export type IntercomBooter_data$key = {
  readonly " $data"?: IntercomBooter_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"IntercomBooter_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "login",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userID",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "roleID",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "intercomUserHash",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Customer",
    "kind": "LinkedField",
    "name": "customer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IntercomBooter_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "impersonatorUser",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "environment",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "884e3a598fa5f1368d4b247fcd85815e";

export default node;
