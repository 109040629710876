/**
 * @generated SignedSource<<4c078505096eadfe98bef85216a3dc60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Wallboard_userJourneyRow$data = {
  readonly name: string;
  readonly platformType: string;
  readonly ujID: number;
  readonly " $fragmentType": "Wallboard_userJourneyRow";
};
export type Wallboard_userJourneyRow$key = {
  readonly " $data"?: Wallboard_userJourneyRow$data;
  readonly " $fragmentSpreads": FragmentRefs<"Wallboard_userJourneyRow">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Wallboard_userJourneyRow",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ujID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platformType",
      "storageKey": null
    }
  ],
  "type": "UserJourney",
  "abstractKey": null
};

(node as any).hash = "e18da6068715bd9d077297d7252b7480";

export default node;
