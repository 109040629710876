/**
 * @generated SignedSource<<2b21e4227cac3042baa9d34ce7aa5ebc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type graphUtils_samples$data = {
  readonly userJourney: {
    readonly " $fragmentSpreads": FragmentRefs<"graphUtils_preparedSamples" | "graphUtils_ujVersions">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"graphUtils_groupedSamples">;
  readonly " $fragmentType": "graphUtils_samples";
};
export type graphUtils_samples$key = {
  readonly " $data"?: graphUtils_samples$data;
  readonly " $fragmentSpreads": FragmentRefs<"graphUtils_samples">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ujID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "graphUtils_samples",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "graphUtils_groupedSamples"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ujId",
          "variableName": "ujID"
        }
      ],
      "concreteType": "UserJourney",
      "kind": "LinkedField",
      "name": "userJourney",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "graphUtils_preparedSamples"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "graphUtils_ujVersions"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b24520cb355735cf11f031416f08b45f";

export default node;
