import React, { HTMLAttributes } from "react";
import { useTitle } from "../lib/title";

export function RouteNotFoundErrorPage() {
    useTitle("Page Not Found");
    return <embedded-error-page src="/error-pages/not-found.html" />;
}

export function ErrorPage() {
    useTitle("Error");
    return <embedded-error-page src="/error-pages/error-500.html" />;
}

export function BadRequestErrorPage() {
    useTitle("Bad Request");
    return <embedded-error-page src="/error-pages/bad-request.html" />;
}

export function ForbiddenErrorPage() {
    useTitle("Forbidden");
    return <embedded-error-page src="/error-pages/forbidden.html" />;
}

export function SelectUserPage() {
    // not really an error page, but displays the same way
    useTitle("Select User");
    return (
        <embedded-error-page
            src="/error-pages/select-user.html"
            data-testid="select-user-page"
        />
    );
}

class EmbeddedErrorPage extends HTMLElement {
    connectedCallback() {
        const src = this.getAttribute("src");
        if (!src) {
            throw Error(
                "An EmbeddedErrorPage must have a specified src attribute"
            );
        }
        fetch(src)
            .then((response) => {
                return response.text();
            })
            .then((html) => {
                // Base elements don't work in web components, so we have to re-write the urls
                html = html.replaceAll('src="', 'src="/error-pages/');
                html = html.replaceAll('href="', 'href="/error-pages/');
                // It's not a 404, but a not found route.
                html = html.replaceAll(/HTTP \d+ -/gi, "");
                const shadow = this.attachShadow({ mode: "closed" });
                shadow.innerHTML = html;
            });
    }
}

customElements.define("embedded-error-page", EmbeddedErrorPage);

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "embedded-error-page": EmbeddedErrorPageAttributes;
        }
        interface EmbeddedErrorPageAttributes
            extends HTMLAttributes<HTMLElement> {
            src: string;
        }
    }
}
