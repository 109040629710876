/**
 * @generated SignedSource<<d8c4479ba557cb73030d21f1b2960445>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LimitedTimeRangeInput = {
  end: any;
  start: any;
};
export type UJDeliveryTimeGraphQuery$variables = {
  backup: boolean;
  timeRange: LimitedTimeRangeInput;
  ujID: number;
};
export type UJDeliveryTimeGraphQuery$data = {
  readonly userJourney: {
    readonly cutoffs: ReadonlyArray<{
      readonly start: any;
      readonly value: number | null;
    }>;
    readonly samples: ReadonlyArray<{
      readonly pageDeliveryTotal: number;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"amChartsUtils_useRoot" | "graphUtils_samples">;
};
export type UJDeliveryTimeGraphQuery = {
  response: UJDeliveryTimeGraphQuery$data;
  variables: UJDeliveryTimeGraphQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "backup"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timeRange"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ujID"
},
v3 = [
  {
    "kind": "Variable",
    "name": "ujId",
    "variableName": "ujID"
  }
],
v4 = {
  "kind": "Variable",
  "name": "timeRange",
  "variableName": "timeRange"
},
v5 = [
  {
    "kind": "Variable",
    "name": "backup",
    "variableName": "backup"
  },
  {
    "kind": "Literal",
    "name": "pmeOn",
    "value": false
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageDeliveryTotal",
  "storageKey": null
},
v7 = [
  (v4/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": (v7/*: any*/),
  "concreteType": "Cutoff",
  "kind": "LinkedField",
  "name": "cutoffs",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UJDeliveryTimeGraphQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "amChartsUtils_useRoot"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "graphUtils_samples"
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserJourney",
        "kind": "LinkedField",
        "name": "userJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "BasicSample",
            "kind": "LinkedField",
            "name": "samples",
            "plural": true,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UJDeliveryTimeGraphQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAdmin",
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showThirdPartyWarnings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showThirdPartyErrors",
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserJourney",
        "kind": "LinkedField",
        "name": "userJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "TimeRange",
            "kind": "LinkedField",
            "name": "pmeWindows",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "end",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "BasicSample",
            "kind": "LinkedField",
            "name": "samples",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allThirdPartyNoCustomerImpact",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extraWarningsAllThirdPartyNoCustomerImpact",
                "storageKey": null
              },
              (v10/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "UJVersion",
            "kind": "LinkedField",
            "name": "versions",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "summary",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "details",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Step",
                "kind": "LinkedField",
                "name": "steps",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dfb4a21dd2e2347f0700d86ecc237a4a",
    "id": null,
    "metadata": {},
    "name": "UJDeliveryTimeGraphQuery",
    "operationKind": "query",
    "text": "query UJDeliveryTimeGraphQuery(\n  $ujID: Int!\n  $timeRange: LimitedTimeRangeInput!\n  $backup: Boolean!\n) {\n  ...amChartsUtils_useRoot\n  ...graphUtils_samples\n  userJourney(ujId: $ujID) {\n    samples(timeRange: $timeRange, pmeOn: false, backup: $backup) {\n      pageDeliveryTotal\n      id\n    }\n    cutoffs(timeRange: $timeRange) {\n      start\n      value\n    }\n    id\n  }\n}\n\nfragment amChartsUtils_useRoot on Query {\n  currentUser {\n    isAdmin\n    id\n  }\n}\n\nfragment graphUtils_groupedSamples on Query {\n  ...urlParams_thirdPartyToggleDefaults\n}\n\nfragment graphUtils_pmeWindows on UserJourney {\n  pmeWindows(timeRange: $timeRange) {\n    start\n    end\n  }\n}\n\nfragment graphUtils_preparedSamples on UserJourney {\n  ...graphUtils_pmeWindows\n  samples(timeRange: $timeRange, pmeOn: false, backup: $backup) {\n    timestamp\n    status\n    allThirdPartyNoCustomerImpact\n    extraWarningsAllThirdPartyNoCustomerImpact\n    id\n  }\n}\n\nfragment graphUtils_samples on Query {\n  ...graphUtils_groupedSamples\n  userJourney(ujId: $ujID) {\n    ...graphUtils_preparedSamples\n    ...graphUtils_ujVersions\n    id\n  }\n}\n\nfragment graphUtils_ujVersions on UserJourney {\n  versions(timeRange: $timeRange) {\n    start\n    summary\n    details\n    steps {\n      id\n      name\n    }\n    id\n  }\n}\n\nfragment urlParams_thirdPartyToggleDefaults on Query {\n  currentUser {\n    customer {\n      showThirdPartyWarnings\n      showThirdPartyErrors\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a8c5a645b91403aff8838eb0c87c432";

export default node;
