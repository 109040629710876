/**
 * @generated SignedSource<<0190d453e2ca9b46f51767828cf80bf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GoogleAnalyticsInitialiser_data$data = {
  readonly currentUser: {
    readonly customer: {
      readonly name: string;
    };
    readonly impersonator: string | null;
    readonly isAdmin: boolean;
  };
  readonly " $fragmentType": "GoogleAnalyticsInitialiser_data";
};
export type GoogleAnalyticsInitialiser_data$key = {
  readonly " $data"?: GoogleAnalyticsInitialiser_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoogleAnalyticsInitialiser_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoogleAnalyticsInitialiser_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdmin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "impersonator",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Customer",
          "kind": "LinkedField",
          "name": "customer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b8ffa81ad0bde8f0b0d40dcc4a1b5bcd";

export default node;
