import {
    AnalyticsProvider,
    UserJourney_AnalyticsQuery$data,
} from "../pages/__generated__/UserJourney_AnalyticsQuery.graphql";

export type Analytics = UserJourney_AnalyticsQuery$data["analyticsVisitors"];
export type { AnalyticsProvider } from "../pages/__generated__/UserJourney_AnalyticsQuery.graphql";

export const ANALYTICS_PROVIDER_NAMES: { [key in AnalyticsProvider]: string } =
    {
        ADOBE: "Adobe Analytics",
        GOOGLE_UA: "Google Analytics",
        GOOGLE: "Google Analytics",
        "%future added value": "???",
    };
