import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql } from "babel-plugin-relay/macro";
import React, { Suspense, useState } from "react";
import {
    Button,
    Dropdown,
    Nav,
    NavLinkProps,
    Placeholder,
    PlaceholderProps,
} from "react-bootstrap";
import { useFragment } from "react-relay";
import { useOldPortalURLParams, useSelectedUJ } from "../../lib/urlParams";
import { NotificationsBell } from "../Notifications";
import { Header_header$key } from "./__generated__/Header_header.graphql";
import { Header_navigation$key } from "./__generated__/Header_navigation.graphql";
import { Header_user$key } from "./__generated__/Header_user.graphql";
import {
    Header_PossibleImpersonations$key,
    Header_PossibleImpersonations$data,
} from "./__generated__/Header_PossibleImpersonations.graphql";
import {
    AdminMenu,
    OverviewMenu,
    PreferencesMenu,
    SettingsMenu,
    UJPageMenu,
    DropdownToggle,
} from "./DropdownMenus";
import styles from "./Header.module.scss";
import { UJMenu } from "./UJMenu";
import { Pipe } from "../utils";
import { RequiredFields } from "../../helpers";
import { useLocation } from "react-router-dom";
import { hasPermission } from "../../lib/auth";
import { Header_SelectedUJToolkit$key } from "./__generated__/Header_SelectedUJToolkit.graphql";
import { Header_SelectedUJCustomerName$key } from "./__generated__/Header_SelectedUJCustomerName.graphql";

const HELP_PAGES: { [key: string]: string } = {
    "/userJourney/graphs": "performance-graphs-short-term",
    "/wallboard": "the-wallboard",
};

function User({ queryRef }: { queryRef: Header_user$key }) {
    const user = useFragment<Header_user$key>(
        graphql`
            fragment Header_user on User {
                displayName
                customer {
                    name
                }
                permissions {
                    allow
                    deny
                }
            }
        `,
        queryRef
    );
    const location = useLocation();
    const helpPage = HELP_PAGES[location.pathname];
    return (
        // Don't wrap this part of the header, flex uses nowrap by default.
        <div className={`text-nowrap d-flex ${styles.user}`}>
            {/* Hide on medium or smaller devices*/}
            <span className={"d-none d-lg-inline"}>
                You are logged in as:&nbsp;
            </span>
            <a
                href="/portal2/setting/user/personalInfo"
                title="Manage personal information"
                data-testid="PersonaInformationLink"
            >
                {user.displayName}
            </a>
            {!hasPermission(user.permissions, "power-user-header") && (
                <>
                    <Pipe />
                    <NotificationsBell />
                </>
            )}
            <Pipe />
            {/* ESLint wants us to set `rel="noreferer"' but the help site uses the referrer for
             authentication */}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
                href={`https://help.thinktribe.com/abc123abc/${helpPage}-mm1`}
                target="_blank"
            >
                Help
            </a>
            <Pipe />
            <a href="/portal2/logout">Logout</a>
            {hasPermission(user.permissions, "power-user-unimpersonate") && (
                <>
                    <Pipe />
                    <span>Division: {user.customer.name}</span>
                    &nbsp;
                    <a href="/portal2/powerUser/unImpersonateAs">
                        Switch Division
                    </a>
                </>
            )}
        </div>
    );
}

/**
 * HOC which renders `Nav.Link` with parameters for the old portal added to the URL.
 * Also makes `href` required.
 */
function withOldPortalURLNavLink() {
    return (props: RequiredFields<NavLinkProps, "href">) => {
        const href = useOldPortalURLParams(props.href);
        const newProps = { ...props, href };

        return <Nav.Link {...newProps} />;
    };
}

const OldPortalNavLink = withOldPortalURLNavLink();

function Navigation({ queryRef }: { queryRef: Header_navigation$key }) {
    const data = useFragment<Header_navigation$key>(
        graphql`
            fragment Header_navigation on Query {
                currentUser {
                    isAdmin
                    impersonator
                    permissions {
                        allow
                        deny
                    }
                    ...Header_user
                    ...UJMenu_user
                    ...DropdownMenus_UJPageMenu
                    ...Header_PossibleImpersonations
                }
                userJourneys {
                    ujID
                    ...UJMenu_UJs
                    ...Header_SelectedUJToolkit
                    ...Header_SelectedUJCustomerName
                }
            }
        `,
        queryRef
    );
    const selectedUJ = useSelectedUJ(data.userJourneys);
    const canViewCustomerName = hasPermission(
        data.currentUser.permissions,
        "view-any-customer-name"
    );

    return (
        <div className={"d-flex flex-grow-1"}>
            {/* First column containing the main navigation.
                Make the navigation go to the bottom of the column for lg or bigger widths,
                otherwise it should use the vertical space, because it's wrapping, and looks better
                aligned with the top of the logo.*/}
            <div className={"d-flex flex-column justify-content-lg-end"}>
                {/* A row of navs which can wrap underneath each other.*/}
                {!hasPermission(
                    data.currentUser.permissions,
                    "power-user-header"
                ) && (
                    <div className={`d-flex flex-wrap ${styles.flexGap}`}>
                        <Nav
                            variant="pills"
                            className={`${styles.leftNav} ${styles.overviewMenu}`}
                        >
                            {data.currentUser.isAdmin && (
                                <>
                                    <Nav.Item>
                                        <Nav.Link href="/portal2/status/clientWallboard">
                                            Wallboard
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <OldPortalNavLink href="/portal2/status/liveHistoric">
                                            Historic Status
                                        </OldPortalNavLink>
                                    </Nav.Item>
                                </>
                            )}
                            {!data.currentUser.isAdmin && (
                                <OverviewMenu
                                    permissions={data.currentUser.permissions}
                                />
                            )}
                        </Nav>
                        <Nav variant="pills" className={`${styles.leftNav}`}>
                            <div className={styles.ujPageMenu}>
                                <UJPageMenu queryRef={data.currentUser} />
                            </div>
                            <UJMenu
                                ujsQueryRef={data.userJourneys}
                                userQueryRef={data.currentUser}
                            />
                        </Nav>
                        {selectedUJ &&
                            (data.currentUser.isAdmin ||
                                canViewCustomerName) && (
                                <Nav
                                    variant="pills"
                                    className={`${styles.leftNav}`}
                                >
                                    {data.currentUser.isAdmin && (
                                        <SelectedUJToolkit
                                            fragmentRef={data.userJourneys}
                                        />
                                    )}
                                    {canViewCustomerName && (
                                        <SelectedUJCustomerName
                                            fragmentRef={data.userJourneys}
                                        />
                                    )}
                                </Nav>
                            )}
                    </div>
                )}

                {hasPermission(
                    data.currentUser.permissions,
                    "power-user-header"
                ) && (
                    <Nav
                        variant="pills"
                        className={`${styles.leftNav} ${styles.overviewMenu}`}
                    >
                        <Nav.Item>
                            <ImpersonationDropDown
                                fragmentRef={data.currentUser}
                            />
                        </Nav.Item>
                    </Nav>
                )}
            </div>

            {/* push the right hand side column to the right*/}
            <div className={"d-flex flex-column flex-grow-1 "} />

            {/* Right-hand side column*/}
            <div className={"d-flex flex-column"}>
                <div className={"d-flex align-self-end"}>
                    <User queryRef={data.currentUser} />
                </div>

                {/* Push the second row to the bottom on large width devices.
                    Once it becomes medium, the left-hand side nav will start from the top
                    Of the header, so do the same here to make them line up.*/}
                <div className={"d-flex flex-grow-1 d-none d-lg-inline"} />

                <div
                    className={`d-flex flex-wrap justify-content-end ${styles.flexGap}`}
                >
                    {hasPermission(
                        data.currentUser.permissions,
                        "view-admin-menu"
                    ) && (
                        <Nav variant="pills">
                            <AdminMenu
                                permissions={data.currentUser.permissions}
                            />
                        </Nav>
                    )}
                    {hasPermission(
                        data.currentUser.permissions,
                        "view-settings-user"
                    ) && (
                        <Nav variant="pills" className={"flex-nowrap"}>
                            <PreferencesMenu
                                permissions={data.currentUser.permissions}
                            />
                            <SettingsMenu
                                permissions={data.currentUser.permissions}
                            />
                        </Nav>
                    )}
                </div>
            </div>
        </div>
    );
}

function SelectedUJToolkit({
    fragmentRef,
}: {
    fragmentRef: Header_SelectedUJToolkit$key;
}) {
    const userJourneys = useFragment(
        graphql`
            fragment Header_SelectedUJToolkit on UserJourney
            @relay(plural: true) {
                ujID
                name
            }
        `,
        fragmentRef
    );
    const selectedUJ = useSelectedUJ(userJourneys)!;

    return (
        <>
            <div className="nav-item" style={{ marginRight: "1px" }}>
                <CopyButton label={"ID"} content={selectedUJ.ujID.toString()} />
            </div>
            <div className="nav-item">
                <CopyButton label={"Name"} content={selectedUJ.name} />
            </div>
        </>
    );
}

function SelectedUJCustomerName({
    fragmentRef,
}: {
    fragmentRef: Header_SelectedUJCustomerName$key;
}) {
    const userJourneys = useFragment(
        graphql`
            fragment Header_SelectedUJCustomerName on UserJourney
            @relay(plural: true) {
                ujID
                customer {
                    name
                }
            }
        `,
        fragmentRef
    );
    const selectedUJ = useSelectedUJ(userJourneys)!;

    return (
        <div className="nav-item">
            <div className={`${styles.customerName}`}>
                {selectedUJ.customer.name}
            </div>
        </div>
    );
}

type PossibleImpersonationCustomer =
    Header_PossibleImpersonations$data["possibleImpersonations"][number];

function ImpersonationDropDownItem({
    impersonation,
}: {
    impersonation: PossibleImpersonationCustomer;
}) {
    const label = `${impersonation.customerName} [${impersonation.roleID}]`;
    return (
        <form
            method={"post"}
            action={"/portal2/powerUser/impersonateAs"}
            aria-label={`Select user ${label}`}
        >
            <Dropdown.Item
                eventKey={impersonation.userID}
                as={Button}
                type={"submit"}
            >
                {label}
            </Dropdown.Item>
            <input
                type={"hidden"}
                name={"userID"}
                value={impersonation.userID}
            ></input>
        </form>
    );
}

function ImpersonationDropDown({
    fragmentRef,
}: {
    fragmentRef: Header_PossibleImpersonations$key;
}) {
    const data = useFragment(
        graphql`
            fragment Header_PossibleImpersonations on User {
                possibleImpersonations {
                    customerID
                    customerName
                    userID
                    roleID
                }
            }
        `,
        fragmentRef
    );

    return (
        <Dropdown>
            <DropdownToggle>Select Customer</DropdownToggle>

            <Dropdown.Menu>
                {data.possibleImpersonations.map(
                    (impersonation: PossibleImpersonationCustomer) => (
                        <ImpersonationDropDownItem
                            key={impersonation.userID}
                            impersonation={impersonation}
                        />
                    )
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

function CopyButton({ label, content }: { label: string; content: string }) {
    const [icon, setIcon] = useState(faCopy);

    function handleClick() {
        navigator.clipboard.writeText(content).then(() => {
            setIcon(faCircleCheck);
            setTimeout(() => {
                setIcon(faCopy);
            }, 1000);
        });
    }

    return (
        <Button
            onClick={handleClick}
            className={`inverted ${styles.copyButton}`}
            variant={"outline-secondary"}
        >
            <FontAwesomeIcon
                icon={icon}
                size={"sm"}
                className={styles.copyIcon}
            />
            &nbsp;
            {label}
        </Button>
    );
}

function PlaceholderContainer(props: PlaceholderProps) {
    return (
        <Placeholder as={"div"} bg={"light"} {...props}>
            {props.children}
        </Placeholder>
    );
}

function NavigationPlaceholder() {
    return (
        <Placeholder
            as="div"
            animation="wave"
            className={"d-flex flex-grow-1 flex-column"}
        >
            <PlaceholderContainer xs={12}>
                <PlaceholderContainer xs={7} lg={9} />
                <PlaceholderContainer xs={5} lg={3}>
                    <PlaceholderContainer xs={2} />
                    <PlaceholderContainer
                        xs={4}
                        className={[
                            styles.placeholderSeparator,
                            styles.userItemPlaceholder,
                        ].join(" ")}
                    >
                        <Placeholder xs={12} />
                    </PlaceholderContainer>
                    <PlaceholderContainer
                        xs={2}
                        className={[
                            styles.placeholderSeparator,
                            styles.userItemPlaceholder,
                        ].join(" ")}
                    >
                        <Placeholder xs={12} />
                    </PlaceholderContainer>
                    <PlaceholderContainer
                        xs={2}
                        className={[
                            styles.placeholderSeparator,
                            styles.userItemPlaceholder,
                        ].join(" ")}
                    >
                        <Placeholder xs={12} />
                    </PlaceholderContainer>
                    <PlaceholderContainer
                        xs={2}
                        className={[
                            styles.placeholderSeparator,
                            styles.userItemPlaceholder,
                        ].join(" ")}
                    >
                        <Placeholder xs={12} />
                    </PlaceholderContainer>
                </PlaceholderContainer>
            </PlaceholderContainer>
            <PlaceholderContainer
                className={"d-flex flex-row flex-grow-1 align-items-end"}
                xs={12}
            >
                <PlaceholderContainer as={Nav} xs={7} lg={5}>
                    <PlaceholderContainer
                        xs={4}
                        className={styles.placeholderSeparator}
                    >
                        <Placeholder.Button xs={12} />
                    </PlaceholderContainer>
                    <PlaceholderContainer
                        xs={4}
                        className={styles.placeholderSeparator}
                    >
                        <Placeholder.Button xs={12} />
                    </PlaceholderContainer>
                    <PlaceholderContainer
                        xs={4}
                        className={styles.placeholderSeparator}
                    >
                        <Placeholder.Button xs={12} />
                    </PlaceholderContainer>
                </PlaceholderContainer>
                <PlaceholderContainer xs={2} lg={5} />
                <PlaceholderContainer as={Nav} xs={3} lg={2}>
                    <PlaceholderContainer
                        xs={2}
                        className={styles.placeholderSeparator}
                    ></PlaceholderContainer>
                    <PlaceholderContainer
                        xs={5}
                        className={styles.placeholderSeparator}
                    >
                        <Placeholder.Button xs={12} />
                    </PlaceholderContainer>
                    <PlaceholderContainer
                        xs={5}
                        className={styles.placeholderSeparator}
                    >
                        <Placeholder.Button xs={12} />
                    </PlaceholderContainer>
                </PlaceholderContainer>
            </PlaceholderContainer>
        </Placeholder>
    );
}

export function Header({ queryRef }: { queryRef: Header_header$key }) {
    const data = useFragment<Header_header$key>(
        graphql`
            fragment Header_header on Query {
                ...Header_navigation
            }
        `,
        queryRef
    );

    const logoSrc =
        "/portal2/assets/" +
        process.env.REACT_APP_ASSET_VERSION_HASH +
        "/images/logos/tribe.png";

    return (
        <div className={styles.header + " d-flex"}>
            <div>
                <a href={"/portal2/"}>
                    <img className={styles.logo} src={logoSrc} alt={"Tribe"} />
                </a>
            </div>
            <Suspense fallback={<NavigationPlaceholder />}>
                <Navigation queryRef={data} />
            </Suspense>
        </div>
    );
}
