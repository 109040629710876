/**
 * @generated SignedSource<<3e7e347bedfe06141cf7a1add347a7e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type App_user$data = {
  readonly currentUser: {
    readonly id: string;
  };
  readonly " $fragmentType": "App_user";
};
export type App_user$key = {
  readonly " $data"?: App_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"App_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "App_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "11ccb036badb4f385c2f578fd65c7faa";

export default node;
