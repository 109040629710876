/* Uncategorized hooks */

import { useCallback, useEffect, useRef } from "react";

type SetCallbackFn = (callback: () => void, ms: number) => void;
type ResetCallbackFn = () => void;

export function useTimeout(): [SetCallbackFn, ResetCallbackFn] {
    const timeoutRef = useRef<number | null>();

    const reset = useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, []);

    const set = useCallback(
        (callback: () => void, ms: number) => {
            reset();
            timeoutRef.current = window.setTimeout(() => {
                timeoutRef.current = null;
                callback();
            }, ms);
        },
        [reset]
    );

    // Reset when component unmounts.
    useEffect(() => reset, [reset]);

    // If we ever need to reset outside the hook we can return reset as well.
    return [set, reset];
}
