/**
 * @generated SignedSource<<0beb5cf4b5aea6d9e36c05d099793c97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UJStatuses = "OBSOLETE" | "PAUSED" | "RUNNING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Wallboard_bars$data = {
  readonly currentUser: {
    readonly " $fragmentSpreads": FragmentRefs<"userJourneys_userPreferences">;
  };
  readonly localState: {
    readonly samples: ReadonlyArray<{
      readonly ujID: number;
      readonly " $fragmentSpreads": FragmentRefs<"Wallboard_userJourneySamples">;
    }> | null;
  } | null;
  readonly userJourneys: ReadonlyArray<{
    readonly status: UJStatuses | null;
    readonly ujID: number;
    readonly " $fragmentSpreads": FragmentRefs<"Wallboard_userJourneyRow">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"urlParams_thirdPartyToggleDefaults">;
  readonly " $fragmentType": "Wallboard_bars";
};
export type Wallboard_bars$key = {
  readonly " $data"?: Wallboard_bars$data;
  readonly " $fragmentSpreads": FragmentRefs<"Wallboard_bars">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ujID",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "backup"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Wallboard_bars",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "userJourneys_userPreferences"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserJourney",
      "kind": "LinkedField",
      "name": "userJourneys",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Wallboard_userJourneyRow"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "urlParams_thirdPartyToggleDefaults"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LocalState",
          "kind": "LinkedField",
          "name": "localState",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "backup",
                  "variableName": "backup"
                }
              ],
              "concreteType": "LocalSamples",
              "kind": "LinkedField",
              "name": "samples",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Wallboard_userJourneySamples"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "89176a683289ac2b04d151243b20f6c8";

export default node;
