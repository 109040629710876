/**
 * @generated SignedSource<<f8bf6865f20210ecc073570715ef6d2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserJourneyQuery$variables = Record<PropertyKey, never>;
export type UserJourneyQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ToggleMenu">;
};
export type UserJourneyQuery = {
  response: UserJourneyQuery$data;
  variables: UserJourneyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserJourneyQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ToggleMenu"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserJourneyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Permissions",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deny",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showThirdPartyWarnings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showThirdPartyErrors",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3f998df698c999a68a84dd883a74426",
    "id": null,
    "metadata": {},
    "name": "UserJourneyQuery",
    "operationKind": "query",
    "text": "query UserJourneyQuery {\n  ...ToggleMenu\n}\n\nfragment ToggleMenu on Query {\n  currentUser {\n    permissions {\n      allow\n      deny\n    }\n    id\n  }\n  ...urlParams_thirdPartyToggleDefaults\n}\n\nfragment urlParams_thirdPartyToggleDefaults on Query {\n  currentUser {\n    customer {\n      showThirdPartyWarnings\n      showThirdPartyErrors\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e906a6f2919d801f4483a1b9eb27e423";

export default node;
