import * as am5 from "@amcharts/amcharts5";
import { GridLayout } from "@amcharts/amcharts5";
import { IDisposer } from "@amcharts/amcharts5/.internal/core/util/Disposer";
import {
    Exporting,
    ExportingMenu,
} from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Kelly from "@amcharts/amcharts5/themes/Kelly";
import * as am5xy from "@amcharts/amcharts5/xy";
import { XYChart } from "@amcharts/amcharts5/xy";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import {
    MutableRefObject,
    startTransition,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    formatDateInUTC,
    parse,
    TimeRange,
    timeRangeToString,
} from "../../lib/dateUtils";
import {
    useBackupToggle,
    useOldPortalURLSearchParams,
    useStrictURLTimerange,
    useURLSelectedUJID,
} from "../../lib/urlParams";
import { SampleGroup, UJVersion } from "./graphUtils";
import { useTimezone } from "../GlobalContext";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
    Analytics,
    ANALYTICS_PROVIDER_NAMES,
    AnalyticsProvider,
} from "../../lib/analytics";
import { amChartsUtils_useRoot$key } from "./__generated__/amChartsUtils_useRoot.graphql";
import { SampleEffectiveStatus } from "../../lib/samples";

// We render only one chart at a time now.
export const CHART_CONTAINER_ID = "chartContainer";
export const CHART_HEIGHT = 500;
// This value will allow user to pan a one length of the currently displayed time range.
const MAX_DEVIATION_X = 1;

type DragMode = "zoom" | "pan";
type XYChartUserData = {
    navigationTooltips: am5.Tooltip[];
};
type RootRef = MutableRefObject<am5.Root | undefined>;

class DragControl implements IDisposer {
    chart: am5xy.XYChart;
    private mode: DragMode;
    private actionInProgress: boolean;
    private controlHandlers: {
        up: (event: KeyboardEvent) => void;
        down: (event: KeyboardEvent) => void;
    };
    private disposed = false;

    constructor(chart: am5xy.XYChart) {
        this.chart = chart;
        this.mode = "zoom";
        this.actionInProgress = false;
        this.controlHandlers = {
            up: this.controlUpHandler.bind(this),
            down: this.controlDownHandler.bind(this),
        };
    }

    init() {
        this.chart.events.on("panstarted", () => {
            this.actionInProgress = true;
        });
        this.chart.events.on("panended", () => {
            this.actionInProgress = false;
            // Now that action is finished, we can change the mode if necessary.
            if (this.mode !== "pan") {
                this.configureMode(this.mode);
            }
        });

        const cursor = this.chart.get("cursor")!;
        cursor.events.on("selectstarted", () => {
            this.actionInProgress = true;
        });
        cursor.events.on("selectended", () => {
            runOnZoomAnimationEnded(this.chart, () => {
                this.actionInProgress = false;
                // Now that action is finished, we can change the mode if necessary.
                if (this.mode !== "zoom") {
                    this.configureMode(this.mode);
                }
            });
        });

        document.addEventListener("keydown", this.controlHandlers.down);
        document.addEventListener("keyup", this.controlHandlers.up);

        this.setMode("zoom");
    }

    private setMode(mode: DragMode) {
        this.mode = mode;
        // Don't update configuration while action is already in progress.
        if (!this.actionInProgress) {
            this.configureMode(mode);
        }
    }

    private configureMode(mode: DragMode) {
        if (mode === "pan") {
            this.chart.set("panX", true);
            this.chart.get("cursor")?.set("behavior", "none");
        } else {
            this.chart.set("panX", false);
            this.chart.get("cursor")?.set("behavior", "selectX");
        }
    }

    private controlDownHandler(event: KeyboardEvent) {
        // Don't do anything if control is pressed while action is already in progress.
        if (event.key === "Control") {
            this.setMode("pan");
        }
    }

    private controlUpHandler(event: KeyboardEvent) {
        if (event.key === "Control") {
            this.setMode("zoom");
        }
    }

    dispose() {
        // This is supposed to be called  when root is disposed, so ignore chart events.
        document.removeEventListener("keydown", this.controlHandlers.down);
        document.removeEventListener("keyup", this.controlHandlers.up);
        this.disposed = true;
    }

    isDisposed(): boolean {
        return this.disposed;
    }
}

const LEGEND_TOP_PADDING = 10;
// ensure there is enough space at the top of the graph for uj version flags
// and the error bar legend.
const CHART_TOP_PADDING = 60;

interface RootEffect {
    (root: am5.Root): void;
}

export function useRoot(
    queryRef: amChartsUtils_useRoot$key,
    maxLegendColumns: number,
    effect: RootEffect
): RootRef {
    const rootRef = useRef<am5.Root>();
    const [ujID] = useURLSelectedUJID(true);
    const [urlTimeRange] = useStrictURLTimerange();
    const timezone = useTimezone();

    const { currentUser } = useFragment(
        graphql`
            fragment amChartsUtils_useRoot on Query {
                currentUser {
                    isAdmin
                }
            }
        `,
        queryRef
    );

    useEffect(() => {
        let root = am5.Root.new(CHART_CONTAINER_ID);
        rootRef.current = root;
        root.timezone = am5.Timezone.new(timezone);

        root.setThemes([
            am5themes_Animated.new(root),
            am5themes_Kelly.new(root), // "Highly contrasting colours"
        ]);

        const chartUserData: XYChartUserData = {
            navigationTooltips: [],
        };
        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                focusable: true,
                panX: false,
                panY: false,
                layout: root.verticalLayout,
                paddingTop: CHART_TOP_PADDING,
                paddingBottom: 0,
                userData: chartUserData,
                paddingLeft: 0,
            })
        );
        // We don't use this button at all.
        chart.zoomOutButton.set("forceHidden", true);

        // todo adding this border to the graph breaks panning
        // chart.plotContainer.set("background", am5.Rectangle.new(root, {
        //     stroke: am5.color("#A9A9A9"),
        //     strokeWidth: 1,
        //     strokeOpacity: 1,
        // }));
        chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                min: urlTimeRange.start.toMillis(),
                max: urlTimeRange.end.toMillis(),
                maxDeviation: MAX_DEVIATION_X,
                groupData: false,
                baseInterval: {
                    timeUnit: "second",
                    count: 1,
                },
                renderer: am5xy.AxisRendererX.new(root, {}),
            })
        );

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                maxDeviation: 0.2, // TODO: what is this?
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );
        yAxis.children.unshift(
            am5.Label.new(root, {
                rotation: -90,
                text: "Seconds",
                y: am5.p50,
                centerX: am5.p50,
            })
        );

        Exporting.new(root, {
            id: root.dom.id + "Exporting",
            menu: ExportingMenu.new(root, {}),
        });

        const legend = chart.children.push(
            am5.Legend.new(root, {
                id: root.dom.id + "LeftLegend",
                paddingTop: LEGEND_TOP_PADDING,
                x: 80,
                y: CHART_HEIGHT, // ensures the left and right legend are aligned.
                centerX: am5.percent(0),
                layout: GridLayout.new(chart.root, {
                    maxColumns: maxLegendColumns,
                }),
            })
        );
        legend.events.on("boundschanged", () => {
            root.dom.style.height = CHART_HEIGHT + legend.height() + "px";
        });

        const statusMarkLegend = chart.children.push(
            am5.Legend.new(root, {
                id: root.dom.id + "StatusMarkLegend",
                position: "absolute",
                nameField: "name",
                fillField: "color",
                strokeField: "color",
                y: am5.percent(0),
                centerY: am5.percent(100),
                x: am5.percent(100),
                centerX: am5.percent(100),
                paddingBottom: 30, // space for the uj version flags
            })
        );
        const statusMarkLegendItems = [
            {
                name: "Error",
                color: am5.color(statusMarksConfig["ERROR"].fillcolor),
            },
            {
                name: "Warning",
                color: am5.color(statusMarksConfig["WARNING"].fillcolor),
            },
            {
                name: "PME",
                color: am5.color(statusMarksConfig["PME"].fillcolor),
            },
        ];

        if (currentUser.isAdmin) {
            statusMarkLegendItems.push({
                name: "Debug",
                color: am5.color(statusMarksConfig["DEBUG"].fillcolor),
            });
        }

        statusMarkLegend.data.setAll(statusMarkLegendItems);

        const tooltip = createLegendTooltip(chart, "up");
        statusMarkLegend.dataItems.forEach((item) => {
            const name = item.get("name");
            let tooltipText;
            if (name === "Error") {
                tooltipText = "The samples highlighted in red are errors.";
            } else if (name === "Warning") {
                tooltipText = "The samples highlighted in yellow are warnings.";
            } else if (name === "PME") {
                tooltipText =
                    "The samples covered with Grey are in a Planned Maintenance Exclusion period.";
            } else if (name === "Debug") {
                tooltipText =
                    "The samples highlighted in blue had an error in Tribe software and require debugging.";
            }
            item.get("itemContainer").setAll({
                tooltipText: tooltipText,
                tooltip,
            });
        });

        // noinspection JSIgnoredPromiseFromCall
        chart.appear(1000, 100);

        const cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                xAxis: getXAxis(chart),
                behavior: "none",
                snapToSeries: [],
            })
        );
        cursor.lineY.set("visible", true);

        rootRef.current = root;

        const dragControl = new DragControl(chart);
        dragControl.init();

        root.addDisposer(dragControl);

        effect(root);

        return () => {
            root.dispose();
        };

        // With each UJ we have different step names, which means different series.
        // We don't know any way to remove a series that does not cause a flurry of
        // the event dispatcher errors, so we just have to re-create the whole chart.
        // It's also nicer not to animate when the UJ changes, because the data is unrelated.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ujID, timezone]);

    return rootRef;
}

function getChartUserData(chart: am5xy.XYChart): XYChartUserData {
    return chart.get("userData");
}

/**
 * Retrieves chart entity from the global registry.
 */
export function getChartItem(root: am5.Root, name: "Exporting"): Exporting;
export function getChartItem(
    root: am5.Root,
    name: "VisitorsAxisLabel"
): am5.Label;
export function getChartItem(
    root: am5.Root,
    name: "LeftLegend" | "VisitorsLegend" | "StatusMarkLegend"
): am5.Legend;
export function getChartItem(root: am5.Root, name: string) {
    const item = am5.registry.entitiesById[root.dom.id + name];
    if (item === undefined) {
        throw Error(`Chart item ${name} unexpectedly undefined`);
    }
    return item;
}

export function setExportFilenamePrefix(
    root: am5.Root,
    chartName: string,
    timeRange: TimeRange
) {
    const format = "yyyy-MM-dd HH-mm-ss";
    const startFormatted = timeRange.start.toFormat(format);
    const endFormatted = timeRange.end.toFormat(format);

    const prefix =
        `${chartName} from ${startFormatted} to ${endFormatted}`.replaceAll(
            " ",
            "_"
        );

    const exporting = getChartItem(root, "Exporting");
    exporting.set("filePrefix", prefix);
}

export function useExportChartFilename(rootRef: RootRef, chartName: string) {
    const [urlTimeRange] = useStrictURLTimerange();
    const urlTimeRangeString = timeRangeToString(urlTimeRange);

    // Update export filename whenever the timerange changes.
    useEffect(() => {
        const root = rootRef.current!;
        setExportFilenamePrefix(root, chartName, urlTimeRange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlTimeRangeString]);
}

function getSelectedTimeRange(chart: am5xy.XYChart) {
    const xAxis = getXAxis(chart);
    const start = DateTime.fromMillis(xAxis.getPrivate("selectionMin")!);
    const end = DateTime.fromMillis(xAxis.getPrivate("selectionMax")!);
    return { start, end };
}

// Exported for testing.
export function useNavigateToSingleSample() {
    const [ujID] = useURLSelectedUJID(true);
    const [backup] = useBackupToggle();
    const params = new URLSearchParams({
        uj: ujID.toString(),
        backup: backup.toString(),
    });
    const searchParams = useOldPortalURLSearchParams(params);

    function navigateToSingleSample(sampleTime: DateTime) {
        searchParams.set("sampleTime", formatDateInUTC(sampleTime));
        searchParams.sort(); // for testing
        window.location.assign(
            `/portal2/userJourney/graphs/singleSample?${searchParams.toString()}`
        );
    }

    return navigateToSingleSample;
}

export function useEventHandlers(rootRef: RootRef) {
    const [urlTimeRange, setURLTimeRange] = useStrictURLTimerange();
    const urlTimeRangeString = timeRangeToString(urlTimeRange);
    const handlersRef = useRef<{
        panended: any;
        selectended: any;
        chartClick: any;
    }>();
    const navigateToSingleSample = useNavigateToSingleSample();

    // Update the chart event handlers whenever the setURLTimeRange function changes, because
    // that's what they use when they fire. It's a proxy for any URL change.
    useEffect(() => {
        const root = rootRef.current!;
        const chart = getChart(root);
        const xAxis = getXAxis(chart);
        const cursor = chart.get("cursor")!;
        const currentHandlers = handlersRef.current;

        // Remove existing handlers, if any.
        if (currentHandlers) {
            chart.events.off("panended", currentHandlers.panended);
            cursor.events.off("selectended", currentHandlers.selectended);
            chart.chartContainer.events.off(
                "click",
                currentHandlers.chartClick
            );
        }

        function chartClickHandler() {
            const xAxis = getXAxis(chart);

            // The cursors private xPosition snaps to samples, but the cursors lines do not, so
            // we can use them to find out exactly where the user clicked.
            navigateToSingleSample(
                DateTime.fromMillis(
                    xAxis.positionToValue(
                        xAxis.coordinateToPosition(cursor.lineX.x())
                    )
                )
            );
        }

        function panendedHandler() {
            const newTimeRange = getSelectedTimeRange(chart);
            setURLTimeRange(newTimeRange);
        }

        function dateTimeFromPosition(coordinate: number) {
            return DateTime.fromMillis(
                xAxis.positionToValue(xAxis.coordinateToPosition(coordinate))
            );
        }

        function selectEndedHandler({ target }: { target: am5xy.XYCursor }) {
            const selectionBounds = target.selection.localBounds();
            if (selectionBounds.right < 0) {
                // A single click is considered a selection, with a nonsense region.
                // https://github.com/amcharts/amcharts5/issues/510
                return;
            }

            // Zoom immediately, we don't want to wait for the query to load because we already
            // have the data.
            // Use our zoom instead of xAxis.zoom, because it does something I don't understand.
            // When we try to set the xAxis bounds after zooming, but before we have reloaded
            // the data it ends up zooming even more.
            xAxis.set(
                "min",
                dateTimeFromPosition(selectionBounds.left).toMillis()
            );
            xAxis.set(
                "max",
                dateTimeFromPosition(selectionBounds.right).toMillis()
            );

            // Hide the grey selection UI
            // noinspection JSIgnoredPromiseFromCall
            cursor.selection.hide();
            setURLTimeRange({
                start: dateTimeFromPosition(selectionBounds.left),
                end: dateTimeFromPosition(selectionBounds.right),
            });
        }

        chart.events.on("panended", panendedHandler);
        cursor.events.on("selectended", selectEndedHandler);
        chart.chartContainer.events.on("click", chartClickHandler);

        handlersRef.current = {
            panended: panendedHandler,
            selectended: selectEndedHandler,
            chartClick: chartClickHandler,
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setURLTimeRange, urlTimeRangeString]);
}

export function getChart(root: am5.Root) {
    return root.container.children.getIndex(0) as am5xy.XYChart;
}

export function getXAxis(chart: am5xy.XYChart) {
    return chart.xAxes.getIndex(0)! as am5xy.DateAxis<am5xy.AxisRenderer>;
}

export function getYAxis(chart: am5xy.XYChart) {
    return chart.yAxes.getIndex(0)! as am5xy.ValueAxis<am5xy.AxisRenderer>;
}

export function snapCursorToSeries(chart: XYChart, series: am5xy.XYSeries[]) {
    chart.get("cursor")!.set("snapToSeries", series);
}

export function getSeries(chart: am5xy.XYChart, name: string): am5xy.XYSeries {
    const series = chart.series.values.find((series) => {
        return series.get("name") === name;
    });
    if (series === undefined) {
        throw Error("Series '" + name + "' unexpectedly undefined");
    }
    return series;
}

type MarkedStatus = Extract<
    SampleEffectiveStatus,
    "ERROR" | "WARNING" | "PME" | "DEBUG"
>;
const markedStatuses = ["ERROR", "WARNING", "PME", "DEBUG"];
const statusMarksConfig: {
    [key in MarkedStatus]: {
        fillcolor: string;
        opacity: number;
    };
} = {
    ERROR: { fillcolor: "#ff0000", opacity: 1 },
    WARNING: { fillcolor: "#ffd700", opacity: 0.8 },
    PME: { fillcolor: "#d3d3d3", opacity: 1 },
    DEBUG: { fillcolor: "#6699ff", opacity: 1 },
};

function makeStatusMarkings(
    xAxis: am5xy.DateAxis<am5xy.AxisRenderer>,
    groups: SampleGroup[]
) {
    for (const group of groups) {
        if (!markedStatuses.includes(group.status)) {
            continue;
        }
        const configForStatus = statusMarksConfig[group.status as MarkedStatus];
        const rangeDataItem = xAxis.makeDataItem({
            value: group.start,
            endValue: group.end,
            above: false,
        });
        const axisRange = xAxis.createAxisRange(rangeDataItem);
        axisRange.get("axisFill")!.setAll({
            fill: am5.Color.fromAny(configForStatus.fillcolor),
            fillOpacity: configForStatus.opacity,
            visible: true,
        });

        // Don't show a new grid line
        axisRange.get("grid")!.set("visible", false);
    }
}

function makeUJVersionMarkings(
    xAxis: am5xy.DateAxis<am5xy.AxisRenderer>,
    versions: readonly UJVersion[]
) {
    const chart = xAxis.chart!;
    const root = xAxis.root;
    const min = xAxis.get("min")!;
    const max = xAxis.get("max")!;
    for (const version of versions) {
        // Skip versions outside of bounds. We shouldn't get any version past
        // the max, but check anyway, for the sake of completeness.
        if (version.start < min || version.start > max) {
            continue;
        }

        let tooltipText = `Change: ${version.summary}`;
        if (version.details) {
            tooltipText += `\nDetails: ${version.details}`;
        }
        const tooltip = am5.Tooltip.new(root, {
            labelText: tooltipText,
            pointerOrientation: "up",
        });
        tooltip.get("background")!.setAll({
            fill: am5.color("#FFFFFF"),
            fillOpacity: 1,
            stroke: am5.color("#000000"),
            strokeOpacity: 0.3,
        });
        // noinspection SpellCheckingInspection
        const flagImg =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfmBhsIOzOJ4KiXAAAAn0lEQVQoz9XOsY4BARSF4c8YEgkR0SHb7dYarU4tnmFLL+GhdN7AK0jssM1qTCIaGYxikh1TTNTOrU7uf3P/iq29bwsTXYmNlZVI4NPUzJHUj7FI+j8nO7/OWQtlucvT0spL4EXeCqiUASFSXMXOatoahX0cInA0d3BS1zc08qXj5s/aktTOR+GqqmOgp5m/KBrcxOJnyVLBDEhcJOXAA4I2LhYen/4IAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA2LTI3VDA4OjU5OjQzKzAwOjAw9DaMUQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNi0yN1QwODo1OTo0MyswMDowMIVrNO0AAAAASUVORK5CYII=";
        const flag = am5.Picture.new(root, {
            width: 16,
            height: 16,
            centerX: am5.percent(0),
            centerY: am5.percent(100),
            // This is the font-awesome flag icon, converted to a PNG, downsized to
            // 16x16 and then converted to a data-uri. SVGs don't seem to work.
            src: flagImg,
            tooltip,
            tooltipText,
        });
        const chartHeight = chart.plotContainer.getPrivate("height")!;
        flag.adapters.add("y", function () {
            return -1 * (chartHeight + 2);
        });
        const dataItem = xAxis.makeDataItem({
            value: version.start,
            above: true,
        });
        dataItem.set(
            "bullet",
            am5xy.AxisBullet.new(root, {
                sprite: flag,
            })
        );
        xAxis.createAxisRange(dataItem);
        dataItem.get("grid")!.setAll({
            stroke: am5.Color.fromAny("#000000"),
            strokeWidth: 2,
            strokeOpacity: 1,
        });
    }
}

export function makeMarkings(
    xAxis: am5xy.DateAxis<am5xy.AxisRenderer>,
    groups: SampleGroup[],
    versions: readonly UJVersion[]
) {
    xAxis.axisRanges.clear();

    makeStatusMarkings(xAxis, groups);
    makeUJVersionMarkings(xAxis, versions);
}

function addLineSeries(
    chart: am5xy.XYChart,
    yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
    settings: Partial<am5xy.ILineSeriesSettings>
) {
    return chart.series.push(
        am5xy.LineSeries.new(chart.root, {
            minBulletDistance: 10,
            connect: false,
            xAxis: getXAxis(chart),
            yAxis,
            valueYField: "value",
            valueXField: "timestamp",
            // We must set connect: false to get gaps, but then amCharts will also add
            // gaps based on the baseInterval of the axis. So make this huge to stop it.
            autoGapCount: 100 * 60 * 5,
            ...settings,
        })
    );
}

export function addPerSampleSeries(
    root: am5.Root,
    name: string,
    colour?: string,
    strokeWidth?: number
) {
    const chart = getChart(root);
    const yAxis = getYAxis(chart);

    // We might use a shared tooltip once we figure out why it's causing us issues:
    // - the more series the tooltip is linked to, the further from the cursor it renders
    // - in the step graph once a step goes outside the view, the shared tooltip
    //   stops showing up at all. The same happened if we don't re-create
    //   the chart after changing UJs.
    const tooltip = am5.Tooltip.new(root, {
        labelText:
            "{name}: {valueX.formatDate('EEE d MMM HH:mm:ss')}: {valueY.formatNumber('#.##')}s",
        pointerOrientation: "horizontal",
    });
    getChartUserData(chart).navigationTooltips.push(tooltip);

    const settings: Partial<am5xy.ILineSeriesSettings> = { name, tooltip };
    if (colour) {
        settings.stroke = am5.Color.fromAny(colour);
        settings.fill = am5.Color.fromAny(colour);
    }
    const series = addLineSeries(chart, yAxis, settings);
    series.strokes.template.setAll({
        strokeWidth: strokeWidth ?? 1,
    });
    return series;
}

function getVisitorsSeriesName(provider: AnalyticsProvider) {
    return `${ANALYTICS_PROVIDER_NAMES[provider]} Visitors`;
}

export const VISITORS_SERIES_NAMES = Object.keys(ANALYTICS_PROVIDER_NAMES)
    .filter((p) => p !== "GOOGLE_UA")
    .filter((p) => p !== "%future added value")
    .map((provider) => getVisitorsSeriesName(provider as AnalyticsProvider));

const LEGEND_TOOLTIPS: { [key: string]: string } = {
    "Delivery Time": "How long it took for the complete User Journey to run.",
    "Average Delivery Time":
        "The delivery time, averaged over neighbouring samples and then smoothed.",
    Cutoff:
        "Cutoff is a quality response time threshold: it is the level (in seconds) at which " +
        "you think the User Journey would be slow enough to cause problems for your site users.",
    [getVisitorsSeriesName(
        "ADOBE"
    )]: `The number of unique visitors that visited your site in that hour, according to ${ANALYTICS_PROVIDER_NAMES.ADOBE}.`,
    [getVisitorsSeriesName(
        "GOOGLE"
    )]: `The number of unique visitors that visited your site in that hour, according to ${ANALYTICS_PROVIDER_NAMES.GOOGLE}.`,
};

function createLegendTooltip(
    chart: am5xy.XYChart,
    pointerOrientation: "up" | "down"
) {
    const tooltip = am5.Tooltip.new(chart.root, {
        pointerOrientation: pointerOrientation,
    });
    tooltip.get("background")!.setAll({
        fill: am5.color("#FFFFFF"),
        fillOpacity: 1,
        stroke: am5.color("#000000"),
        strokeOpacity: 0.3,
    });
    tooltip.label.setAll({ oversizedBehavior: "wrap", maxWidth: 500 });
    return tooltip;
}

function createLegendTooltips(chart: am5xy.XYChart, legend: am5.Legend) {
    const tooltip = createLegendTooltip(chart, "down");

    legend.dataItems.forEach((item) => {
        item.get("itemContainer").setAll({
            tooltipText: LEGEND_TOOLTIPS[item.get("name")!],
            tooltip,
        });
    });
}

export function configureLegend(
    chart: am5xy.XYChart,
    excludeSeriesNames?: string[]
) {
    const legend = getChartItem(chart.root, "LeftLegend");

    excludeSeriesNames ??= [];
    // Never show the Fill Between series in the legend.
    excludeSeriesNames.push("Fill Between");
    // Never show the visitors series in this legend.
    excludeSeriesNames.push(...VISITORS_SERIES_NAMES);

    const included = chart.series.values.filter(
        (series) => !excludeSeriesNames!.includes(series.get("name") as string)
    );
    legend.data.setAll(included);

    createLegendTooltips(chart, legend);

    configureLegendLayout(chart, legend);
}

export function updateXAxisBounds(
    xAxis: am5xy.DateAxis<am5xy.AxisRenderer>,
    toTimeRange: TimeRange
) {
    // Always reset the selection, we never want it to think it is zoomed or panned.
    xAxis.setPrivate("selectionMin", undefined);
    xAxis.setPrivate("selectionMax", undefined);

    // If pan then start and will change
    if (xAxis.get("start") !== 0 || xAxis.get("end") !== 1) {
        // reset the chart, so it doesn't think there is any zooming/panning
        // it's a lot simpler if we don't let the graph stay in the zoomed state.
        xAxis.set("start", 0);
        xAxis.set("end", 1);
        // set private avoids animations, we don't want any animations, because the
        // user has already zoomed or panned the axes to what they want to see.
        xAxis.setPrivate("min", toTimeRange.start.toMillis());
        xAxis.setPrivate("max", toTimeRange.end.toMillis());
    } else {
        // Set the new xaxis bounds, with animation enabled, e.g. the user has
        // navigated using the arrow buttons.
        xAxis.set("min", toTimeRange.start.toMillis());
        xAxis.set("max", toTimeRange.end.toMillis());
    }
}

export function addAnalyticsVisitorsSeries(chart: am5xy.XYChart) {
    // Set up the part of the graph showing visitors data from the analytics provider.
    const visitorsColor = am5.color("#0077CC");
    const visitorsYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(chart.root, {
            min: 0,
            maxDeviation: 0.2,
            renderer: am5xy.AxisRendererY.new(chart.root, {
                opposite: true,
            }),
        })
    );
    const visitorsYAxisRenderer = visitorsYAxis.get("renderer");
    visitorsYAxisRenderer.grid.template.setAll({
        // Hide the grid lines on the second yaxis
        strokeOpacity: 0,
    });
    visitorsYAxisRenderer.labels.template.setAll({
        fill: visitorsColor,
    });

    visitorsYAxis.children.push(
        am5.Label.new(chart.root, {
            id: chart.root.dom.id + "VisitorsAxisLabel",
            rotation: 90,
            text: "Analytics Visitors", // Updated once we know the provider
            y: am5.p50,
            centerX: am5.p50,
            fill: visitorsColor,
        })
    );

    VISITORS_SERIES_NAMES.forEach((name) => {
        const tooltip = am5.Tooltip.new(chart.root, {
            labelText:
                "{name}: {valueX.formatDate('EEE d MMM HH:mm:ss')}: {valueY}",
            pointerOrientation: "horizontal",
        });
        const visitorsSeries = addLineSeries(chart, visitorsYAxis, {
            name,
            stroke: visitorsColor,
            fill: visitorsColor,
            tooltip,
        });
        // Plot it as a dashed line, because it's different kind of data to the main graph.
        visitorsSeries.strokes.template.setAll({
            strokeWidth: 2,
            strokeDasharray: [8, 4],
        });

        const cursorSeriesToSnapTo = chart.get("cursor")!.get("snapToSeries")!;
        cursorSeriesToSnapTo.push(visitorsSeries);
        chart.get("cursor")!.set("snapToSeries", cursorSeriesToSnapTo);
    });

    // noinspection JSIgnoredPromiseFromCall
    visitorsYAxis.hide();

    const xAxis = getXAxis(chart);
    chart.children.push(
        am5.Legend.new(chart.root, {
            id: `${xAxis.root.dom.id}VisitorsLegend`,
            position: "absolute",
            paddingTop: LEGEND_TOP_PADDING,
            // align the right-hand side of the legend to the right-hand side of the graph
            // this aligns to the right axis, not the right to the edge of the graph, which is
            // good, but I don't know why it's doing that.
            centerX: am5.percent(100),
            x: am5.percent(100),
            y: CHART_HEIGHT,
        })
    );
}

export function updateAnalyticsVisitorsSeries(
    chart: am5xy.XYChart,
    analytics: Analytics
) {
    const yAxis = chart.yAxes.getIndex(1);
    const legend = getChartItem(chart.root, "VisitorsLegend");
    VISITORS_SERIES_NAMES.forEach((name) =>
        getSeries(chart, name).data.setAll([])
    );

    if (analytics?.values !== undefined) {
        const visitorsSeriesName = getVisitorsSeriesName(analytics.provider!);
        getChartItem(chart.root, "VisitorsAxisLabel").set(
            "text",
            visitorsSeriesName
        );
        const visitorsSeries = getSeries(chart, visitorsSeriesName);

        chart.set("paddingRight", 0);
        visitorsSeries.data.setAll(
            analytics.values.map((value) => {
                return {
                    timestamp: parse(value.dateTime).toMillis(),
                    value: value.visitors,
                };
            })
        );
        yAxis?.show();

        visitorsSeries.set(
            "legendLabelText",
            `${visitorsSeriesName} (${analytics.config!.configID}: ${
                analytics.config!.configName
            })`
        );

        legend.data.setAll([visitorsSeries]);
        createLegendTooltips(chart, legend);
    } else {
        // When we have a yaxis, we need some padding, otherwise labels right on the edge of the
        // graph get chopped.
        chart.set("paddingRight", 20);
        yAxis?.hide();
        legend.data.setAll([]);
    }
}

function runOnZoomAnimationEnded(chart: am5xy.XYChart, callback: () => void) {
    const xaxis = getXAxis(chart);
    let dispose: IDisposer;

    function selectionChangeHandler() {
        // Stop this handler from firing again.
        dispose.dispose();
        callback();
    }

    // selectionMin will change as the animation progresses. Debounce
    // the handler, so we only call it when changes stop (animation ended).
    // Handler will unsubscribe itself via `dispose`.
    dispose = xaxis.onPrivate(
        "selectionMin",
        debounce(selectionChangeHandler, 100)
    );
}

type Dimensions = {
    height: number;
    width: number;
};

function useWindowDimensions(): Dimensions {
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }
        window.addEventListener("resize", handleResize);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return dimensions;
}

function configureLegendLayout(chart: am5xy.XYChart, mainLegend: am5.Legend) {
    const visitorsLegend = getChartItem(chart.root, "VisitorsLegend");

    // We must use the width of the div, because chart.width has not been updated yet.
    const chartDiv = document.getElementById(CHART_CONTAINER_ID)!;
    // Use the narrow style before the legends are too close, and allow a bit of breathing space.
    if (
        chartDiv.offsetWidth - 50 <
        mainLegend.width() + visitorsLegend.width()
    ) {
        if (mainLegend.get("x") !== visitorsLegend.get("x")) {
            // Not enough space, and we didn't move the visitors legend yet. So move it under
            // the main legend.
            visitorsLegend.set("x", mainLegend.get("x"));
            visitorsLegend.set("y", CHART_HEIGHT + mainLegend.height());
            visitorsLegend.set("centerX", am5.percent(0));
            mainLegend.set("paddingBottom", visitorsLegend.height());
        }
    } else {
        if (mainLegend.get("x") === visitorsLegend.get("x")) {
            // Enough space, and the visitors legend is under the main one. So move it out.
            visitorsLegend.set("x", am5.percent(100));
            visitorsLegend.set("y", CHART_HEIGHT);
            visitorsLegend.set("centerX", am5.percent(100));
            mainLegend.set("paddingBottom", 0);
        }
    }
}

export function useConfigureLegendLayoutEffect(rootRef: RootRef) {
    const windowDimensions = useWindowDimensions();
    // Re-configure the legend layout when the window dimensions change
    useEffect(() => {
        const root = rootRef.current!;
        const mainLegend = getChartItem(root, "LeftLegend");
        // Resize events fire a lot, so re-layout the legend in a start transition
        // so the ui doesn't get frozen while we do it.
        startTransition(() => {
            const chart = getChart(root);
            configureLegendLayout(chart, mainLegend);
        });

        // We don't want this to run when `root` or `mainLegend` change. We have a separate call
        // to `configureLegendLayout` in those cases.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowDimensions]);
}
