import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { IntercomBooter_data$key } from "./__generated__/IntercomBooter_data.graphql";
import { useIntercom } from "react-use-intercom";
import { useCookies } from "react-cookie";
import { useLogError } from "../lib/logging";

export function IntercomBooter({
    queryRef,
}: {
    queryRef: IntercomBooter_data$key;
}) {
    // Using a fragment to commonise the user fields doesn't seem to work.
    const { currentUser, impersonatorUser, environment } =
        useFragment<IntercomBooter_data$key>(
            graphql`
                fragment IntercomBooter_data on Query {
                    currentUser {
                        login
                        displayName
                        email
                        userID
                        roleID
                        intercomUserHash
                        customer {
                            customerID
                            name
                        }
                    }
                    impersonatorUser {
                        login
                        displayName
                        email
                        userID
                        roleID
                        intercomUserHash
                        customer {
                            customerID
                            name
                        }
                    }
                    environment
                }
            `,
            queryRef
        );
    const realUser = impersonatorUser ? impersonatorUser : currentUser;
    const { boot } = useIntercom();
    const [cookies] = useCookies(["DisableTribeIntercom"]);
    const logError = useLogError();

    if (cookies.DisableTribeIntercom !== true) {
        if (!realUser.email) {
            logError({
                subject: `User ${realUser.login} has no email address`,
            });
        }
        boot({
            name: realUser.displayName,
            email: realUser.email ?? undefined,
            userId: realUser.userID.toString(),
            userHash: realUser.intercomUserHash,
            customAttributes: {
                user_login: realUser.login,
                customer_id: realUser.customer.customerID,
                customer_name: realUser.customer.name,
                user_role: realUser.roleID,
                environment,
            },
        });
    }

    return <></>;
}
