import { commitLocalUpdate, useRelayEnvironment } from "react-relay";
import { NotificationsQuery$data } from "../components/__generated__/NotificationsQuery.graphql";
import { getTimestamp } from "./dateUtils";
import { getLocalState } from "./localState";
import { Environment } from "relay-runtime";
import { useCallback } from "react";

let notificationID = 0;
let linkID = 0;

type NotificationData = Omit<
    NonNullable<NotificationsQuery$data["localState"]>["notifications"][number],
    "id" | "timestamp" | "seen"
>;

const DEDUPLICATE_PERIOD_MS = 5 * 1000;

function createNotification(
    environment: Environment,
    notificationData: NotificationData
) {
    commitLocalUpdate(environment, (store) => {
        const localState = getLocalState(store);
        const notifications =
            localState.getLinkedRecords("notifications") || [];
        const now = getTimestamp();

        if (notifications.length > 0) {
            const lastNotification = notifications[notifications.length - 1];
            if (
                lastNotification.getValue("type") === notificationData.type &&
                lastNotification.getValue("title") === notificationData.title &&
                lastNotification.getValue("message") ===
                    notificationData.message &&
                lastNotification.getValue("ttl") === notificationData.ttl &&
                (lastNotification.getValue("timestamp") as number) >
                    now - DEDUPLICATE_PERIOD_MS
            ) {
                // Duplicate notification, don't do anything.
                return;
            }
        }

        const notification = store.create(
            `currentUser:Notification:${notificationID++}`,
            "Notification"
        );

        notification.setValue(notificationData.type, "type");
        notification.setValue(notificationData.title, "title");
        notification.setValue(notificationData.message, "message");
        notification.setValue(notificationData.ttl, "ttl");
        notification.setValue(notificationData.isImportant, "isImportant");
        notification.setValue(now, "timestamp");
        notification.setValue(notificationID, "id");
        notification.setValue(false, "seen");

        if (notificationData.link) {
            const link = store.create(
                `currentUser:Notification:Link:${linkID++}`,
                "Link"
            );
            link.setValue(notificationData.link.description, "description");
            link.setValue(notificationData.link.url, "url");
            notification.setLinkedRecord(link, "link");
        }

        localState.setLinkedRecords(
            [...notifications, notification],
            "notifications"
        );
    });
}

function deleteNotification(environment: Environment, notificationID: number) {
    commitLocalUpdate(environment, (store) => {
        const localState = getLocalState(store);
        const notifications =
            localState!.getLinkedRecords("notifications") || [];

        localState.setLinkedRecords(
            notifications.filter((notification) => {
                return notification.getValue("id") !== notificationID;
            }),
            "notifications"
        );
    });
}

function expireOldNotifications(environment: Environment) {
    commitLocalUpdate(environment, (store) => {
        const localState = getLocalState(store);
        const notifications =
            localState!.getLinkedRecords("notifications") || [];
        const now = getTimestamp();

        const filteredNotifications = notifications.filter((notification) => {
            return (
                now <
                (notification.getValue("timestamp") as number) +
                    (notification.getValue("ttl") as number) * 1000
            );
        });

        localState.setLinkedRecords(filteredNotifications, "notifications");
    });
}

export function useNotifications() {
    // Don't use Environment from RelayEnvironment.ts because it's not the right environment
    // when running tests.
    const environment = useRelayEnvironment();

    return {
        createNotification: useCallback(
            (notificationData: NotificationData) => {
                createNotification(environment, notificationData);
            },
            [environment]
        ),
        deleteNotification: useCallback(
            (notificationID: number) => {
                deleteNotification(environment, notificationID);
            },
            [environment]
        ),
        expireOldNotifications: useCallback(() => {
            expireOldNotifications(environment);
        }, [environment]),
    };
}
