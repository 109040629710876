/**
 * @generated SignedSource<<1b5bc6f84ec02cc3fa5792ea1bbeee2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type graphUtils_pmeWindows$data = {
  readonly pmeWindows: ReadonlyArray<{
    readonly end: any;
    readonly start: any;
  }>;
  readonly " $fragmentType": "graphUtils_pmeWindows";
};
export type graphUtils_pmeWindows$key = {
  readonly " $data"?: graphUtils_pmeWindows$data;
  readonly " $fragmentSpreads": FragmentRefs<"graphUtils_pmeWindows">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "timeRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "graphUtils_pmeWindows",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "timeRange",
          "variableName": "timeRange"
        }
      ],
      "concreteType": "TimeRange",
      "kind": "LinkedField",
      "name": "pmeWindows",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "start",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "end",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserJourney",
  "abstractKey": null
};

(node as any).hash = "e47d0a15a95da136cf5fde6601a263d7";

export default node;
