/**
 * @generated SignedSource<<e724de5151e7b84428d652cb15346a61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type subscriptionsSamplesSubscription$variables = {
  backup: boolean;
  cursor?: any | null;
  startTime?: any | null;
};
export type subscriptionsSamplesSubscription$data = {
  readonly sampleEvents: ReadonlyArray<any>;
};
export type subscriptionsSamplesSubscription = {
  response: subscriptionsSamplesSubscription$data;
  variables: subscriptionsSamplesSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "backup"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startTime"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "backup",
        "variableName": "backup"
      },
      {
        "kind": "Variable",
        "name": "cursor",
        "variableName": "cursor"
      },
      {
        "kind": "Variable",
        "name": "startTime",
        "variableName": "startTime"
      }
    ],
    "kind": "ScalarField",
    "name": "sampleEvents",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "subscriptionsSamplesSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "subscriptionsSamplesSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "190b7ae4004d1a754c19646f2e89fba9",
    "id": null,
    "metadata": {},
    "name": "subscriptionsSamplesSubscription",
    "operationKind": "subscription",
    "text": "subscription subscriptionsSamplesSubscription(\n  $backup: Boolean!\n  $cursor: SampleEventsCursor\n  $startTime: DateTime\n) {\n  sampleEvents(backup: $backup, cursor: $cursor, startTime: $startTime)\n}\n"
  }
};
})();

(node as any).hash = "fdd7178809813532f47e83f5b6a36e3e";

export default node;
