import React from "react";
import ReactDOM from "react-dom/client";
import "./global.scss";
import { IntercomProvider } from "react-use-intercom";
import { App } from "./App";
import { ErrorBoundary } from "./components/ErrorBoundary";
import reportWebVitals from "./reportWebVitals";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { RelayEnvironmentProvider } from "react-relay";
import Environment from "./RelayEnvironment";
import { GlobalContextProvider } from "./components/GlobalContext";
import { CookiesProvider } from "react-cookie";

let root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <RelayEnvironmentProvider environment={Environment}>
        <CookiesProvider>
            <ErrorBoundary>
                <IntercomProvider appId={"na226xq6"}>
                    <GlobalContextProvider>
                        <App />
                    </GlobalContextProvider>
                </IntercomProvider>
            </ErrorBoundary>
        </CookiesProvider>
    </RelayEnvironmentProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
