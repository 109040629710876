/**
 * @generated SignedSource<<2bd9b3d6b979a46b60f8cbe790de6571>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UJMenu_UJs$data = ReadonlyArray<{
  readonly customer: {
    readonly name: string;
  };
  readonly name: string;
  readonly platformType: string;
  readonly ujID: number;
  readonly " $fragmentType": "UJMenu_UJs";
}>;
export type UJMenu_UJs$key = ReadonlyArray<{
  readonly " $data"?: UJMenu_UJs$data;
  readonly " $fragmentSpreads": FragmentRefs<"UJMenu_UJs">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UJMenu_UJs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ujID",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platformType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "UserJourney",
  "abstractKey": null
};
})();

(node as any).hash = "919b0151162743ffafc7b5a21e9fe9c2";

export default node;
