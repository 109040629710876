import {
    subscriptionsSamplesSubscription,
    subscriptionsSamplesSubscription$data,
    subscriptionsSamplesSubscription$variables,
} from "./__generated__/subscriptionsSamplesSubscription.graphql";
import {
    commitLocalUpdate,
    Environment,
    GraphQLSubscriptionConfig,
    OperationType,
    RecordProxy,
    RecordSourceSelectorProxy,
} from "relay-runtime";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSubscription } from "react-relay";
import { useTimeout } from "../../lib/hooks";
import { useLogError } from "../../lib/logging";
import { subscriptionsCustomerSubscription } from "./__generated__/subscriptionsCustomerSubscription.graphql";
import { graphql } from "babel-plugin-relay/macro";
import { SampleStatus } from "./__generated__/Wallboard_userJourneySamples.graphql";
import { getLocalState } from "../../lib/localState";
import { useNotifications } from "../../lib/notifications";
import { subscriptionsClient } from "../../RelayEnvironment";
import { isForbiddenError } from "../../lib/auth";

const subscriptionsSamplesMaxAgeHours = 48;

const SAMPLE_OR_REBUILD_STREAM_EVENT_TYPES = [
    undefined,
    "NEW_SAMPLE",
    "DELETE_SAMPLE",
    "REBUILDING_STREAM",
] as const;

// We don't want to notify immediately, in case we reconnect quickly.
// Subscriptions will attempt to reconnect every RETRY_TIMEOUT_MS ms. Let's notify after they fail this arbitrary
// amount of times.
// If we're ever going to change the subscription retry timeout, we might want to adjust it or replace it with
// time based logic.
const NOTIFY_ON_CONNECTION_ERRORS_COUNT = 3;
const INITIAL_RETRY_TIMEOUT_MS = 5000;
const MAX_RETRY_TIMEOUT_MS = 120000;

interface UJEvent {
    readonly id: string;
    readonly ujID: number;
}

interface SampleEvent extends UJEvent {
    readonly timestamp: number;
}

export interface NewSampleEvent extends SampleEvent {
    readonly type?: "NEW_SAMPLE";
    readonly status?: SampleStatus;
    readonly pageDeliveryTotal: number;
    readonly allThirdPartyNoCustomerImpact?: true;
    readonly extraWarningsAllThirdPartyNoCustomerImpact?: boolean;
    readonly inPME?: true;
    readonly helperTarget?: string;
}

interface DeleteSampleEvent extends SampleEvent {
    readonly type: "DELETE_SAMPLE";
}

interface RebuildingStreamEvent extends UJEvent {
    readonly type: "REBUILDING_STREAM";
}

type SampleOrRebuildStreamEvent =
    | NewSampleEvent
    | DeleteSampleEvent
    | RebuildingStreamEvent;

type SamplesSubscriptionStore =
    RecordSourceSelectorProxy<subscriptionsSamplesSubscription$data>;

function removeOldSamples(
    store: SamplesSubscriptionStore,
    samples: RecordProxy[],
    threshold: number
) {
    const keepSamplesIndex = samples.findIndex(
        (sample) => (sample.getValue("timestamp") as number) >= threshold
    );

    if (keepSamplesIndex === -1) {
        return samples;
    }

    const samplesToRemove = samples.slice(0, keepSamplesIndex);
    const samplesToKeep = samples.slice(keepSamplesIndex);

    samplesToRemove.forEach((sample) => {
        if (process.env.NODE_ENV === "development") {
            console.debug(
                `Removing old sample: ${sample.getValue(
                    "ujID"
                )}, ${sample.getValue("timestamp")}`
            );
        }
        store.delete(sample.getDataID());
    });

    return samplesToKeep;
}

type PartialSubscriptionConfig<SubscriptionType extends OperationType> = Omit<
    GraphQLSubscriptionConfig<SubscriptionType>,
    "variables"
>;

function useUpToDateRef<T>(value: T) {
    const ref = useRef(value);
    ref.current = value;
    return ref;
}

function useNotifyOnLostConnection() {
    const { createNotification } = useNotifications();
    const [errorCount, setErrorCount] = useState(0);
    const connectionLostRef = useRef<boolean>(false);

    const onConnectionError = useCallback(() => {
        setErrorCount((prev) => prev + 1);
    }, []);

    // Reset error count whenever we connected successfully.
    useEffect(() => {
        return subscriptionsClient.on("connected", () => {
            setErrorCount(0);
        });
    }, []);

    useEffect(() => {
        if (
            errorCount === NOTIFY_ON_CONNECTION_ERRORS_COUNT &&
            !connectionLostRef.current
        ) {
            createNotification({
                type: "ERROR",
                title: "Lost connection to the live updates server",
                message:
                    "We'll attempt to reconnect in the background. Until then, you won't see any live updates.",
                link: null,
                ttl: 60,
                isImportant: true,
            });
            connectionLostRef.current = true;
        } else if (errorCount === 0 && connectionLostRef.current) {
            createNotification({
                type: "INFO",
                title: "Restored connection to the live updates server",
                message: "Live updates have resumed.",
                link: null,
                ttl: 60,
                isImportant: true,
            });
            connectionLostRef.current = false;
        }
    }, [createNotification, errorCount]);

    return [onConnectionError];
}

type HasMessage = {
    message: string;
};

function isArrayWithMessages(object: any): object is HasMessage[] {
    return object instanceof Array && object.every((e) => !!e.message);
}

function useResilientSubscription<SubscriptionType extends OperationType>(
    partialConfig: PartialSubscriptionConfig<SubscriptionType>,
    variables: GraphQLSubscriptionConfig<SubscriptionType>["variables"],
    restartConditions: any[] = []
): void {
    const [attempt, setAttempt] = useState(1);
    const logError = useLogError();
    // We need these to be up-to-date when we compute new config, but we mustn't make new config
    // every time they change, hence store them with useRef.
    const setRetryTimeoutRef = useUpToDateRef(useTimeout()[0]);
    const variablesRef = useUpToDateRef(variables);
    const [onConnectionError] = useNotifyOnLostConnection();

    const config = useMemo((): GraphQLSubscriptionConfig<SubscriptionType> => {
        return {
            ...partialConfig,
            // Relay declares `error` to be an `Error`, but (so far) it never actually is. If the
            // server emits an error on the subscription, then we get an object like
            // `[{message: "Error message"}]`. If the connection drops then we get an `Event` with
            // no useful information in it.
            onError: (object: any) => {
                console.log("error", object);
                let error;
                if (object instanceof Event) {
                    onConnectionError();
                } else if (isArrayWithMessages(object)) {
                    if (object.length > 0 && isForbiddenError(object[0])) {
                        // The user is not authenticated. Perhaps they logged out in another tab
                        // and then we had to reconnect. We could notify them, so that we could keep
                        // the page open, but they'd have to load the login page anyway, so let's do
                        // it for them.
                        window.location.reload();
                    }
                    error = JSON.stringify(object);
                } else {
                    error = object;
                }

                if (error) {
                    logError({
                        subject: "Subscription error",
                        error,
                    });
                }

                if (partialConfig.onError) {
                    partialConfig.onError(error);
                }

                const retryTimeout = Math.min(
                    MAX_RETRY_TIMEOUT_MS,
                    attempt * INITIAL_RETRY_TIMEOUT_MS
                );
                console.log(`Retrying in ${retryTimeout}ms`);
                setRetryTimeoutRef.current(() => {
                    setAttempt((a) => a + 1);
                }, retryTimeout);
            },
            variables: variablesRef.current,
        };
    }, [
        partialConfig,
        variablesRef,
        setRetryTimeoutRef,
        logError,
        onConnectionError,
        attempt,
        // Eslint complains about unnecessary dependency, but we need it to force a new config and re-subscribe.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...restartConditions,
    ]);

    useSubscription(config);
}

export function useCustomerSubscription(): void {
    const partialConfig = useMemo(
        (): PartialSubscriptionConfig<subscriptionsCustomerSubscription> => ({
            subscription: graphql`
                subscription subscriptionsCustomerSubscription(
                    $cursor: CustomerEventsCursor
                ) {
                    customerEvents(cursor: $cursor) {
                        __typename
                        ... on GlobalReloadEvent {
                            id
                        }
                    }
                }
            `,
            updater: (store, data) => {
                if (data === null || data === undefined) {
                    return;
                }

                data.customerEvents.forEach((event) => {
                    switch (event.__typename) {
                        case "GlobalReloadEvent":
                            window.location.reload();
                            break;
                        default:
                            console.warn(
                                `Unexpected event type: ${event.__typename}.`
                            );
                    }
                });
            },
        }),
        []
    );
    useResilientSubscription(partialConfig, { cursor: null });
}

function handleUJEvents(
    ujID: number,
    store: RecordSourceSelectorProxy<subscriptionsSamplesSubscription$data>,
    localSamples: RecordProxy,
    events: SampleOrRebuildStreamEvent[],
    localLatestIDs: {
        [p: number]: string;
    },
    oldSamplesThreshold: number
) {
    let recentSamples = localSamples.getLinkedRecords("recent") ?? [];

    for (const event of events) {
        switch (event.type) {
            case undefined:
            case "NEW_SAMPLE":
                handleNewSample(store, event, recentSamples);
                break;
            case "DELETE_SAMPLE":
                handleDeleteSample(store, event, recentSamples);
                break;
            case "REBUILDING_STREAM":
                handleRebuildingStream(store, recentSamples);
                break;
        }
    }

    recentSamples = removeOldSamples(store, recentSamples, oldSamplesThreshold);
    localSamples.setLinkedRecords(recentSamples, "recent");
    if (recentSamples.length > 0) {
        localSamples.setLinkedRecord(
            recentSamples[recentSamples.length - 1],
            "latest"
        );
    }
    localLatestIDs[ujID] = events[events.length - 1].id;
}

export function useSamplesSubscription(
    backup: boolean,
    timeSpanHours: number
): void {
    const [latestIDs, setLatestIDs] = useState<{ [key: number]: string }>({});
    const [longestTimeSpanHours, setLongestTimeSpanHours] =
        useState(timeSpanHours);
    if (timeSpanHours > longestTimeSpanHours) {
        setLongestTimeSpanHours(timeSpanHours);
        setLatestIDs({});
    }

    const partialConfig =
        useMemo((): PartialSubscriptionConfig<subscriptionsSamplesSubscription> => {
            // This is the same data as `latestIDs`. We have to collect it twice because we
            // don't see changes to `latestIDs` here. (We see them when the deps change, but
            // they don't.)
            const localLatestIDs: { [key: number]: string } = {};
            return {
                subscription: graphql`
                    subscription subscriptionsSamplesSubscription(
                        $backup: Boolean!
                        $cursor: SampleEventsCursor
                        $startTime: DateTime
                    ) {
                        sampleEvents(
                            backup: $backup
                            cursor: $cursor
                            startTime: $startTime
                        )
                    }
                `,
                updater: (store, data) => {
                    if (data === null || data === undefined) {
                        return;
                    }

                    const eventsByUJID: {
                        [key: number]: SampleOrRebuildStreamEvent[];
                    } = {};
                    const oldSampleThreshold = DateTime.now()
                        .minus({
                            hour: subscriptionsSamplesMaxAgeHours,
                        })
                        .toSeconds();

                    data.sampleEvents.forEach(
                        // We optimistically assume the shape of data we received from the API is correct.
                        (event: SampleOrRebuildStreamEvent) => {
                            if (
                                !SAMPLE_OR_REBUILD_STREAM_EVENT_TYPES.includes(
                                    event.type
                                )
                            ) {
                                // Future event types we don't handle yet come here.
                                return;
                            }

                            if (eventsByUJID[event.ujID] === undefined) {
                                eventsByUJID[event.ujID] = [event];
                            } else {
                                eventsByUJID[event.ujID].push(event);
                            }
                        }
                    );

                    const localState = getLocalState(store);
                    const allLocalSamples =
                        localState.getLinkedRecords("samples", { backup }) ??
                        [];
                    const localSamplesByUJID = Object.fromEntries(
                        allLocalSamples.map((s) => [s.getValue("ujID"), s])
                    );
                    for (let [ujID, events] of Object.entries(eventsByUJID)) {
                        let localSamples = localSamplesByUJID[ujID];
                        if (!localSamples) {
                            localSamples = store.create(
                                `LocalSamples:${backup}:${ujID}`,
                                "LocalSamples"
                            );
                            localSamples.setValue(ujID, "ujID");
                            allLocalSamples.push(localSamples);
                        }
                        handleUJEvents(
                            parseInt(ujID),
                            store,
                            localSamples,
                            events,
                            localLatestIDs,
                            oldSampleThreshold
                        );
                    }
                    localState.setLinkedRecords(allLocalSamples, "samples", {
                        backup,
                    });

                    setLatestIDs((prev) => ({
                        ...prev,
                        ...localLatestIDs,
                    }));
                },
            };
        }, [backup]);

    let variables: subscriptionsSamplesSubscription$variables;
    if (Object.keys(latestIDs).length === 0) {
        variables = {
            backup,
            startTime: DateTime.now().minus({
                hour: timeSpanHours,
            }),
        };
    } else {
        variables = { backup, cursor: latestIDs };
    }

    useResilientSubscription(partialConfig, variables, [longestTimeSpanHours]);
}

function handleNewSample(
    store: SamplesSubscriptionStore,
    event: NewSampleEvent,
    recentSamples: RecordProxy[]
) {
    const newRecentSample = store.create(
        `RecentSample:${event.id}`,
        "RecentSample"
    );
    newRecentSample.setValue(event.ujID, "ujID");
    newRecentSample.setValue(event.timestamp, "timestamp");
    newRecentSample.setValue(event.pageDeliveryTotal, "pageDeliveryTotal");
    newRecentSample.setValue(event.status ?? "OK", "status");
    newRecentSample.setValue(
        event.allThirdPartyNoCustomerImpact ?? false,
        "allThirdPartyNoCustomerImpact"
    );
    newRecentSample.setValue(
        event.extraWarningsAllThirdPartyNoCustomerImpact ?? null,
        "extraWarningsAllThirdPartyNoCustomerImpact"
    );
    newRecentSample.setValue(event.inPME ?? false, "inPME");
    newRecentSample.setValue(event.helperTarget ?? null, "helperTarget");
    recentSamples.push(newRecentSample);
}

function handleDeleteSample(
    store: SamplesSubscriptionStore,
    event: DeleteSampleEvent,
    recentSamples: RecordProxy[]
) {
    if (recentSamples.length === 0) {
        return;
    }
    const index = recentSamples.findIndex(
        (sample) => sample.getValue("timestamp") === event.timestamp
    );
    if (index !== -1) {
        // Delete the record itself. Normally, Relay would delete
        // unused records at some point, but we might be adding
        // another one with the same ID before this happens.
        // One of the scenarios is when there's a swap where backup
        // and main samples had the same monDateTime.
        store.delete(recentSamples[index].getDataID());
        recentSamples.splice(index, 1);
    }
}

function handleRebuildingStream(
    store: SamplesSubscriptionStore,
    recentSamples: RecordProxy[]
) {
    while (recentSamples.length > 0) {
        store.delete(recentSamples.pop()!.getDataID());
    }
}

export function clearRecentSamples(environment: Environment) {
    commitLocalUpdate(environment, (store) => {
        const localState = getLocalState(store);
        localState
            .getLinkedRecords("samples", { backup: false })
            ?.forEach((localSamples) => {
                localSamples.getLinkedRecords("recent")?.forEach((sample) => {
                    store.delete(sample.getDataID());
                });
                localSamples.setLinkedRecords([], "recent");
                // This fails, despite being allowed by the typing. We can get away with not
                // doing it as long as we trigger a reload of the events, because then we'll set it
                // again.
                // localSamples.setLinkedRecord(null, "recent");
            });
    });
}
