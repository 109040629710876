/**
 * @generated SignedSource<<dfd89c7a622ddbbcd1c5afba183d165b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LimitedTimeRangeInput = {
  end: any;
  start: any;
};
export type UJStepGraphQuery$variables = {
  backup: boolean;
  timeRange: LimitedTimeRangeInput;
  ujID: number;
};
export type UJStepGraphQuery$data = {
  readonly userJourney: {
    readonly stepResults: ReadonlyArray<{
      readonly number: number;
      readonly pageDelivery: number | null;
      readonly timestamp: number;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"amChartsUtils_useRoot" | "graphUtils_samples">;
};
export type UJStepGraphQuery = {
  response: UJStepGraphQuery$data;
  variables: UJStepGraphQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "backup"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timeRange"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ujID"
},
v3 = [
  {
    "kind": "Variable",
    "name": "ujId",
    "variableName": "ujID"
  }
],
v4 = {
  "kind": "Variable",
  "name": "backup",
  "variableName": "backup"
},
v5 = {
  "kind": "Variable",
  "name": "timeRange",
  "variableName": "timeRange"
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageDelivery",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v5/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UJStepGraphQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "graphUtils_samples"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "amChartsUtils_useRoot"
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserJourney",
        "kind": "LinkedField",
        "name": "userJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "BulkStepResult",
            "kind": "LinkedField",
            "name": "stepResults",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UJStepGraphQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showThirdPartyWarnings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showThirdPartyErrors",
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAdmin",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserJourney",
        "kind": "LinkedField",
        "name": "userJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "TimeRange",
            "kind": "LinkedField",
            "name": "pmeWindows",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "end",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v4/*: any*/),
              {
                "kind": "Literal",
                "name": "pmeOn",
                "value": false
              },
              (v5/*: any*/)
            ],
            "concreteType": "BasicSample",
            "kind": "LinkedField",
            "name": "samples",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allThirdPartyNoCustomerImpact",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extraWarningsAllThirdPartyNoCustomerImpact",
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "UJVersion",
            "kind": "LinkedField",
            "name": "versions",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "summary",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "details",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Step",
                "kind": "LinkedField",
                "name": "steps",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "BulkStepResult",
            "kind": "LinkedField",
            "name": "stepResults",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "999e74f032a2fd2ab3942e858926d340",
    "id": null,
    "metadata": {},
    "name": "UJStepGraphQuery",
    "operationKind": "query",
    "text": "query UJStepGraphQuery(\n  $ujID: Int!\n  $timeRange: LimitedTimeRangeInput!\n  $backup: Boolean!\n) {\n  ...graphUtils_samples\n  ...amChartsUtils_useRoot\n  userJourney(ujId: $ujID) {\n    stepResults(timeRange: $timeRange, backup: $backup) {\n      timestamp\n      number\n      pageDelivery\n      id\n    }\n    id\n  }\n}\n\nfragment amChartsUtils_useRoot on Query {\n  currentUser {\n    isAdmin\n    id\n  }\n}\n\nfragment graphUtils_groupedSamples on Query {\n  ...urlParams_thirdPartyToggleDefaults\n}\n\nfragment graphUtils_pmeWindows on UserJourney {\n  pmeWindows(timeRange: $timeRange) {\n    start\n    end\n  }\n}\n\nfragment graphUtils_preparedSamples on UserJourney {\n  ...graphUtils_pmeWindows\n  samples(timeRange: $timeRange, pmeOn: false, backup: $backup) {\n    timestamp\n    status\n    allThirdPartyNoCustomerImpact\n    extraWarningsAllThirdPartyNoCustomerImpact\n    id\n  }\n}\n\nfragment graphUtils_samples on Query {\n  ...graphUtils_groupedSamples\n  userJourney(ujId: $ujID) {\n    ...graphUtils_preparedSamples\n    ...graphUtils_ujVersions\n    id\n  }\n}\n\nfragment graphUtils_ujVersions on UserJourney {\n  versions(timeRange: $timeRange) {\n    start\n    summary\n    details\n    steps {\n      id\n      name\n    }\n    id\n  }\n}\n\nfragment urlParams_thirdPartyToggleDefaults on Query {\n  currentUser {\n    customer {\n      showThirdPartyWarnings\n      showThirdPartyErrors\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9b6249c3fafd19a5cb5d4c56bb85791";

export default node;
