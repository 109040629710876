import groupBy from "lodash/groupBy";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { userJourneys_userPreferences$key } from "./__generated__/userJourneys_userPreferences.graphql";

type WithCustomerName = {
    readonly customer: {
        readonly name: string;
    };
};

/**
 * Groups User Journeys by their customer name. Resulting map keys are ordered alphabetically.
 * @param userJourneys
 */
export function groupByCustomerName<T extends WithCustomerName>(
    userJourneys: ReadonlyArray<T>
) {
    const groups = groupBy(userJourneys, (uj) => {
        return uj.customer.name;
    });

    const orderedGroupNames = Object.keys(groups).sort();
    const orderedGroups = new Map<string, T[]>();
    orderedGroupNames.forEach((groupName) => {
        orderedGroups.set(groupName, groups[groupName]);
    });

    return orderedGroups;
}

type UJGroupPreferencesWithUJs<UJType> = {
    groupID: number;
    hidden: boolean;
    ujs: ReadonlyArray<UJType>;
};

/**
 * Returns a map of user journey groups for given User Journeys, keyed by group name. Each group contains a list of User
 * Journeys, and a boolean indicating whether the group is hidden.
 */
export function useUJGroupPreferencesForUJs<UJType extends { ujID: number }>(
    userJourneys: ReadonlyArray<UJType>,
    userQueryRef: userJourneys_userPreferences$key
): Map<string, UJGroupPreferencesWithUJs<UJType>> {
    const user = useFragment(
        graphql`
            fragment userJourneys_userPreferences on User {
                preferences {
                    userJourneyGroups {
                        groupID
                        name
                        hidden
                        ujIDs
                    }
                }
            }
        `,
        userQueryRef
    );

    const ujsByID: { [k: number]: UJType } = {};
    userJourneys.forEach((uj) => {
        ujsByID[uj.ujID] = uj;
    });

    const sortedGroups = new Map<string, UJGroupPreferencesWithUJs<UJType>>();
    user.preferences.userJourneyGroups.forEach((group) => {
        sortedGroups.set(group.name, {
            groupID: group.groupID,
            hidden: group.hidden,
            // We may not be sorting all the user's UJs, e.g. on the wallboard we only show running
            // UJs. So there may be ujIDs which are not in `ujsByID`.
            ujs: group.ujIDs.map((ujID) => ujsByID[ujID]).filter((uj) => !!uj),
        });
    });
    return sortedGroups;
}
