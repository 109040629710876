/**
 * @generated SignedSource<<f9bdd40341d01450d9ae1a1902b38517>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SampleStatus = "DEBUG" | "ERROR" | "OK" | "SLOW" | "UNKNOWN" | "WARNING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Wallboard_userJourneySamples$data = {
  readonly recent: ReadonlyArray<{
    readonly allThirdPartyNoCustomerImpact: boolean;
    readonly extraWarningsAllThirdPartyNoCustomerImpact: boolean | null;
    readonly inPME: boolean;
    readonly pageDeliveryTotal: number;
    readonly status: SampleStatus;
    readonly timestamp: number;
  }> | null;
  readonly " $fragmentType": "Wallboard_userJourneySamples";
};
export type Wallboard_userJourneySamples$key = {
  readonly " $data"?: Wallboard_userJourneySamples$data;
  readonly " $fragmentSpreads": FragmentRefs<"Wallboard_userJourneySamples">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Wallboard_userJourneySamples",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RecentSample",
          "kind": "LinkedField",
          "name": "recent",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allThirdPartyNoCustomerImpact",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extraWarningsAllThirdPartyNoCustomerImpact",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "inPME",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pageDeliveryTotal",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "LocalSamples",
  "abstractKey": null
};

(node as any).hash = "923368b56df5220743d5628daad97ce9";

export default node;
