/**
 * @generated SignedSource<<5125cb671c599c7db590e4630816825f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SampleStatus = "DEBUG" | "ERROR" | "OK" | "SLOW" | "UNKNOWN" | "WARNING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HeatTiles_heatTiles$data = {
  readonly localState: {
    readonly samples: ReadonlyArray<{
      readonly recent: ReadonlyArray<{
        readonly helperTarget: string | null;
        readonly status: SampleStatus;
        readonly timestamp: number;
      }> | null;
      readonly ujID: number;
    }> | null;
  } | null;
  readonly userJourneys: ReadonlyArray<{
    readonly helper: {
      readonly testLabel: string;
    } | null;
    readonly ujID: number;
  }>;
  readonly " $fragmentType": "HeatTiles_heatTiles";
};
export type HeatTiles_heatTiles$key = {
  readonly " $data"?: HeatTiles_heatTiles$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeatTiles_heatTiles">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ujID",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "backup"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeatTiles_heatTiles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserJourney",
      "kind": "LinkedField",
      "name": "userJourneys",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UJHelper",
          "kind": "LinkedField",
          "name": "helper",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "testLabel",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LocalState",
          "kind": "LinkedField",
          "name": "localState",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "backup",
                  "variableName": "backup"
                }
              ],
              "concreteType": "LocalSamples",
              "kind": "LinkedField",
              "name": "samples",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RecentSample",
                  "kind": "LinkedField",
                  "name": "recent",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timestamp",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "helperTarget",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "02934dab91d6c675eecbef4ca7776916";

export default node;
