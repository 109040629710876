/**
 * @generated SignedSource<<b5e03e229bd94b4b2ac55c5014f5c302>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AnalyticsErrorCode = "CONFIG_NOT_FOUND" | "GENERAL" | "INSUFFICIENT_PERMISSIONS" | "NO_ACCESS" | "%future added value";
export type AnalyticsPartialErrorCode = "FOUND_UNCATEGORISED_DATA" | "%future added value";
export type AnalyticsProvider = "ADOBE" | "GOOGLE" | "GOOGLE_UA" | "%future added value";
export type LimitedTimeRangeInput = {
  end: any;
  start: any;
};
export type UserJourney_AnalyticsQuery$variables = {
  timeRange: LimitedTimeRangeInput;
  ujID: number;
};
export type UserJourney_AnalyticsQuery$data = {
  readonly analyticsVisitors: {
    readonly code?: AnalyticsErrorCode;
    readonly config?: {
      readonly configID: string | null;
      readonly configName: string | null;
    };
    readonly partialErrorCode?: AnalyticsPartialErrorCode | null;
    readonly provider?: AnalyticsProvider;
    readonly values?: ReadonlyArray<{
      readonly dateTime: any;
      readonly visitors: number;
    }>;
  } | null;
};
export type UserJourney_AnalyticsQuery = {
  response: UserJourney_AnalyticsQuery$data;
  variables: UserJourney_AnalyticsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timeRange"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ujID"
},
v2 = [
  {
    "kind": "Variable",
    "name": "timeRange",
    "variableName": "timeRange"
  },
  {
    "kind": "Variable",
    "name": "ujID",
    "variableName": "ujID"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AnalyticsConfig",
  "kind": "LinkedField",
  "name": "config",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AnalyticsDataPoint",
      "kind": "LinkedField",
      "name": "values",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visitors",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partialErrorCode",
      "storageKey": null
    }
  ],
  "type": "AnalyticsVisitorsData",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "type": "AnalyticsVisitorsError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserJourney_AnalyticsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "analyticsVisitors",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserJourney_AnalyticsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "analyticsVisitors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "faf8642848bfbb2cd2319e7be6701ecc",
    "id": null,
    "metadata": {},
    "name": "UserJourney_AnalyticsQuery",
    "operationKind": "query",
    "text": "query UserJourney_AnalyticsQuery(\n  $ujID: Int!\n  $timeRange: LimitedTimeRangeInput!\n) {\n  analyticsVisitors(ujID: $ujID, timeRange: $timeRange) {\n    __typename\n    ... on AnalyticsVisitorsData {\n      provider\n      values {\n        dateTime\n        visitors\n      }\n      config {\n        configID\n        configName\n      }\n      partialErrorCode\n    }\n    ... on AnalyticsVisitorsError {\n      provider\n      code\n      config {\n        configID\n        configName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b60e9b4d9198683c413eb40e553022e2";

export default node;
