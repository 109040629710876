/**
 * @generated SignedSource<<21dc3443cf8e076d1251c0d21bd3cb9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type subscriptionsCustomerSubscription$variables = {
  cursor?: any | null;
};
export type subscriptionsCustomerSubscription$data = {
  readonly customerEvents: ReadonlyArray<{
    readonly __typename: "GlobalReloadEvent";
    readonly id: string;
  }>;
};
export type subscriptionsCustomerSubscription = {
  response: subscriptionsCustomerSubscription$data;
  variables: subscriptionsCustomerSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cursor",
        "variableName": "cursor"
      }
    ],
    "concreteType": "GlobalReloadEvent",
    "kind": "LinkedField",
    "name": "customerEvents",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "subscriptionsCustomerSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "subscriptionsCustomerSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "26b194bb56c5e03ad1c8dbafccdad6f8",
    "id": null,
    "metadata": {},
    "name": "subscriptionsCustomerSubscription",
    "operationKind": "subscription",
    "text": "subscription subscriptionsCustomerSubscription(\n  $cursor: CustomerEventsCursor\n) {\n  customerEvents(cursor: $cursor) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "456c8fdfd81bc17fa49a352b83f89530";

export default node;
