import { DateTime, DateTimeFormatOptions, DateTimeUnit, Settings } from "luxon";

export function configureLuxon(timeZoneName: string, locale: string) {
    Settings.defaultZone = timeZoneName;
    Settings.defaultLocale = locale;
}

export type TimeRange = {
    start: DateTime;
    end: DateTime;
};

// URL params must be in this specific format, with a specified offset.
// noinspection SpellCheckingInspection
const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ssZZ";

export function isThisYear(date: DateTime) {
    return date.hasSame(DateTime.now(), "year");
}

export function formatDateInUTC(date: DateTime) {
    return date.toUTC().toFormat(DATE_FORMAT);
}

export function parse(dateString: string) {
    return DateTime.fromFormat(dateString, DATE_FORMAT);
}

export function parseWithMicroseconds(dateString: string) {
    // Sadly, luxon does not have a token for microseconds, but parsing with
    // ISO format works.
    return DateTime.fromISO(dateString);
}

/**
 * Checks whether the given time range is more than two weeks in the local
 * time zone.
 */
export function isMoreThanTwoWeeks(timeRange: TimeRange) {
    return timeRange.end.diff(timeRange.start, "days").days > 14;
}

function sameTime(first: DateTime, second: DateTime) {
    return first.hour === second.hour && sameMinuteAndSecond(first, second);
}

function sameMinuteAndSecond(first: DateTime, second: DateTime) {
    return first.minute === second.minute && first.second === second.second;
}

export function sameTimeRange(left: TimeRange, right: TimeRange) {
    return (
        formatDateInUTC(left.start) === formatDateInUTC(right.start) &&
        formatDateInUTC(left.end) === formatDateInUTC(right.end)
    );
}

function getTimeRangeShift(timeRange: TimeRange, amount: "half" | "full") {
    let offset, duration;

    const numDays = diffInDaysIfExact(timeRange);
    if (numDays !== null) {
        // The range is a whole number of days
        if (amount === "full") {
            offset = { day: numDays };
        } else {
            offset = {
                day: Math.floor(numDays / 2),
                hour: 12 * (numDays % 2),
            };
        }

        return { offset, duration: { day: numDays } };
    }

    const numHours = diffInHoursIfExact(timeRange);
    if (numHours !== null) {
        // The range is a whole number of hours
        if (amount === "full") {
            offset = { hour: numHours };
        } else {
            offset = {
                hour: Math.floor(numHours / 2),
                minute: 30 * (numHours % 2),
            };
        }

        return { offset, duration: { hour: numHours } };
    }

    // Custom range.
    duration = { second: diffInSeconds(timeRange) };
    if (amount === "full") {
        offset = { second: duration.second };
    } else {
        offset = { second: Math.floor(duration.second / 2) };
    }

    return { offset, duration };
}

export function shiftTimeRange(
    timeRange: TimeRange,
    direction: "forward" | "back",
    amount: "full" | "half"
) {
    // TODO (later): shift by months and quarters
    let { offset, duration } = getTimeRangeShift(timeRange, amount);
    const start =
        direction === "forward"
            ? timeRange.start.plus(offset)
            : timeRange.start.minus(offset);

    return {
        start,
        end: start.plus(duration),
    };
}

/**
 * Prepare for diff calculations by stripping milliseconds
 */
function prepareForDiff(timeRange: TimeRange) {
    return {
        start: timeRange.start.startOf("second"),
        end: timeRange.end.startOf("second"),
    };
}

function diffInMonthsIfExact(timeRange: TimeRange) {
    const { start, end } = prepareForDiff(timeRange);
    const diff = end.diff(start, ["months", "milliseconds"]);
    if (diff.months !== 0 && diff.milliseconds === 0) {
        return diff.months;
    } else {
        return null;
    }
}

function diffInDaysIfExact(timeRange: TimeRange) {
    const { start, end } = prepareForDiff(timeRange);
    if (sameTime(start, end)) {
        return end.diff(start, "days").days;
    } else {
        return null;
    }
}

function diffInHoursIfExact(timeRange: TimeRange) {
    const { start, end } = prepareForDiff(timeRange);
    if (sameMinuteAndSecond(start, end)) {
        return end.diff(start, "hours").hours;
    } else {
        return null;
    }
}

function diffInSeconds(timeRange: TimeRange) {
    const { start, end } = prepareForDiff(timeRange);
    return end.diff(start, "seconds").seconds;
}

export function getTimeRangePeriod(timeRange: TimeRange) {
    function formatPeriod(name: string, length: number, half: boolean) {
        if (length === 1) {
            if (half) {
                if (name === "hour") {
                    return "half an hour";
                } else {
                    return `half a ${name}`;
                }
            } else {
                return `1 ${name}`;
            }
        } else {
            if (half) {
                if (length % 2 === 0) {
                    return (
                        `${length / 2} ${name}` + (length / 2 === 1 ? "" : "s")
                    );
                } else {
                    return `${length / 2} ${name}s`;
                }
            } else {
                return `${length} ${name}s`;
            }
        }
    }

    let periodName;
    let length;

    const numMonths = diffInMonthsIfExact(timeRange);
    if (numMonths !== null) {
        periodName = "month";
        length = numMonths;
    } else {
        const numDays = diffInDaysIfExact(timeRange);
        if (numDays !== null) {
            if (numDays % 7 === 0) {
                periodName = "week";
                length = numDays / 7;
            } else {
                periodName = "day";
                length = numDays;
            }
        } else {
            const numHours = diffInHoursIfExact(timeRange);
            if (numHours !== null) {
                periodName = "hour";
                length = numHours;
            } else {
                periodName = "period";
                length = 1;
            }
        }
    }

    return {
        period: formatPeriod(periodName, length, false),
        halfPeriod: formatPeriod(periodName, length, true),
    };
}

export function startOfNext(date: DateTime, unit: DateTimeUnit) {
    return date.endOf(unit).plus({ millisecond: 1 });
}

export function justBeforeIfMidnight(date: DateTime) {
    return isMidnight(date) ? date.minus({ second: 1 }) : date;
}

export function midnightIfJustBefore(date: DateTime) {
    const oneSecondLater = date.plus({ second: 1 });
    return isMidnight(oneSecondLater) ? oneSecondLater : date;
}

export function getNowRange(timeRange: TimeRange): TimeRange {
    const now = DateTime.now();
    const numDays = diffInDaysIfExact(timeRange);
    if (numDays !== null && numDays > 3) {
        // treat a week just like a 7-day range instead of specially like a calendar week,
        // because we don't care (yet) about calendar weeks in the performance graphs.
        return {
            start: now.startOf("day").plus({ day: 1 }).minus({ day: numDays }),
            end: startOfNext(now, "day"),
        };
    }

    const numHours = diffInHoursIfExact(timeRange);
    if (numHours !== null) {
        // todo implement something better for 1 hour ranges which will be empty when the current
        //  time is exactly on the hour, or implement a minimum timerange, maybe 3 hours?
        // This is a range of whole hours, or a short number of days.

        const nextHour = now.startOf("hour").plus({ hour: 1 });
        if (numDays !== null && numDays <= 3) {
            // Keep the day range, but end at the end of the current hour instead of at the
            // end of the current day. To avoid having too much white space.
            return {
                start: nextHour.minus({ day: numDays }),
                end: nextHour,
            };
        } else {
            return {
                start: nextHour.minus({ hour: numHours }),
                end: nextHour,
            };
        }
    }

    // custom range, someone zoomed or manually set the time to a non-whole hour or daily range,
    // and now want to jump to now. Let's get them back into a nicer duration.

    // Snap the time range to whole hours if <= 3 days, otherwise snap to whole days.
    // Where snapping is always increasing the length of the time range
    if (timeRange.end.diff(timeRange.start, "days").days <= 3) {
        return getNowRange({
            start: timeRange.start.startOf("hour"),
            end: startOfNext(timeRange.end, "hour"),
        });
    } else {
        return getNowRange({
            start: timeRange.start.startOf("day"),
            end: startOfNext(timeRange.end, "day"),
        });
    }
}

export function timeRangeToString(timeRange: TimeRange) {
    return `${formatDateInUTC(timeRange.start)}-${formatDateInUTC(
        timeRange.end
    )}`;
}

/** Returns the calendar timerange for the given date and duration
 * e.g. the month timerange for the month that the date is in.
 */
function getCalendarTimeRange(date: DateTime, unit: "month" | "week" | "day") {
    return {
        start: date.startOf(unit),
        end: startOfNext(date, unit),
    };
}

/** Returns the timerange for the current duration
 *
 *  e.g. this week's timerange or this month's timerange.
 */
export function getThisTimeRange(unit: "month" | "week" | "day"): TimeRange {
    const now = DateTime.now();
    return getCalendarTimeRange(now, unit);
}

/** Returns the timerange for the last duration
 *
 *  e.g. last week's timerange or last month's timerange.
 */
export function getLastTimeRange(unit: "month" | "week" | "day") {
    const date = DateTime.now().minus({ [unit]: 1 });
    return getCalendarTimeRange(date, unit);
}

/** Returns the Last X hours or days timerange from now.
 * Snapping now to the end of the current duration
 *
 *  e.g. The last 7 days timerange ending at the end of today.
 *  or The last 24 hours timerange ending at the end of the current hour.
 */
export function getLastXTimeRange(unit: "day" | "hour", number: number) {
    const end = startOfNext(DateTime.now(), unit);
    const start = end.minus({ [unit]: number });

    return {
        start: start,
        end: end,
    };
}

export function isMidnight(date: DateTime) {
    return date.hour === 0 && date.minute === 0 && date.second === 0;
}

export function getTimestamp() {
    return DateTime.now().toMillis();
}

export function relative(timestamp: number): string {
    const relativeString = DateTime.fromMillis(timestamp).toRelative();
    if (relativeString === null) {
        throw new Error(`Invalid timestamp ${timestamp}`);
    } else {
        return relativeString;
    }
}

type RecentDateTimeProps = {
    timeSpanHours: number;
    timestamp: number;
    showSeconds: boolean;
};

export function RecentDateTime(props: RecentDateTimeProps) {
    const dateTime = DateTime.fromSeconds(props.timestamp);
    const format: DateTimeFormatOptions = {
        hour: "numeric",
        minute: "numeric",
    };
    if (props.showSeconds) {
        format.second = "numeric";
    }
    if (props.timeSpanHours > 24 && dateTime < DateTime.now().startOf("day")) {
        format.weekday = "short";
    }
    return <>{dateTime.toLocaleString(format)}</>;
}
