/**
 * @generated SignedSource<<66e934b581abcdf1fa943ecf4717ad38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DropdownMenus_UJPageMenu$data = {
  readonly isAdmin: boolean;
  readonly " $fragmentType": "DropdownMenus_UJPageMenu";
};
export type DropdownMenus_UJPageMenu$key = {
  readonly " $data"?: DropdownMenus_UJPageMenu$data;
  readonly " $fragmentSpreads": FragmentRefs<"DropdownMenus_UJPageMenu">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DropdownMenus_UJPageMenu",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAdmin",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ce651b52ade8e50f61e458de47c1ab22";

export default node;
