import { graphql } from "babel-plugin-relay/macro";
import {
    HTMLAttributes,
    Suspense,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import {
    PreloadedQuery,
    useFragment,
    usePreloadedQuery,
    useRelayEnvironment,
} from "react-relay";
import { Wallboard_userJourneyRow$key } from "./__generated__/Wallboard_userJourneyRow.graphql";
import {
    Wallboard_userJourneySamples$data,
    Wallboard_userJourneySamples$key,
} from "./__generated__/Wallboard_userJourneySamples.graphql";
import { WallboardQuery } from "./__generated__/WallboardQuery.graphql";
import { DateTime } from "luxon";
import { useLoaderData } from "react-router-dom";
import {
    InvalidURLParameter,
    useOldPortalURLSearchParams,
    usePMEToggle,
    useThirdPartyToggles,
    useURLIntegerParam,
} from "../../lib/urlParams";
import { StatusBar } from "./StatusBar";
import styles from "./Wallboard.module.scss";
import { SymmetricBar } from "../../components/utils";
import { useUJGroupPreferencesForUJs } from "../../lib/userJourneys";
import { ToggleMenu } from "../../components/ToggleMenu";
import {
    clearRecentSamples,
    useCustomerSubscription,
    useSamplesSubscription,
} from "./subscriptions";
import { ToggleMenu$key } from "../../components/__generated__/ToggleMenu.graphql";
import { useTitle } from "../../lib/title";
import { UJPlatformIcon } from "../../components/UJPlatformIcon";
import {
    Wallboard_bars$data,
    Wallboard_bars$key,
} from "./__generated__/Wallboard_bars.graphql";
import { TextWithTooltip } from "../../components/TextWithTooltip";
import { useDimensions } from "./dimensions";
import { HeatTiles } from "./HeatTiles";
import {
    getEffectiveStatus,
    SampleDisplayOptions,
    SampleLink,
} from "../../lib/samples";
import { RecentDateTime } from "../../lib/dateUtils";
import { useTimeout } from "../../lib/hooks";
import { useCookies } from "react-cookie";

const wallboardTimeSpanHoursValues = [3, 24, 48] as const;
type WallboardTimeSpanHours = typeof wallboardTimeSpanHoursValues[number];

export const wallboardQuery = graphql`
    query WallboardQuery($backup: Boolean!) {
        ...Wallboard_bars @arguments(backup: $backup)
        ...ToggleMenu
        ...HeatTiles_heatTiles @arguments(backup: $backup)
    }
`;
type UserJourneys = Wallboard_bars$data["userJourneys"];

function calculateCurrentMinute() {
    return DateTime.now().startOf("minute");
}

export function Wallboard() {
    // Show only main samples.
    //
    // If at some point we add backups, there will be an issue we need to solve.
    // Every time we switch between a backup and main, we'll be interrupting
    // subscription, and missing updates. We'd either have to keep cursor for
    // each subscription, or ditch existing data and start fresh.
    // We'll also need to add backup to the latest sample links.
    const backup = false;

    const [currentMinute, setCurrentMinute] = useState<DateTime>(
        calculateCurrentMinute()
    );
    const timeSpanHours = useTimeSpanHours()[0];

    useEffect(() => {
        const msToNextMinute = currentMinute
            .plus({ minute: 1 })
            .diff(DateTime.now())
            .toMillis();
        const timeout = setTimeout(() => {
            setCurrentMinute(calculateCurrentMinute());
        }, msToNextMinute + 1);

        return () => {
            clearTimeout(timeout);
        };
    }, [currentMinute]);

    useSamplesSubscription(backup, timeSpanHours);
    useCustomerSubscription();

    const data = usePreloadedQuery(
        wallboardQuery,
        useLoaderData() as PreloadedQuery<WallboardQuery>
    );

    useTitle("Live Status Wallboard");

    // The bars should end at the end of the current minute because
    // we'll get samples which started in the current minute.
    const endTime = currentMinute.plus({ minute: 1 });

    return (
        <>
            <Suspense>
                <SettingsBar queryRef={data} />
            </Suspense>
            <div className={styles.content} id={"bars-and-heat-tiles"}>
                {/* Safari needs this extra div to calculate the heights of the grid elements
                correctly. */}
                <div>
                    <div className={styles.bars}>
                        <div className={styles.ticksRow}>
                            <div
                                className={`${styles.columnFiller} sticky-top`}
                            ></div>
                            <Ticks
                                timeSpanHours={timeSpanHours}
                                endTime={currentMinute}
                                className={"sticky-top"}
                            />
                            <div
                                className={`${styles.columnFiller} sticky-top`}
                            ></div>
                        </div>
                        <Suspense>
                            <Bars
                                queryRef={data}
                                timeSpanHours={timeSpanHours}
                                endTime={endTime}
                            />
                        </Suspense>
                    </div>
                </div>
                <Suspense>
                    <HeatTiles
                        queryRef={data}
                        timeSpanHours={timeSpanHours}
                        endTime={endTime}
                    />
                </Suspense>
            </div>
        </>
    );
}

type BarsProps = {
    queryRef: Wallboard_bars$key;
    timeSpanHours: WallboardTimeSpanHours;
    endTime: DateTime;
};

function Bars({ queryRef, timeSpanHours, endTime }: BarsProps) {
    const data = useFragment(
        graphql`
            fragment Wallboard_bars on Query
            @argumentDefinitions(backup: { type: "Boolean!" }) {
                currentUser {
                    ...userJourneys_userPreferences
                }
                userJourneys {
                    ujID
                    status
                    ...Wallboard_userJourneyRow
                }
                localState {
                    samples(backup: $backup) {
                        ujID
                        ...Wallboard_userJourneySamples
                    }
                }
                ...urlParams_thirdPartyToggleDefaults
            }
        `,
        queryRef
    );
    const localSamplesRefsByUJID = Object.fromEntries(
        (data.localState?.samples || []).map((s) => [s.ujID, s])
    );

    const [showThirdPartyErrors, showThirdPartyWarnings] =
        useThirdPartyToggles(data);
    const [showPMEs] = usePMEToggle();
    const sampleDisplayOptions: SampleDisplayOptions = {
        showPMEs,
        showThirdPartyErrors,
        showThirdPartyWarnings,
    };

    const groupedUJs = useUJGroupPreferencesForUJs(
        data.userJourneys.filter((uj) => uj.status !== "OBSOLETE"),
        data.currentUser
    );

    return (
        <>
            {Array.from(groupedUJs).map(
                ([groupName, { groupID, hidden, ujs }]) => {
                    const localSamplesRefs = ujs.map(
                        (uj) => localSamplesRefsByUJID[uj.ujID]
                    );
                    return (
                        <UserJourneyGroup
                            key={groupName}
                            groupID={groupID}
                            groupName={groupName}
                            defaultHidden={hidden}
                            userJourneys={ujs}
                            localSamplesRefs={localSamplesRefs}
                            // The bars should end at the end of the current minute because we'll get
                            // samples which started in the current minute.
                            endTime={endTime}
                            timeSpanHours={timeSpanHours}
                            displayOptions={sampleDisplayOptions}
                        />
                    );
                }
            )}
        </>
    );
}

type UserJourneyComponentProps = {
    endTime: DateTime;
    timeSpanHours: WallboardTimeSpanHours;
    displayOptions: SampleDisplayOptions;
};

type LocalSamplesRef = NonNullable<
    NonNullable<Wallboard_bars$data["localState"]>["samples"]
>[number];

type UserJourneyGroupProps = UserJourneyComponentProps & {
    groupID: number;
    groupName: string;
    defaultHidden: boolean;
    userJourneys: UserJourneys;
    localSamplesRefs: LocalSamplesRef[];
};

function UserJourneyGroup({
    groupID,
    groupName,
    defaultHidden,
    userJourneys,
    localSamplesRefs,
    endTime,
    timeSpanHours,
    displayOptions,
}: UserJourneyGroupProps) {
    const cookieName = `showHide-${groupID}`;
    const [cookies, setCookie] = useCookies([cookieName]);

    const shown = cookies[cookieName]
        ? cookies[cookieName] === "show"
        : !defaultHidden;

    const toggleShown = useCallback(
        () =>
            setCookie(cookieName, shown ? "hide" : "show", {
                expires: DateTime.now().plus({ year: 1 }).toJSDate(),
                secure: true,
            }),
        [cookieName, setCookie, shown]
    );

    return (
        <section className={styles.userJourneyGroup}>
            <div className={styles.groupName}>
                <TextWithTooltip name={"UJ Groups"} label={groupName} />
                <Button
                    onClick={toggleShown}
                    className={styles.showHideButton}
                    variant={"link"}
                >
                    {shown ? "Hide" : "Show"}
                </Button>
            </div>
            {shown &&
                userJourneys.map((uj, index) => (
                    <UserJourneyRow
                        queryRef={uj}
                        localSamplesRef={localSamplesRefs[index]}
                        key={uj.ujID}
                        endTime={endTime}
                        timeSpanHours={timeSpanHours}
                        displayOptions={displayOptions}
                    />
                ))}
        </section>
    );
}

//<editor-fold desc="Ticks">
function calculateTicks(
    endTime: DateTime,
    timeSpanHours: number,
    numIntervals: number
) {
    const startTime = endTime.minus({ hour: timeSpanHours });
    const intervalHours = timeSpanHours / numIntervals;

    const ticks = [];
    let currentTick = startTime;
    while (currentTick <= endTime) {
        ticks.push(currentTick.toSeconds());
        currentTick = currentTick.plus({ hour: intervalHours });
    }

    return ticks;
}

type TicksProps = {
    timeSpanHours: WallboardTimeSpanHours;
    endTime: DateTime;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

function Ticks({ timeSpanHours, endTime, className }: TicksProps) {
    const numIntervals = 6;
    const [ticks, setTicks] = useState<number[]>([]);
    const classNames = className ? [className] : [];
    classNames.push(styles.ticks);

    useEffect(() => {
        setTicks(calculateTicks(endTime, timeSpanHours, numIntervals));
    }, [endTime, timeSpanHours]);

    const tickElements = ticks.map((tick) => {
        return (
            <div key={tick}>
                <div>
                    <RecentDateTime
                        timestamp={tick}
                        timeSpanHours={timeSpanHours}
                        showSeconds={false}
                    />
                </div>
            </div>
        );
    });

    return <div className={classNames.join(" ")}>{tickElements}</div>;
}
//</editor-fold>

//<editor-fold desc="Settings bar">
function SettingsBar({ queryRef }: { queryRef: ToggleMenu$key }) {
    return (
        <div className={styles.settingsBar}>
            <SymmetricBar
                left={<Legend />}
                middle={<TimeSpanHoursSelector />}
                right={
                    <Suspense>
                        <ToggleMenu queryRef={queryRef} showBackup={false} />
                    </Suspense>
                }
            />
        </div>
    );
}

function Legend() {
    return (
        <div className={styles.legend}>
            <div>
                <span className={`${styles.colourBlock} OK`} />
                OK
            </div>
            <div>
                <span className={`${styles.colourBlock} WARNING`} />
                Warning
            </div>
            <div>
                <span className={`${styles.colourBlock} SLOW`} />
                Cutoff
            </div>
            <div>
                <span className={`${styles.colourBlock} ERROR`} />
                Error
            </div>
            <div>
                <span className={`${styles.colourBlock} PME`} />
                PME
            </div>
        </div>
    );
}

function isHoursValue(value: number): value is WallboardTimeSpanHours {
    return wallboardTimeSpanHoursValues.includes(
        value as WallboardTimeSpanHours
    );
}

function useTimeSpanHours(): [
    WallboardTimeSpanHours,
    (value: WallboardTimeSpanHours) => void
] {
    const [integerValue, setIntegerValue] = useURLIntegerParam("hours", false);
    const selection = integerValue ?? 24;

    if (!isHoursValue(selection)) {
        throw new InvalidURLParameter("");
    }

    return [selection, setIntegerValue];
}

function TimeSpanHoursSelector() {
    const [timeSpanHours, setTimeSpanHours] = useTimeSpanHours();
    const [longestTimeSpanHours, setLongestTimeSpanHours] =
        useState(timeSpanHours);
    const environment = useRelayEnvironment();

    function handleClick(value: WallboardTimeSpanHours) {
        setTimeSpanHours(value);
        if (value > longestTimeSpanHours) {
            setLongestTimeSpanHours(value);
            clearRecentSamples(environment);
        }
    }

    const options = wallboardTimeSpanHoursValues.map((value) => {
        const active = value === timeSpanHours;

        return (
            <Button
                onClick={() => handleClick(value)}
                active={active}
                aria-current={active}
                variant="tribe-primary"
                key={value}
            >
                {value} Hours
            </Button>
        );
    });

    return (
        <ButtonGroup aria-label={"Select time span to display"}>
            {options}
        </ButtonGroup>
    );
}
//</editor-fold>

//<editor-fold desc="User journey rows">
type Sample = NonNullable<Wallboard_userJourneySamples$data["recent"]>[number];

type UserJourneyRowProps = UserJourneyComponentProps & {
    queryRef: Wallboard_userJourneyRow$key;
    localSamplesRef: LocalSamplesRef;
};
function UserJourneyRow({
    queryRef,
    localSamplesRef,
    endTime,
    timeSpanHours,
    displayOptions,
}: UserJourneyRowProps) {
    const userJourney = useFragment(
        graphql`
            fragment Wallboard_userJourneyRow on UserJourney {
                ujID
                name
                platformType
            }
        `,
        queryRef
    );
    const localSamples = useFragment<Wallboard_userJourneySamples$key>(
        graphql`
            fragment Wallboard_userJourneySamples on LocalSamples {
                recent {
                    timestamp
                    status
                    allThirdPartyNoCustomerImpact
                    extraWarningsAllThirdPartyNoCustomerImpact
                    inPME
                    pageDeliveryTotal
                }
            }
        `,
        localSamplesRef
    );

    const [latestSample, setLatestSample] = useState<Sample | undefined>();
    const [flashStatus, setFlashStatus] = useState<string | null>(null);
    const [setFlashTimeout] = useTimeout();

    const recentSamples = localSamples?.recent ?? [];
    const newLatestSample = recentSamples[recentSamples.length - 1];
    if (
        newLatestSample &&
        // Flash when `latestSample` is undefined, i.e. for the first sample. So we flash on page
        // load and when a new UJ receives its first sample.
        latestSample?.timestamp !== newLatestSample.timestamp
    ) {
        const newEffectiveStatus = getEffectiveStatus(
            newLatestSample,
            displayOptions
        );
        if (newEffectiveStatus === flashStatus) {
            // We're still flashing a previous sample of the same status. Cancel that animation, so
            // we start a new flash on the next render.
            setFlashStatus(null);
        } else {
            setLatestSample(newLatestSample);
            setFlashStatus(newEffectiveStatus);
            setFlashTimeout(() => {
                setFlashStatus(null);
                (window as any).wallboardLoaded = true; // For screenshots
            }, 12000);
        }
    }

    const ujLinkParams = useOldPortalURLSearchParams(
        new URLSearchParams({
            uj: userJourney.ujID.toString(),
        })
    );
    const ujLink = `/portal2/userJourney/home?${ujLinkParams.toString()}`;

    const classNames = [styles.userJourneyRow];
    if (flashStatus) {
        // It would be nicer to specify `.flash` and (for example) `.ERROR` separately. But then
        // mysteriously the colour change is not animated.
        classNames.push(styles[`flash${flashStatus}`]);
    }

    return (
        <div
            className={classNames.join(" ")}
            data-hasdata={recentSamples.length > 0}
        >
            <a href={ujLink}>
                <UJPlatformIcon type={userJourney.platformType} />
                {userJourney.name}
            </a>
            <UserJourneySamples
                ujID={userJourney.ujID}
                recentSamples={recentSamples}
                endTime={endTime}
                timeSpanHours={timeSpanHours}
                displayOptions={displayOptions}
            />
            <LatestSampleLink
                latestSample={newLatestSample}
                ujID={userJourney.ujID}
                displayOptions={displayOptions}
            />
        </div>
    );
}

type UserJourneySamplesProps = UserJourneyComponentProps & {
    ujID: number;
    recentSamples: NonNullable<Wallboard_userJourneySamples$data["recent"]>;
};

function UserJourneySamples({
    ujID,
    recentSamples,
    endTime,
    timeSpanHours,
    displayOptions,
}: UserJourneySamplesProps) {
    // Get the width of the bar's container, so we can pass it to the bar.
    const ref = useRef(null);
    const { width } = useDimensions(ref);

    if (recentSamples.length === 0 || width === undefined) {
        // No samples yet for this UJ, or the container doesn't have dimensions yet. We can't plot
        // bar until we know its width in pixels.
        return (
            <div className={styles.bar} data-testid={"bar"} ref={ref}>
                <span></span>
            </div>
        );
    }

    const startTime = endTime.minus({ hour: timeSpanHours });

    // Params related to not yet supported features.
    const maxGap = null; // only set for helpers

    return (
        <div className={styles.bar} data-testid={"bar"} ref={ref}>
            <StatusBar
                ujID={ujID}
                start={startTime.toSeconds()}
                end={endTime.toSeconds()}
                samples={recentSamples}
                maxGap={maxGap}
                displayOptions={displayOptions}
                width={width}
            />
        </div>
    );
}

type LatestSampleLinkParams = {
    latestSample: Sample | undefined;
    ujID: number;
    displayOptions: SampleDisplayOptions;
};
function LatestSampleLink(props: LatestSampleLinkParams) {
    if (!props.latestSample) {
        return (
            <div>
                <span></span>
            </div>
        );
    }

    // Apparently a correct way to avoid rounding errors.
    const deliveryTimeRounded =
        Math.round(
            (props.latestSample.pageDeliveryTotal + Number.EPSILON) * 10
        ) / 10;
    return (
        <SampleLink ujID={props.ujID} timestamp={props.latestSample.timestamp}>
            <span
                className={`${getEffectiveStatus(
                    props.latestSample,
                    props.displayOptions
                )}`}
            >
                {deliveryTimeRounded.toFixed(1)}
                {"s"}
            </span>
        </SampleLink>
    );
}
//</editor-fold>
