import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import ReactGA from "react-ga4";
import { GoogleAnalyticsInitialiser_data$key } from "./__generated__/GoogleAnalyticsInitialiser_data.graphql";

export function GoogleAnalyticsInitialiser({
    queryRef,
}: {
    queryRef: GoogleAnalyticsInitialiser_data$key;
}) {
    const { currentUser } = useFragment<GoogleAnalyticsInitialiser_data$key>(
        graphql`
            fragment GoogleAnalyticsInitialiser_data on Query {
                currentUser {
                    isAdmin
                    impersonator
                    customer {
                        name
                    }
                }
            }
        `,
        queryRef
    );

    const userType = currentUser.isAdmin
        ? "admin"
        : currentUser.impersonator
        ? "impersonator"
        : "client";
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TAG!, {
        gaOptions: {
            cookie_flags: "SameSite=None;Secure",
            custom_map: {
                dimension1: "customer_name",
                dimension2: "user_type",
            },
            customer_name: currentUser.customer.name,
            user_type: userType,
        },
    });

    return <></>;
}
