import { graphql } from "babel-plugin-relay/macro";
import React, { ReactNode, Suspense, useRef } from "react";
import {
    loadQuery,
    PreloadedQuery,
    useFragment,
    usePreloadedQuery,
} from "react-relay";
import {
    createBrowserRouter,
    Outlet,
    redirect,
    RouteObject,
    RouterProvider,
    useLoaderData,
    useRouteError,
} from "react-router-dom";
import { App_user$key } from "./__generated__/App_user.graphql";
import { AppQuery } from "./__generated__/AppQuery.graphql";
import { PageErrorBoundary } from "./components/ErrorBoundary";
import { useTimezone } from "./components/GlobalContext";
import { Header } from "./components/Header";
import { IntercomBooter } from "./components/IntercomBooter";
import { RouteNotFoundErrorPage, SelectUserPage } from "./pages/ErrorPage";
import { UserJourney, userJourneyQuery } from "./pages/UserJourney";
import { privateRouteQuery, PrivateRoute } from "./components/PrivateRoute";
import { Wallboard, wallboardQuery } from "./pages/wallboard/Wallboard";
import { GoogleAnalyticsInitialiser } from "./components/GoogleAnalyticsInitialiser";
import relayModernEnvironment from "./RelayEnvironment";
import { IEnvironment } from "relay-runtime";
import { DateTime } from "luxon";
import styles from "./App.module.scss";

const appQuery = graphql`
    query AppQuery {
        ...Header_header
        ...IntercomBooter_data
        ...App_user
        ...GoogleAnalyticsInitialiser_data
    }
`;

function Footer() {
    const year = DateTime.now().year;
    return (
        <footer className={styles.footer}>
            &copy; ThinkTRIBE Ltd {year} - Web Application Testing - Tel 01227
            768276 -
            <a href="mailto:support@thinktribe.com" target="_self">
                E-Mail Tribe
            </a>
        </footer>
    );
}

function Layout() {
    const data = usePreloadedQuery(
        appQuery,
        useLoaderData() as PreloadedQuery<AppQuery>
    );
    return (
        <>
            <UserContext queryRef={data}>
                <Header queryRef={data} />
                <Suspense>
                    <IntercomBooter queryRef={data} />
                </Suspense>
                <Suspense>
                    <GoogleAnalyticsInitialiser queryRef={data} />
                </Suspense>
                <PageErrorBoundary>
                    <div className={styles.content}>
                        <Outlet />
                    </div>
                </PageErrorBoundary>
            </UserContext>
            <Footer />
        </>
    );
}

// Exported for testing
export function UserContext(props: {
    children: ReactNode;
    queryRef: App_user$key;
}) {
    const user = useFragment<App_user$key>(
        graphql`
            fragment App_user on Query {
                currentUser {
                    id
                }
            }
        `,
        props.queryRef
    );
    const userRef = useRef<string>(user.currentUser.id);
    const userChanged = userRef.current !== user.currentUser.id;

    if (userChanged) {
        console.log(`User changed to ${user.currentUser.id}`);
        // This effectively clears the cache. If we decide to do something smarter, we may want to update the userRef.
        window.location.reload();
    }

    // Don't render children if user changed to avoid errors.
    return <>{!userChanged && props.children}</>;
}

export function Rethrow(): ReactNode {
    throw useRouteError();
}

export function getRoutes(environment: IEnvironment): RouteObject[] {
    return [
        {
            path: "/",
            element: <Layout />,
            loader: async () => {
                return Promise.resolve(loadQuery(environment, appQuery, {}));
            },
            errorElement: <Rethrow />,
            children: [
                {
                    path: "userJourney/graphs",
                    element: <UserJourney />,
                    loader: async () => {
                        return Promise.resolve(
                            loadQuery(environment, userJourneyQuery, {})
                        );
                    },
                },
                {
                    path: "wallboard",
                    element: <Wallboard />,
                    loader: async ({ request }) => {
                        const url = new URL(request.url);
                        const backup = url.searchParams.get("backup");
                        if (backup === "true") {
                            url.searchParams.delete("backup");
                            return redirect(url.toString());
                        } else {
                            return Promise.resolve(
                                loadQuery(environment, wallboardQuery, {
                                    backup: false,
                                })
                            );
                        }
                    },
                },
                {
                    path: "powerUser/selectUser",
                    element: (
                        <PrivateRoute expectedAllowPermission="impersonate-user">
                            <SelectUserPage />
                        </PrivateRoute>
                    ),
                    loader: async () => {
                        return Promise.resolve(
                            loadQuery(environment, privateRouteQuery, {})
                        );
                    },
                },
                {
                    path: "*",
                    element: <RouteNotFoundErrorPage />,
                },
            ],
        },
    ];
}

const router = createBrowserRouter(getRoutes(relayModernEnvironment));

export function App() {
    // Make the app re-render if the timezone changes.
    useTimezone();
    return <RouterProvider router={router} />;
}
