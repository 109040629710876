// Taken from https://stackoverflow.com/a/75101934
import { RefObject, useMemo, useSyncExternalStore } from "react";

function subscribe(callback: (e: Event) => void) {
    window.addEventListener("resize", callback);
    return () => {
        window.removeEventListener("resize", callback);
    };
}

export function useDimensions(ref: RefObject<HTMLElement>) {
    const dimensions = useSyncExternalStore(subscribe, () => {
        if (ref.current === null)
            return JSON.stringify({ width: undefined, height: undefined });

        // Computed style includes width and height, but it may or may not include padding and border depending on
        // the value of box-sizing. So, it's better to use clientWidth and clientHeight, which always include padding.
        const computedStyle = window.getComputedStyle(ref.current);
        return JSON.stringify({
            width:
                ref.current.clientWidth -
                parseFloat(computedStyle.paddingLeft) -
                parseFloat(computedStyle.paddingRight),
            height:
                ref.current.clientHeight -
                parseFloat(computedStyle.paddingTop) -
                parseFloat(computedStyle.paddingBottom),
        });
    });
    return useMemo(() => JSON.parse(dimensions), [dimensions]);
}
