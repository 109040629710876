import { CHART_CONTAINER_ID, CHART_HEIGHT } from "./amChartsUtils";

export function UJGraph() {
    return (
        <>
            <div
                id={CHART_CONTAINER_ID}
                style={{ width: "100%", height: `${CHART_HEIGHT}px` }}
            ></div>
        </>
    );
}
