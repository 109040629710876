/**
 * @generated SignedSource<<db03a801b4616f02511f6c58bb8d5f9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Header_SelectedUJCustomerName$data = ReadonlyArray<{
  readonly customer: {
    readonly name: string;
  };
  readonly ujID: number;
  readonly " $fragmentType": "Header_SelectedUJCustomerName";
}>;
export type Header_SelectedUJCustomerName$key = ReadonlyArray<{
  readonly " $data"?: Header_SelectedUJCustomerName$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_SelectedUJCustomerName">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Header_SelectedUJCustomerName",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ujID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserJourney",
  "abstractKey": null
};

(node as any).hash = "cb54d9313be9c13ae6f6e354b95ef9e5";

export default node;
