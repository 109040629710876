import { createClient } from "graphql-ws";
import {
    commitLocalUpdate,
    Environment,
    FetchFunction,
    IEnvironment,
    Network,
    Observable,
    RecordProxy,
    RecordSource,
    RecordSourceProxy,
    RequestParameters,
    Store,
    SubscribeFunction,
    Variables,
} from "relay-runtime";
import { getLocalState } from "./lib/localState";

type Queries = {
    readonly totalActiveQueries: number;
};

export function getQueries(
    store: RecordSourceProxy,
    localState: RecordProxy
): RecordProxy<Queries> {
    let queries = localState.getLinkedRecord("queries");

    if (!queries) {
        const queries = store.create(`Queries`, "Queries");
        queries.setValue(0, "totalActiveQueries");
        localState.setLinkedRecord(queries, "queries");
    }

    return localState.getLinkedRecord("queries");
}

interface ChangeFunction {
    (value: number): number;
}

function changeQueryCount(
    environment: IEnvironment,
    changeFunction: ChangeFunction
) {
    commitLocalUpdate(environment, (store) => {
        const localState = getLocalState(store);
        const queries = getQueries(store, localState);

        queries.setValue(
            changeFunction(queries.getValue("totalActiveQueries")),
            "totalActiveQueries"
        );

        localState.setLinkedRecord(queries, "queries");
    });
}

export function decrementQueryCount(environment: IEnvironment) {
    changeQueryCount(environment, function (value: number) {
        return value - 1;
    });
}

export function incrementQueryCount(environment: IEnvironment) {
    changeQueryCount(environment, function decr(value: number) {
        return value + 1;
    });
}

export const fetchOverHTTP: FetchFunction = async (request, variables) => {
    console.log(
        `fetching query ${request.name} with ${JSON.stringify(variables)}`
    );
    incrementQueryCount(relayModernEnvironment);
    const response = await fetch("/api/private", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            query: request.text,
            variables,
        }),
    });

    // Clone the response in case we need to read it again in order to report invalid syntax.
    // (What an odd API...)
    const responseClone = response.clone();
    try {
        const result = await response.json();
        console.log(
            `fetched query ${request.name} with ${JSON.stringify(variables)}`
        );
        return result;
    } catch (e) {
        let headers: string[] = [];
        responseClone.headers.forEach((value, name) =>
            headers.push("    " + name + ": " + value)
        );
        throw new Error(
            "Invalid API response." +
                "\n  Status: " +
                responseClone.status +
                " " +
                responseClone.statusText +
                "\n  Headers:\n" +
                headers.join("\n") +
                "\n  Content: " +
                (await responseClone.text())
        );
    } finally {
        decrementQueryCount(relayModernEnvironment);
    }
};

export const subscriptionsClient = createClient({
    url: `wss://${window.location.host}/api/private/subscribe`,
    // Don't retry here because we can't change the variables. If the connection is closed the
    // `onError` handler of the subscription is called.
    retryAttempts: 0,
});

const subscribeOverWebsocket: SubscribeFunction = (
    request: RequestParameters,
    variables: Variables
) => {
    console.log(
        `subscribing to ${request.name} with ${JSON.stringify(variables)}`
    );
    return Observable.create((sink) => {
        return subscriptionsClient.subscribe(
            {
                operationName: request.name,
                query: request.text!,
                variables,
            },
            // The sink may be passed `data: null`, which it accepts, but only if `extensions`
            // is the only other field. We could persuade TS to understand that, but it's not
            // worth it.
            // @ts-ignore
            sink
        );
    });
};

const relayModernEnvironment = new Environment({
    network: Network.create(fetchOverHTTP, subscribeOverWebsocket),
    store: new Store(new RecordSource()),
});

// Export a singleton instance of Relay Environment configured with our network functions:
export default relayModernEnvironment;
