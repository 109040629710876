import parseInt from "lodash/parseInt";
import { DateTime } from "luxon";
import React, { ComponentType, useTransition } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { formatDateInUTC, parse, TimeRange } from "./dateUtils";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { urlParams_thirdPartyToggleDefaults$key } from "./__generated__/urlParams_thirdPartyToggleDefaults.graphql";
import { DropdownItemProps } from "react-bootstrap/DropdownItem";
import { Dropdown } from "react-bootstrap";
import { RequiredFields } from "../helpers";

export class InvalidURLParameter extends Error {
    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly, because `Error` is weird.
        Object.setPrototypeOf(this, InvalidURLParameter.prototype);
    }
}

class MissingURLParameter extends InvalidURLParameter {
    constructor(parameterName: string) {
        super(`${parameterName} is missing`);
    }
}

export class InvalidURLParameterValue extends InvalidURLParameter {
    constructor(parameterName: string, value: any, allowedValues?: any[]) {
        let message = `Invalid ${parameterName} value: '${value}'`;

        if (allowedValues) {
            message += `, allowed values: ${allowedValues}`;
        }

        super(message);
    }
}

// Helper functions, not react hooks, so they can be run conditionally.

function getURLParam(paramName: string, searchParams: URLSearchParams) {
    const paramValue = searchParams.get(paramName);
    if (paramValue === null) {
        throw new MissingURLParameter(paramName);
    }
    return paramValue;
}

function getDateURLParam(paramName: string, searchParams: URLSearchParams) {
    const paramValue = getURLParam(paramName, searchParams);
    const dateValue = parse(paramValue);
    if (dateValue.isValid) {
        return dateValue;
    } else {
        throw new InvalidURLParameterValue(paramName, paramValue);
    }
}

interface URLParamSetter {
    (value: string): void;
}

function useURLParam<T extends boolean>(
    name: string,
    strict: T
): [T extends true ? string : string | null, URLParamSetter];
function useURLParam(
    name: string,
    strict: boolean
): [string | null, URLParamSetter] {
    const [searchParams, setSearchParams] = useSearchParams();

    function setValue(value: string) {
        searchParams.set(name, value);
        setSearchParams(searchParams);
    }

    const value = searchParams.get(name);
    if (strict && value === null) {
        throw new MissingURLParameter(name);
    }
    return [value, setValue];
}

export function useURLIntegerParam(
    paramName: string,
    strict: boolean
): [number | null, (value: number) => void] {
    const [value, setValue] = useURLParam(paramName, strict);

    function setIntegerValue(v: number) {
        setValue(v.toString());
    }

    if (value === null) {
        return [null, setIntegerValue];
    } else {
        const integerValue = parseInt(value);
        if (isNaN(integerValue)) {
            throw new InvalidURLParameterValue(paramName, value);
        }
        return [integerValue, setIntegerValue];
    }
}

export function useURLBooleanParam(
    name: string,
    defaultValue: boolean
): [boolean, (value: boolean) => void] {
    const [value, setValue] = useURLParam(name, false);

    function getValue(): boolean {
        switch (value) {
            case null:
                return defaultValue;
            case "false":
                return false;
            case "true":
                return true;
            default:
                throw new InvalidURLParameterValue(name, value);
        }
    }

    function setBooleanValue(value: boolean) {
        setValue(value ? "true" : "false");
    }

    return [getValue(), setBooleanValue];
}

export interface SetURLTimeRange {
    (timerange: TimeRange): void;
}

export function useURLTimerange(): [TimeRange | null, SetURLTimeRange] {
    const [searchParams, setSearchParams] = useSearchParams();

    const setValue = (timeRange: TimeRange) => {
        // setSearchParams requires us to set them all
        // So just update the one's we need to update
        searchParams.set("start", formatDateInUTC(timeRange.start));
        searchParams.set("end", formatDateInUTC(timeRange.end));

        setSearchParams(searchParams);
    };

    const setURLTimeRange = useSetterWithTransition(
        "timeRange",
        setValue,
        false
    );

    try {
        return [
            {
                start: getDateURLParam("start", searchParams),
                end: getDateURLParam("end", searchParams),
            },
            setURLTimeRange,
        ];
    } catch (error) {
        if (error instanceof InvalidURLParameter) {
            if (error.message.includes("missing")) {
                // Use default timerange
                return [null, setURLTimeRange];
            }
        }
        throw error;
    }
}

export function useStrictURLTimerange(): [TimeRange, SetURLTimeRange] {
    const [urlTimeRange, setURLTimeRange] = useURLTimerange();
    if (urlTimeRange === null) {
        throw Error("Unexpected null url timerange");
    }
    return [urlTimeRange, setURLTimeRange];
}

export function usePMEToggle() {
    return useURLBooleanParam("pme", true);
}

function useSetterWithTransition<T>(
    paramName: keyof URLParameters,
    setValue: (value: T) => void,
    changeValueInTransition: boolean
): (value: T) => void {
    const [, startTransition] = useURLParamTransition(
        paramName,
        setValue,
        changeValueInTransition
    );
    return (v: T) => {
        startTransition(v);
    };
}

export type URLParameters = {
    ujID: number;
    timeRange: TimeRange;
    backup: boolean;
    graphType: GraphType;
};
type TransitionHook = (changedParams: Partial<URLParameters>) => void;
export const transitionHooks: { [key: string]: TransitionHook } = {};

function useURLParamTransition<T>(
    paramName: keyof URLParameters,
    setValue: (value: T) => void,
    changeValueInTransition: boolean
): [boolean, (value: T) => void] {
    const [isPending, startTransition] = useTransition();
    return [
        isPending,
        (v: T) => {
            if (!changeValueInTransition) {
                // Don't update the url in a transition, so we get the update quicker.
                setValue(v);
            }
            startTransition(() => {
                if (changeValueInTransition) {
                    setValue(v);
                }
                Object.values(transitionHooks).forEach((hook) =>
                    hook({ [paramName]: v })
                );
            });
        },
    ];
}

export function useBackupToggle(): [boolean, (value: boolean) => void] {
    const [backup, setBackup] = useURLBooleanParam("backup", false);
    return [backup, useSetterWithTransition("backup", setBackup, true)];
}

interface UJIDSetter {
    (value: number): void;
}

// Only function overloads can be seen from the outside, so we must specify the optional
// strict false signature.
export function useURLSelectedUJID(strict?: false): [number | null, UJIDSetter];
export function useURLSelectedUJID(strict: true): [number, UJIDSetter];
export function useURLSelectedUJID(
    strict: boolean = false
): [number | null, UJIDSetter] {
    const [ujID, setUJID] = useURLIntegerParam("uj", strict);
    return [ujID, useSetterWithTransition("ujID", setUJID, true)];
}

export const graphTypes = ["userJourney", "step", "stepStacked"] as const;
export type GraphType = typeof graphTypes[number];

export function useGraphType(): [GraphType, (value: GraphType) => void] {
    let [graphType, setGraphType] = useURLParam("graphType", false);
    if (graphType === null) {
        graphType = "userJourney";
    }

    function isGraphType(gt: string): gt is GraphType {
        return (graphTypes as readonly string[]).includes(gt);
    }

    if (isGraphType(graphType)) {
        return [
            graphType,
            useSetterWithTransition("graphType", setGraphType, true),
        ];
    } else {
        throw new InvalidURLParameter(`Invalid graphType '${graphType}'`);
    }
}

export function useOldPortalURLSearchParams(
    searchParams: URLSearchParams
): URLSearchParams {
    const [timeRange] = useURLTimerange();
    const [isPMEOn] = usePMEToggle();

    searchParams.set("slaOn", isPMEOn ? "True" : "False");
    searchParams.delete("pme");

    if (timeRange) {
        searchParams.set("startTime", formatDateInUTC(timeRange.start));
        searchParams.set("endTime", formatDateInUTC(timeRange.end));
    }
    searchParams.delete("start");
    searchParams.delete("end");

    searchParams.delete("graphType");

    // Sort params for consistent ordering.
    searchParams.sort();

    return searchParams;
}

/**
 * Adds old-portal-compatible parameters to the given URL. It replaces existing
 * parameters with the same names.
 * @param url
 */
export function useOldPortalURLParams(url: string): string {
    const [path, searchString] = url.split("?");
    const searchParams = useOldPortalURLSearchParams(
        new URLSearchParams(searchString)
    );
    return `${path}?${searchParams.toString()}`;
}

export function useSelectedUJ<T extends { ujID: number }>(
    userJourneys: readonly T[]
): T | undefined {
    const [urlSelectedUJID] = useURLSelectedUJID();
    return (userJourneys.filter((uj) => uj.ujID === urlSelectedUJID) || [
        undefined,
    ])[0];
}

/**
 * Ensure that time range parameters are present for the given component.
 */
export function withTimeRange<T extends React.JSX.IntrinsicAttributes>(
    Component: ComponentType<T>
) {
    return (props: T) => {
        const [searchParams] = useSearchParams();
        const [urlTimeRange] = useURLTimerange();

        if (urlTimeRange === null) {
            const now = DateTime.now();
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(
                "start",
                formatDateInUTC(now.minus({ day: 1 }))
            );
            newSearchParams.set("end", formatDateInUTC(now));
            return <Navigate to={"?" + newSearchParams.toString()} />;
        } else {
            return <Component {...props} />;
        }
    };
}

export function replaceLocation(url: string) {
    window.location.replace(url);
}

export function useThirdPartyToggles(
    queryRef: urlParams_thirdPartyToggleDefaults$key
): [boolean, boolean, (v: boolean) => void, (v: boolean) => void] {
    const { currentUser } = useFragment(
        graphql`
            fragment urlParams_thirdPartyToggleDefaults on Query {
                currentUser {
                    customer {
                        showThirdPartyWarnings
                        showThirdPartyErrors
                    }
                }
            }
        `,
        queryRef
    );

    const [showThirdPartyErrors, setShowThirdPartyErrors] = useURLBooleanParam(
        "thirdPartyErrors",
        currentUser.customer.showThirdPartyErrors
    );
    const [showThirdPartyWarnings, setShowThirdPartyWarnings] =
        useURLBooleanParam(
            "thirdPartyWarnings",
            currentUser.customer.showThirdPartyWarnings
        );
    return [
        showThirdPartyErrors,
        showThirdPartyWarnings,
        setShowThirdPartyErrors,
        setShowThirdPartyWarnings,
    ];
}

function withOldPortalURLDropdownItem() {
    return (props: RequiredFields<DropdownItemProps, "href">) => {
        const href = useOldPortalURLParams(props.href);
        const newProps = { ...props, href };

        return <Dropdown.Item {...newProps} />;
    };
}

export const OldPortalDropdownItem = withOldPortalURLDropdownItem();
