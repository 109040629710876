import { graphql } from "babel-plugin-relay/macro";
import React, { startTransition, Suspense, useEffect, useState } from "react";
import {
    PreloadedQuery,
    useLazyLoadQuery,
    usePreloadedQuery,
    useQueryLoader,
} from "react-relay";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { NotificationType } from "../components/__generated__/NotificationsQuery.graphql";
import { TimeRangeDisplay } from "../components/TimeRange";
import { UJPanel } from "../components/UJPanel";
import {
    formatDateInUTC,
    getTimestamp,
    isMoreThanTwoWeeks,
    TimeRange,
    timeRangeToString,
} from "../lib/dateUtils";
import { useNotifications } from "../lib/notifications";
import { useTitle } from "../lib/title";
import {
    replaceLocation,
    transitionHooks,
    URLParameters,
    useOldPortalURLParams,
    useStrictURLTimerange,
    useURLSelectedUJID,
    withTimeRange,
} from "../lib/urlParams";
import UserJourney_AnalyticsQueryGraphql, {
    AnalyticsErrorCode,
    AnalyticsPartialErrorCode,
    UserJourney_AnalyticsQuery,
} from "./__generated__/UserJourney_AnalyticsQuery.graphql";
import { UserJourney_UJsQuery } from "./__generated__/UserJourney_UJsQuery.graphql";
import { useIsBackButtonNavigation } from "../lib/backButtonNav";
import { Analytics, ANALYTICS_PROVIDER_NAMES } from "../lib/analytics";
import { SymmetricBar } from "../components/utils";
import { ToggleMenu } from "../components/ToggleMenu";
import { UserJourneyQuery } from "./__generated__/UserJourneyQuery.graphql";

export const userJourneyQuery = graphql`
    query UserJourneyQuery {
        ...ToggleMenu
    }
`;

type AnalyticsNotificationMetaData = {
    code: AnalyticsErrorCode | AnalyticsPartialErrorCode;
    timestamp: number;
};

/**
 * A component which, when rendered, redirects to a given URL with old portal
 * parameters added.
 */
function RedirectToOldPortal(props: { url: string }) {
    const urlWithOldPortalParams = useOldPortalURLParams(props.url);

    useEffect(() => {
        replaceLocation(urlWithOldPortalParams);
    });

    return null;
}

function _UserJourney() {
    // This query is a subset of the "header" query, so we can just get it from the store. We don't
    // want it to suspend because we only use it to set the page title.
    const apiResponse = useLazyLoadQuery<UserJourney_UJsQuery>(
        graphql`
            query UserJourney_UJsQuery {
                currentUser {
                    isAdmin
                }
                userJourneys {
                    ujID
                    name
                }
            }
        `,
        {},
        {
            fetchPolicy: "store-only",
        }
    );
    const [ujID] = useURLSelectedUJID(true);

    let title = "";
    // apiResponse.userJourneys is undefined until the header query returns
    if (apiResponse.userJourneys) {
        const uj = apiResponse.userJourneys.find((uj) => uj.ujID === ujID);
        if (uj) {
            title = uj.name;
            if (apiResponse.currentUser.isAdmin) {
                title = `${uj.ujID.toString()}: ${title}`;
            }
        }
    }

    useTitle(title);

    const data = usePreloadedQuery(
        userJourneyQuery,
        useLoaderData() as PreloadedQuery<UserJourneyQuery>
    );

    const [urlTimeRange] = useStrictURLTimerange();
    const [searchParams] = useSearchParams();

    const [analyticsQueryRef, loadAnalyticsQuery] =
        useQueryLoader<UserJourney_AnalyticsQuery>(
            UserJourney_AnalyticsQueryGraphql
        );
    const [analytics, setAnalytics] = useState<Analytics | null>(null);
    const urlTimeRangeString = timeRangeToString(urlTimeRange);

    const loadAnalytics = (ujID: number, timeRange: TimeRange) => {
        if (!isMoreThanTwoWeeks(timeRange)) {
            loadAnalyticsQuery({
                ujID,
                timeRange: {
                    start: formatDateInUTC(timeRange.start),
                    end: formatDateInUTC(timeRange.end),
                },
            });
        }
    };

    useEffect(() => {
        loadAnalytics(ujID, urlTimeRange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isBackButtonNavigation = useIsBackButtonNavigation();

    useEffect(() => {
        if (isBackButtonNavigation) {
            startTransition(() => {
                loadAnalytics(ujID, urlTimeRange);
            });
        }
    });

    useEffect(() => {
        transitionHooks.UserJourney_analytics = (
            changedParams: Partial<URLParameters>
        ) => {
            loadAnalytics(
                changedParams.ujID || ujID,
                changedParams.timeRange || urlTimeRange
            );
        };
        return () => {
            delete transitionHooks.UserJourney_analytics;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ujID, urlTimeRangeString]);

    const { createNotification } = useNotifications();

    const [
        lastAnalyticsNotificationMetaData,
        setLastAnalyticsNotificationMetaData,
    ] = useState<AnalyticsNotificationMetaData | null>(null);

    useEffect(() => {
        if (!analytics) {
            return;
        }

        const now = getTimestamp();
        const analyticsErrorCode = analytics.code ?? analytics.partialErrorCode;
        if (analyticsErrorCode !== null && analyticsErrorCode !== undefined) {
            const lastNotifiedForThisCodeToday =
                lastAnalyticsNotificationMetaData !== null &&
                lastAnalyticsNotificationMetaData.code === analyticsErrorCode &&
                lastAnalyticsNotificationMetaData.timestamp >
                    now - 24 * 60 * 60 * 1000;
            if (!lastNotifiedForThisCodeToday) {
                let notificationMessage: string;
                let notificationType: NotificationType = "ERROR";
                let includeLink: boolean = true;
                const providerName =
                    ANALYTICS_PROVIDER_NAMES[analytics.provider!];
                let link = {
                    url: "/portal2/setting/analytics/manage",
                    description: "Analytics Integration",
                };

                switch (analyticsErrorCode) {
                    case "CONFIG_NOT_FOUND":
                        switch (analytics.provider) {
                            case "GOOGLE_UA":
                                notificationMessage = `Profile ${
                                    analytics.config!.configID
                                } not found, perhaps your ${providerName} user cannot see it`;
                                break;
                            case "GOOGLE":
                                notificationMessage = `Property ${
                                    analytics.config!.configID
                                } not found, perhaps your ${providerName} user cannot see it`;
                                break;
                            default:
                                notificationMessage = `Report Suite ${
                                    analytics.config!.configID
                                } not found`;
                        }
                        break;
                    case "INSUFFICIENT_PERMISSIONS":
                        notificationMessage = `${providerName} user does not have permission to see profile ${
                            analytics.config!.configID
                        } ${analytics.config!.configName}`;
                        break;
                    case "NO_ACCESS":
                        notificationMessage = `Not authorised to see your ${providerName} data`;
                        break;
                    case "FOUND_UNCATEGORISED_DATA":
                        if (analytics.provider === "GOOGLE") {
                            notificationMessage = `Some of your ${providerName} data could not be displayed 
                                    because Google reported it in the "(other)" field. See the linked documentation.`;
                            link = {
                                url: "https://support.google.com/analytics/answer/13331684",
                                description: "GA4: About the (other) row",
                            };
                        } else {
                            notificationMessage = `Unexpected uncategorised data from ${providerName}.`;
                        }
                        break;
                    default:
                        notificationMessage = `Error fetching ${providerName} data`;
                        includeLink = false;
                }

                createNotification({
                    title: `Error loading ${providerName}`,
                    message: notificationMessage,
                    type: notificationType,
                    ttl: 60,
                    link: includeLink ? link : null,
                    isImportant: false,
                });
                setLastAnalyticsNotificationMetaData({
                    code: analyticsErrorCode,
                    timestamp: now,
                });
            }
        }
    }, [
        analytics,
        createNotification,
        lastAnalyticsNotificationMetaData,
        setLastAnalyticsNotificationMetaData,
    ]);

    if (isMoreThanTwoWeeks(urlTimeRange)) {
        const redirectURL = `/portal2/userJourney/graphs/trends?${searchParams.toString()}`;
        return <RedirectToOldPortal url={redirectURL} />;
    } else {
        return (
            <>
                <SymmetricBar
                    middle={<TimeRangeDisplay />}
                    right={
                        <Suspense>
                            <ToggleMenu queryRef={data} />
                        </Suspense>
                    }
                />
                <UJPanel analytics={analytics} />
                <Suspense>
                    {analyticsQueryRef && (
                        <AnalyticsData
                            setAnalytics={setAnalytics}
                            queryRef={analyticsQueryRef}
                        />
                    )}
                </Suspense>
            </>
        );
    }
}

export const UserJourney = withTimeRange(_UserJourney);

/**
 * Loads the analytics data and calls the setAnalytics callback which is really
 * calling a setState function in the parent component.
 *
 * We do this, because we expect that loading this data is sometimes slow, and we want to
 * render the main graph as soon as the main query has responded. We then add the analytics
 * series asynchronously once we have the data for it.
 */
function AnalyticsData(props: {
    setAnalytics: (value: Analytics) => void;
    queryRef: PreloadedQuery<UserJourney_AnalyticsQuery>;
}) {
    const response = usePreloadedQuery<UserJourney_AnalyticsQuery>(
        graphql`
            query UserJourney_AnalyticsQuery(
                $ujID: Int!
                $timeRange: LimitedTimeRangeInput!
            ) {
                analyticsVisitors(ujID: $ujID, timeRange: $timeRange) {
                    ... on AnalyticsVisitorsData {
                        provider
                        values {
                            dateTime
                            visitors
                        }
                        config {
                            configID
                            configName
                        }
                        partialErrorCode
                    }
                    ... on AnalyticsVisitorsError {
                        provider
                        code
                        config {
                            configID
                            configName
                        }
                    }
                }
            }
        `,
        props.queryRef
    );

    useEffect(() => {
        // avoid an infinite loop by not updating the analytics data if it hasn't changed.
        props.setAnalytics(response.analyticsVisitors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response.analyticsVisitors]);

    return <></>;
}
