import React, { Component, ErrorInfo, ReactNode } from "react";
import { Cookies, withCookies } from "react-cookie";
import { ReactRelayContext } from "react-relay";
import { RelayContext } from "relay-runtime/lib/store/RelayStoreTypes";
import { InvalidURLParameter } from "../lib/urlParams";
import {
    BadRequestErrorPage,
    ErrorPage,
    ForbiddenErrorPage,
} from "../pages/ErrorPage";
import { logError } from "../lib/logging";
import { isForbiddenError } from "../lib/auth";

interface Props {
    children: ReactNode;
    cookies: Cookies;
}

interface State {
    error?: Error;
}

function isBadRequest(error: Error) {
    return error instanceof InvalidURLParameter;
}

class _ErrorBoundary extends Component<Props, State> {
    public state: State = {};

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (
            error.message.includes(
                "Cannot query field 'currentUser' on type 'Query'."
            )
        ) {
            this.props.cookies.set(
                "loginRedirect",
                JSON.stringify({
                    url: window.location.toString(),
                    method: "GET",
                }),
                { path: "/" }
            );
            window.location.assign("/portal2/login");
        } else {
            if (!isBadRequest(error) && !isForbiddenError(error)) {
                logError((this.context as RelayContext).environment, {
                    subject: "Error caught by error boundary",
                    error,
                    errorInfo,
                });
            }
            this.setState({ error });
        }
    }

    public render() {
        if (this.state.error) {
            if (
                this.state.error.message.includes(
                    "Cannot query field 'currentUser' on type 'Query'."
                )
            ) {
                return null;
            }

            return <ErrorPage />;
        }

        return this.props.children;
    }
}
_ErrorBoundary.contextType = ReactRelayContext;

class _PageErrorBoundary extends _ErrorBoundary {
    public render() {
        if (this.state.error) {
            if (isBadRequest(this.state.error)) {
                return <BadRequestErrorPage />;
            } else if (isForbiddenError(this.state.error)) {
                return <ForbiddenErrorPage />;
            } else {
                return super.render();
            }
        }

        return this.props.children;
    }
}

const ErrorBoundary = withCookies(_ErrorBoundary);
const PageErrorBoundary = withCookies(_PageErrorBoundary);

export { ErrorBoundary, PageErrorBoundary };
