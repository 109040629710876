import { graphql } from "babel-plugin-relay/macro";
import React, {
    ChangeEvent,
    MouseEvent,
    useEffect,
    useRef,
    useState,
} from "react";
import { Dropdown, Form, NavItem } from "react-bootstrap";
import { useFragment } from "react-relay";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
    OldPortalDropdownItem,
    useSelectedUJ,
    useURLSelectedUJID,
} from "../../lib/urlParams";
import { UJPlatformIcon } from "../UJPlatformIcon";
import {
    UJMenu_UJs$data,
    UJMenu_UJs$key,
} from "./__generated__/UJMenu_UJs.graphql";
import { UJMenu_user$key } from "./__generated__/UJMenu_user.graphql";
import { DropdownToggle } from "./DropdownMenus";
import styles from "./UJMenu.module.scss";
import {
    groupByCustomerName,
    useUJGroupPreferencesForUJs,
} from "../../lib/userJourneys";
import { userJourneys_userPreferences$key } from "../../lib/__generated__/userJourneys_userPreferences.graphql";
import { hasPermission } from "../../lib/auth";

function UJLabel({
    uj,
    showUJID,
}: {
    uj: UJMenu_UJs$data[number];
    showUJID: boolean;
}) {
    return (
        <>
            <UJPlatformIcon type={uj.platformType} />
            {showUJID && (
                <span className={styles.highlightUJID}>{uj.ujID}: </span>
            )}
            {uj.name}
        </>
    );
}

function ToggleContent(props: {
    uj: UJMenu_UJs$data[number] | undefined;
    showUJID: boolean;
}) {
    if (props.uj !== undefined) {
        return <UJLabel uj={props.uj} showUJID={props.showUJID} />;
    } else {
        return <span>User Journey</span>;
    }
}

function UJItem(props: { uj: UJMenu_UJs$data[number]; showUJID: boolean }) {
    const [urlSelectedUJID, setURLSelectedUJID] = useURLSelectedUJID();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    searchParams.set("uj", props.uj.ujID.toString());
    const isOnUJPage = location.pathname.startsWith("/userJourney");
    const path = isOnUJPage ? location.pathname : "/portal2/userJourney/home";
    const url = path + "?" + searchParams.toString();

    if (isOnUJPage) {
        function handleClick(event: MouseEvent<HTMLAnchorElement>) {
            setURLSelectedUJID(props.uj.ujID);
            event.preventDefault();
        }
        return (
            <Dropdown.Item
                as={Link}
                to={url}
                key={props.uj.ujID}
                disabled={urlSelectedUJID === props.uj.ujID}
                onClick={handleClick}
            >
                <UJLabel uj={props.uj} showUJID={props.showUJID} />
            </Dropdown.Item>
        );
    } else {
        return (
            <OldPortalDropdownItem href={url} key={props.uj.ujID}>
                <UJLabel uj={props.uj} showUJID={props.showUJID} />
            </OldPortalDropdownItem>
        );
    }
}

function SortedUJItems(props: {
    userJourneys: UJMenu_UJs$data;
    showUJID: boolean;
}) {
    // fixme: Sorting by name is at the moment tied to displaying the UJ ID. This is because that's how we sorted them
    //  for admins, and only admins can see UJ IDs. This now makes a little sense, so we should either introduce
    //  a new argument or find a better place to sort UJs.
    const sortedUserJourneys = props.showUJID
        ? [...props.userJourneys].sort((a, b) => {
              if (a.name < b.name) {
                  return -1;
              } else if (a.name === b.name) {
                  return 0;
              } else {
                  return 1;
              }
          })
        : props.userJourneys;

    return (
        <>
            {sortedUserJourneys.map((uj) => {
                return (
                    <UJItem key={uj.ujID} uj={uj} showUJID={props.showUJID} />
                );
            })}
        </>
    );
}

function GroupedUJItems(props: {
    userJourneys: UJMenu_UJs$data;
    groupByCustomerName: boolean;
    userQueryRef: userJourneys_userPreferences$key;
    showUJID: boolean;
}) {
    const ujsGroupedByGroup = useUJGroupPreferencesForUJs(
        props.groupByCustomerName ? [] : props.userJourneys,
        props.userQueryRef
    );

    let groupedUJs: [string, UJMenu_UJs$data][];
    if (props.groupByCustomerName) {
        groupedUJs = Array.from(groupByCustomerName(props.userJourneys));
    } else {
        groupedUJs = Array.from(ujsGroupedByGroup).map(
            ([groupName, { ujs }]) => [groupName, ujs]
        );
    }
    const showGroupHeader = groupedUJs.length > 1;

    return (
        <>
            {groupedUJs.map(([groupName, groupJourneys]) => {
                return (
                    <React.Fragment key={groupName}>
                        {showGroupHeader && (
                            <Dropdown.Header>{groupName}</Dropdown.Header>
                        )}
                        <SortedUJItems
                            userJourneys={groupJourneys}
                            showUJID={props.showUJID}
                        ></SortedUJItems>
                    </React.Fragment>
                );
            })}
        </>
    );
}

function Filter(props: { filter: string; setFilter: (value: string) => void }) {
    const inputFilterRef = useRef<HTMLInputElement>(null);

    // Focus the filter input by default
    useEffect(() => {
        inputFilterRef.current?.focus();
    }, []);

    function handleFilter(event: ChangeEvent<HTMLInputElement>) {
        props.setFilter(event.target.value);
    }

    return (
        <Form.Control
            className={styles.filter}
            type="text"
            placeholder="Filter..."
            onChange={handleFilter}
            ref={inputFilterRef}
            value={props.filter}
        />
    );
}

export function UJMenu(props: {
    ujsQueryRef: UJMenu_UJs$key;
    userQueryRef: UJMenu_user$key;
}) {
    const userJourneys = useFragment(
        graphql`
            fragment UJMenu_UJs on UserJourney @relay(plural: true) {
                ujID
                name
                platformType
                customer {
                    name
                }
            }
        `,
        props.ujsQueryRef
    );
    const currentUser = useFragment<UJMenu_user$key>(
        graphql`
            fragment UJMenu_user on User {
                permissions {
                    allow
                    deny
                }
                ...userJourneys_userPreferences
            }
        `,
        props.userQueryRef
    );
    const showUJID = hasPermission(currentUser.permissions, "view-admin");
    const selectedUJ = useSelectedUJ(userJourneys);

    // UJ Filtering -----
    const [ujFilter, setUJFilter] = useState("");
    const ujFilterLowerCase = ujFilter.toLowerCase();
    function ujNameMatches(uj: { name: string }) {
        return uj.name.toLowerCase().includes(ujFilterLowerCase);
    }
    function ujIDMatches(uj: { ujID: number }) {
        return uj.ujID.toString().includes(ujFilterLowerCase);
    }
    function filteredUJs() {
        return userJourneys.filter((uj) => {
            return ujNameMatches(uj) || (showUJID && ujIDMatches(uj));
        });
    }

    // Changing state when the menu opens causes the component to re-render,
    // by passing it as the key to the Filter component, we cause the filter component to
    // re-render when the menu is opened. This means useEffect in the Filter component gets
    // triggered to focus the input element everytime the menu opens, not just the first time.
    const [menuOpen, setMenuOpen] = useState(false);
    function handleMenuToggle(show: boolean) {
        // This will trigger if the menu is opened/closed by any means, e.g. by click or by keyboard.
        setMenuOpen(show);
    }

    const toggleClass = selectedUJ ? "active" : "";

    return (
        <Dropdown
            aria-label="User Journey Menu"
            as={NavItem}
            onToggle={handleMenuToggle}
        >
            <DropdownToggle className={toggleClass}>
                <ToggleContent uj={selectedUJ} showUJID={showUJID} />
            </DropdownToggle>
            <Dropdown.Menu className={styles.dropdownMenu}>
                {userJourneys.length >= 10 && (
                    <Filter
                        key={menuOpen.toString()}
                        filter={ujFilter}
                        setFilter={setUJFilter}
                    />
                )}
                <GroupedUJItems
                    userJourneys={filteredUJs()}
                    groupByCustomerName={hasPermission(
                        currentUser.permissions,
                        "view-ujs-grouped-by-customer"
                    )}
                    userQueryRef={currentUser}
                    showUJID={showUJID}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
}
