import { faMobileScreen } from "@fortawesome/free-solid-svg-icons/faMobileScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChrome,
    faFirefoxBrowser,
    faEdge,
    faAndroid,
    faApple,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./UJPlatformIcon.module.scss";

const icons = {
    Chrome: faChrome,
    Firefox: faFirefoxBrowser,
    Edge: faEdge,
    iPhone: faApple,
    // We are getting rid of tablet UJ's, so just use the same icon as iPhone.
    iPad: faApple,
};

function V1Icon() {
    return (
        <span className={styles.container}>
            <img
                src={`/portal2/assets/${process.env.REACT_APP_ASSET_VERSION_HASH}/images/icons/v1.png`}
                title="Emulated"
                alt={"Emulated"}
                className={"v1"}
            />
            <img
                src={`/portal2/assets/${process.env.REACT_APP_ASSET_VERSION_HASH}/images/icons/v1-inverted.png`}
                title="Emulated"
                alt={"Emulated"}
                className={"v1 inverted"}
            />
            <img
                src={`/portal2/assets/${process.env.REACT_APP_ASSET_VERSION_HASH}/images/icons/v1-disabled.png`}
                title="Emulated"
                alt={"Emulated"}
                className={"v1 disabled"}
            />
        </span>
    );
}

export function UJPlatformIcon({ type }: { type: string }) {
    if (type === "V1") {
        return <V1Icon />;
    } else {
        let icon;
        let colourClass;
        if (type.indexOf("Android") !== -1) {
            if (type === "Chrome on Android") {
                icon = faAndroid;
                colourClass = "Android";
            } else {
                icon = faMobileScreen;
                colourClass = "App";
            }
        } else {
            icon = icons[type as keyof typeof icons];
            colourClass = type;
        }
        return (
            <span className={styles.container}>
                <FontAwesomeIcon
                    className={`platformIcon ${styles[colourClass]}`}
                    icon={icon}
                    title={type}
                />
            </span>
        );
    }
}
