import React, { ReactElement } from "react";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { useLoaderData } from "react-router-dom";
import { hasPermission } from "../lib/auth";
import { ForbiddenErrorPage } from "../pages/ErrorPage";
import { graphql } from "babel-plugin-relay/macro";
import { PrivateRouteQuery } from "./__generated__/PrivateRouteQuery.graphql";

export const privateRouteQuery = graphql`
    query PrivateRouteQuery {
        currentUser {
            permissions {
                allow
                deny
            }
        }
    }
`;

interface restrictedPagePermissionsProps {
    children: ReactElement;
    expectedAllowPermission: string;
}

export function PrivateRoute({
    children,
    expectedAllowPermission,
}: restrictedPagePermissionsProps) {
    const userPermission = usePreloadedQuery(
        privateRouteQuery,
        useLoaderData() as PreloadedQuery<PrivateRouteQuery>
    );

    const canViewPage = hasPermission(
        userPermission.currentUser.permissions,
        expectedAllowPermission
    );

    if (!canViewPage) {
        return <ForbiddenErrorPage></ForbiddenErrorPage>;
    }

    return children;
}
