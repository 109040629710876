import { ReactNode } from "react";

interface ToggleProps {
    enabled: boolean;
    onToggle: (value: boolean) => void;
    label: ReactNode;
}

export function Toggle(props: ToggleProps) {
    return (
        <div className="form-check form-switch">
            <label>
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={props.enabled}
                    onChange={() => props.onToggle(!props.enabled)}
                />
                {props.label}
            </label>
        </div>
    );
}

export function HookToggle({
    label,
    hook,
}: {
    label: ReactNode;
    hook: () => [boolean, (value: boolean) => void];
}) {
    const [enabled, setValue] = hook();
    return <Toggle enabled={enabled} onToggle={setValue} label={label} />;
}
