import { PropsWithChildren } from "react";
import { formatDateInUTC } from "./dateUtils";
import { DateTime } from "luxon";
import { useOldPortalURLSearchParams } from "./urlParams";
import { SampleStatus } from "../pages/wallboard/__generated__/Wallboard_userJourneySamples.graphql";

export type SampleDisplayOptions = {
    showPMEs: boolean;
    showThirdPartyWarnings: boolean;
    showThirdPartyErrors: boolean;
};

export type SampleEffectiveStatus = SampleStatus | "PME";

export type Sample = {
    readonly timestamp: number;
    readonly status: SampleStatus;
    readonly inPME: boolean;
    readonly allThirdPartyNoCustomerImpact: boolean;
    readonly extraWarningsAllThirdPartyNoCustomerImpact: boolean | null;
};

export function SampleLink(
    props: PropsWithChildren<{
        ujID: number;
        timestamp: number;
    }>
) {
    const params = new URLSearchParams();
    params.set("uj", props.ujID.toString());
    params.set(
        "sampleTime",
        formatDateInUTC(DateTime.fromSeconds(props.timestamp))
    );
    const oldPortalParams = useOldPortalURLSearchParams(params);

    return (
        <a
            href={`/portal2/userJourney/graphs/singleSample?${oldPortalParams.toString()}`}
        >
            {props.children}
        </a>
    );
}

export function getEffectiveStatus(
    sample: Sample,
    {
        showPMEs,
        showThirdPartyWarnings,
        showThirdPartyErrors,
    }: SampleDisplayOptions
): SampleEffectiveStatus {
    if (showPMEs && sample.inPME && sample.status !== "UNKNOWN") {
        return "PME";
    }

    if (sample.status === "WARNING") {
        if (!sample.allThirdPartyNoCustomerImpact || showThirdPartyWarnings) {
            return "WARNING";
        } else {
            return "OK";
        }
    }

    if (sample.status === "ERROR") {
        if (!sample.allThirdPartyNoCustomerImpact || showThirdPartyErrors) {
            return "ERROR";
        } else if (sample.extraWarningsAllThirdPartyNoCustomerImpact === null) {
            return "OK";
        } else if (
            !sample.extraWarningsAllThirdPartyNoCustomerImpact ||
            showThirdPartyWarnings
        ) {
            return "WARNING";
        } else {
            return "OK";
        }
    }

    return sample.status;
}
