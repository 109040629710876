import lowess from "@stdlib/stats-lowess";
import { graphql } from "babel-plugin-relay/macro";
import some from "lodash/some";
import { DateTime, Interval } from "luxon";
import {
    GraphQLTaggedNode,
    PreloadedQuery,
    PreloadFetchPolicy,
    useFragment,
    usePreloadedQuery,
    useQueryLoader,
} from "react-relay";
import { OperationType } from "relay-runtime";
import {
    formatDateInUTC,
    parse,
    parseWithMicroseconds,
} from "../../lib/dateUtils";
import {
    URLParameters,
    usePMEToggle,
    useStrictURLTimerange,
    useThirdPartyToggles,
} from "../../lib/urlParams";
import { graphUtils_groupedSamples$key } from "./__generated__/graphUtils_groupedSamples.graphql";
import { graphUtils_pmeWindows$key } from "./__generated__/graphUtils_pmeWindows.graphql";
import { graphUtils_preparedSamples$key } from "./__generated__/graphUtils_preparedSamples.graphql";
import { graphUtils_samples$key } from "./__generated__/graphUtils_samples.graphql";
import {
    graphUtils_ujVersions$data,
    graphUtils_ujVersions$key,
} from "./__generated__/graphUtils_ujVersions.graphql";
import {
    getEffectiveStatus,
    Sample,
    SampleEffectiveStatus,
} from "../../lib/samples";

export type DataPoint = {
    readonly timestamp: number;
    readonly value: number | null;
};

export type PlottedDataPoint = Omit<DataPoint, "value"> & {
    readonly value: number;
};

/**
 * Utility function to test whether a data point is a non-null data point or not.
 * It utilizes user-defined type guard to let the Typescript know the outcome
 * (https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates).
 *
 * @param dataPoint
 */
export function isPlottedDataPoint(
    dataPoint: DataPoint | PlottedDataPoint
): dataPoint is PlottedDataPoint {
    return dataPoint.value !== null;
}

export function getSmoothedSeries(series: DataPoint[]): DataPoint[] {
    const plottedData: PlottedDataPoint[] = series.filter(isPlottedDataPoint);
    if (plottedData.length === 0) {
        return [];
    }

    const xArr = plottedData.map((dataPoint) => dataPoint.timestamp);
    const yArr = plottedData.map((dataPoint) => dataPoint.value);
    let smoothedData: ReturnType<typeof lowess>;

    if (xArr.length < 2) {
        // lowess does not adhere to their own interface and returns ys
        // when number of points is less than 2.
        smoothedData = {
            x: xArr,
            y: yArr,
        };
    } else {
        const smoothness = series.length > 0 ? 100 / series.length : 0;
        smoothedData = lowess(
            plottedData.map((dataPoint) => dataPoint.timestamp),
            plottedData.map((dataPoint) => dataPoint.value),
            {
                f: smoothness,
                nsteps: 0, // Default iterations in pychartdir is 0
            }
        );
    }

    let smoothedDataIndex = 0;
    return series.map((dataPoint) => {
        let value;
        if (dataPoint.value !== null) {
            value = smoothedData.y[smoothedDataIndex];
            smoothedDataIndex++;
        } else {
            value = null;
        }
        return { timestamp: dataPoint.timestamp, value };
    });
}

function usePMEWindows(
    queryRef: graphUtils_pmeWindows$key
): ReadonlyArray<TimestampTimeRange> {
    const { pmeWindows } = useFragment(
        graphql`
            fragment graphUtils_pmeWindows on UserJourney {
                pmeWindows(timeRange: $timeRange) {
                    start
                    end
                }
            }
        `,
        queryRef
    );

    return pmeWindows.map((pmeWindow) => {
        return {
            start: parse(pmeWindow.start).toMillis(),
            end: parse(pmeWindow.end).toMillis(),
        };
    });
}

export type SampleWithTimeRange = Sample & {
    readonly start: number;
    readonly end: number;
};
type TimestampTimeRange = {
    start: number;
    end: number;
};
export type SampleGroup = TimestampTimeRange & {
    status: SampleEffectiveStatus;
};

function usePrepareSamplesData(queryRef: graphUtils_preparedSamples$key) {
    const userJourney = useFragment(
        graphql`
            fragment graphUtils_preparedSamples on UserJourney {
                ...graphUtils_pmeWindows
                samples(timeRange: $timeRange, pmeOn: false, backup: $backup) {
                    timestamp
                    status
                    allThirdPartyNoCustomerImpact
                    extraWarningsAllThirdPartyNoCustomerImpact
                }
            }
        `,
        queryRef
    );

    const pmeWindows = usePMEWindows(userJourney);

    return { samples: userJourney.samples, pmeWindows };
}

/**
 * Prepare samples for the graph, convert types, calculate time range, etc.
 *
 * This functions assumes all the samples are within the graph range.
 */
function prepareSamples(
    staticData: ReturnType<typeof usePrepareSamplesData>,
    volatileData: ReturnType<typeof usePrepareSamplesData>,
    graphEnd: DateTime
) {
    // Stitch together data from both sources.
    const samples = [...staticData.samples, ...volatileData.samples];
    const pmeWindows = [...staticData.pmeWindows, ...volatileData.pmeWindows];

    function inPME(timestamp: number) {
        return some(
            pmeWindows,
            (pmeWindow) =>
                pmeWindow.start < timestamp && timestamp < pmeWindow.end
        );
    }

    const preparedSamples: SampleWithTimeRange[] = [];
    for (let i = 0; i < samples.length; i++) {
        const currentQuerySample = samples[i];
        const nextQuerySample = samples[i + 1];
        const previousSample = preparedSamples[i - 1];
        const { timestamp, ...restOfTheSample } = currentQuerySample;
        let start: number;
        let end: number;

        if (nextQuerySample) {
            // End of this sample's time range is the midpoint between this and the next sample.
            end = timestamp + (nextQuerySample.timestamp - timestamp) / 2;
        } else {
            // There's no next sample, so extend it to the end of the range.
            end = graphEnd.toMillis();
        }

        if (previousSample) {
            start = previousSample.end;
        } else {
            start = timestamp;
        }

        const sampleInPME = inPME(timestamp);
        preparedSamples.push({
            timestamp,
            start,
            end,
            inPME: sampleInPME,
            ...restOfTheSample,
        });
    }

    return preparedSamples;
}

function useSamplesGroupedByStatus(
    samples: SampleWithTimeRange[],
    queryRef: graphUtils_groupedSamples$key
) {
    const fragmentRef = useFragment(
        graphql`
            fragment graphUtils_groupedSamples on Query {
                ...urlParams_thirdPartyToggleDefaults
            }
        `,
        queryRef
    );

    const [showThirdPartyErrors, showThirdPartyWarnings] =
        useThirdPartyToggles(fragmentRef);
    const [showPMEs] = usePMEToggle();
    const displayOptions = {
        showPMEs,
        showThirdPartyWarnings,
        showThirdPartyErrors,
    };

    if (samples.length === 0) {
        return [];
    }

    function groupFromSample(sample: SampleWithTimeRange) {
        return {
            start: sample.start,
            end: sample.end,
            status: getEffectiveStatus(sample, displayOptions),
        };
    }

    let currentSample = samples[0];
    let currentGroup: SampleGroup = groupFromSample(currentSample);
    const groups: SampleGroup[] = [currentGroup];
    for (currentSample of samples.slice(1)) {
        if (
            currentGroup.status ===
            getEffectiveStatus(currentSample, displayOptions)
        ) {
            currentGroup.end = currentSample.end;
        } else {
            // Prepare for next group.
            currentGroup = groupFromSample(currentSample);
            groups.push(currentGroup);
        }
    }

    return groups;
}

export function getDataPoint(sample: Sample, metricValue: number | null) {
    return {
        timestamp: sample.timestamp,
        value: sample.status === "ERROR" || sample.inPME ? null : metricValue,
    };
}

export type UJVersion = graphUtils_ujVersions$data["versions"][number] & {
    start: number;
};

function useUJVersions(queryRef: graphUtils_ujVersions$key) {
    const { versions } = useFragment(
        graphql`
            fragment graphUtils_ujVersions on UserJourney {
                versions(timeRange: $timeRange) {
                    start
                    summary
                    details
                    steps {
                        id
                        name
                    }
                }
            }
        `,
        queryRef
    );

    return versions.map(({ start, ...restOfTheVersion }) => ({
        start: parseWithMicroseconds(start).toMillis(),
        ...restOfTheVersion,
    }));
}

export function useSamples(
    staticQueryRef: graphUtils_samples$key,
    volatileQueryRef: graphUtils_samples$key
) {
    const fragment = graphql`
        fragment graphUtils_samples on Query {
            ...graphUtils_groupedSamples
            userJourney(ujId: $ujID) {
                ...graphUtils_preparedSamples
                ...graphUtils_ujVersions
            }
        }
    `;
    const staticFragmentRef = useFragment(fragment, staticQueryRef);
    const volatileFragmentRef = useFragment(fragment, volatileQueryRef);

    const [urlTimeRange] = useStrictURLTimerange();

    const staticPreparedSamplesData = usePrepareSamplesData(
        staticFragmentRef.userJourney
    );
    const volatilePreparedSamplesData = usePrepareSamplesData(
        volatileFragmentRef.userJourney
    );
    const samples = prepareSamples(
        staticPreparedSamplesData,
        volatilePreparedSamplesData,
        // Samples should end at the end of the range or now, whatever is earlier.
        DateTime.min(DateTime.now(), urlTimeRange.end)
    );

    const groupedSamples = useSamplesGroupedByStatus(
        samples,
        // We can pick either query here because they return the same value.
        staticFragmentRef
    );

    // If both queries are non-empty the version which crosses the threshold is returned by
    // both, so remove it.
    const staticVersions = useUJVersions(staticFragmentRef.userJourney);
    const volatileVersions = useUJVersions(volatileFragmentRef.userJourney);
    let versions;
    if (staticVersions.length > 0 && volatileVersions.length > 0) {
        versions = [...staticVersions, ...volatileVersions.slice(1)];
    } else {
        versions = [...staticVersions, ...volatileVersions];
    }

    return { preparedSamples: samples, groupedSamples, versions };
}

function loadGraphQuery(
    loadQuery: ReturnType<typeof useQueryLoader>[1],
    ujID: number,
    interval: Interval | null,
    backup: boolean,
    fetchPolicy: PreloadFetchPolicy
) {
    const someTime = DateTime.fromISO("2000-01-01T00:00:00+00:00");
    loadQuery(
        {
            ujID,
            // Ensure we query the api with UTC date-times
            // Even when we're not going to execute this we need to pass a time range so make one up.
            timeRange: {
                start: formatDateInUTC(interval?.start || someTime),
                end: formatDateInUTC(interval?.end || someTime),
            },
            backup,
        },
        // store-only would be better because then the query would never be executed. But
        // preloaded queries don't allow it. This one will still only be executed once per UJ.
        { fetchPolicy: interval === null ? "store-or-network" : fetchPolicy }
    );
}

export function loadGraphQueries(
    loadStaticQuery: ReturnType<typeof useQueryLoader>[1],
    loadVolatileQuery: ReturnType<typeof useQueryLoader>[1],
    { ujID, timeRange, backup }: GraphParameters
) {
    const volatileDataThreshold = DateTime.now()
        .minus({ hour: 24 })
        // Extend this to the previous hour so the time ranges don't change every time,
        // causing new queries.
        .startOf("hour");
    const interval = Interval.fromDateTimes(timeRange.start, timeRange.end);
    let staticInterval: Interval | null = null;
    let volatileInterval: Interval | null = null;

    if (interval.isAfter(volatileDataThreshold)) {
        volatileInterval = interval;
    } else if (interval.contains(volatileDataThreshold)) {
        [staticInterval, volatileInterval] = interval.splitAt(
            volatileDataThreshold
        );
    } else {
        staticInterval = interval;
    }

    loadGraphQuery(
        loadStaticQuery,
        ujID,
        staticInterval,
        backup,
        "store-or-network"
    );
    loadGraphQuery(
        loadVolatileQuery,
        ujID,
        volatileInterval,
        backup,
        "store-and-network"
    );
}

export type GraphParameters = Omit<URLParameters, "graphType">;

export function usePreloadedGraphQueries<T extends OperationType>(
    query: GraphQLTaggedNode,
    queryRefs: [PreloadedQuery<T>, PreloadedQuery<T>]
) {
    const staticAPIResponse = usePreloadedQuery(query, queryRefs[0]);
    const volatileAPIResponse = usePreloadedQuery(query, queryRefs[1]);

    return [staticAPIResponse, volatileAPIResponse];
}
