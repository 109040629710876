import { Badge, Dropdown, Nav } from "react-bootstrap";
import { hasPermission, UserPermissions } from "../../lib/auth";
import { useLocation, useSearchParams } from "react-router-dom";
import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { OldPortalDropdownItem } from "../../lib/urlParams";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { DropdownMenus_UJPageMenu$key } from "./__generated__/DropdownMenus_UJPageMenu.graphql";

function AlphaBadge() {
    return <Badge bg={"danger"}>ALPHA</Badge>;
}

function ItemContent(props: { name: string; isAlpha?: boolean }) {
    return (
        <>
            {props.name} {props.isAlpha && <AlphaBadge />}
        </>
    );
}

type BaseMenuItem = {
    href: string;
    active?: boolean;
};

type MenuItemProps = BaseMenuItem & {
    name: string;
    permission?: string;
    target?: string;
    isAlpha?: boolean;
    includeQuery?: boolean;
    changeParams?: Array<{ name: string; value?: string }>;
    permissions?: UserPermissions;
};

function MenuItem(props: MenuItemProps) {
    const [searchParams] = useSearchParams();
    if (
        (props.permissions !== undefined &&
            props.permission !== undefined &&
            hasPermission(props.permissions, props.permission)) ||
        props.permission === undefined
    ) {
        let href = props.href;
        if (props.includeQuery) {
            if (props.changeParams !== undefined) {
                props.changeParams.forEach(({ name, value }) => {
                    if (value === undefined) {
                        searchParams.delete(name);
                    } else {
                        searchParams.set(name, value);
                    }
                });
            }
            href += "?" + searchParams.toString();
        }
        const itemProps = {
            href: href,
            target: props.target,
            disabled: props.active,
        };
        if (props.includeQuery && props.href.startsWith("/portal2")) {
            return (
                <OldPortalDropdownItem {...itemProps}>
                    <ItemContent name={props.name} isAlpha={props.isAlpha} />
                </OldPortalDropdownItem>
            );
        } else {
            return (
                <Dropdown.Item {...itemProps}>
                    <ItemContent name={props.name} isAlpha={props.isAlpha} />
                </Dropdown.Item>
            );
        }
    } else {
        return null;
    }
}

export function DropdownToggle(props: {
    children: ReactNode;
    className?: string;
}) {
    return (
        <Dropdown.Toggle
            className={props.className}
            title={"Click to open menu"}
        >
            {props.children}
            <FontAwesomeIcon
                style={{ marginLeft: "1em" }}
                icon={faChevronDown}
            />
        </Dropdown.Toggle>
    );
}

export function PreferencesMenu(props: { permissions: UserPermissions }) {
    // Don't let prettier mess up the formatting, it's more readable in this more condensed form
    // prettier-ignore
    return (
        <Dropdown as={Nav.Item}>
            <DropdownToggle>Preferences</DropdownToggle>
            <Dropdown.Menu>
                <MenuItem
                    name="Personal Info" href="/portal2/setting/personalInfo"
                />
                <MenuItem
                    name="Preferences" href="/portal2/setting/userPreferences"
                    permission="!view-admin" permissions={props.permissions}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
}

export function SettingsMenu(props: { permissions: UserPermissions }) {
    // Don't let prettier mess up the formatting, it's more readable in this more condensed form
    // prettier-ignore
    return (
        <Dropdown as={Nav.Item}>
            <DropdownToggle>Settings</DropdownToggle>
            <Dropdown.Menu>
                <MenuItem
                    name="Create new email report" href="/portal2/report/createEmailReport"
                    permission="view-reporting" permissions={props.permissions}
                />
                <MenuItem
                    name="Manage email reports" href="/portal2/report/manageEmailReports"
                    permission="view-reporting" permissions={props.permissions}
                />
                <MenuItem
                    name={hasPermission(props.permissions, "view-admin")
                            ? "User Journey Settings"
                            : "User Journeys"}
                    href="/portal2/setting/userjourneys"
                    permission="view-settings-user-journey" permissions={props.permissions}
                />
                <MenuItem
                    name="IP Addresses" href="/portal2/setting/ipAddresses"
                    permission="view-settings-ip-addresses" permissions={props.permissions}
                />
                <MenuItem
                    name="KPI Thresholds" href="/portal2/setting/kpiThresholds"
                    permission="view-settings-kpi" permissions={props.permissions}
                />
                <MenuItem
                    name="Alert Contacts" href="/portal2/setting/alerts/contacts"
                    permission="view-settings-alerts" permissions={props.permissions}
                />
                <MenuItem
                    name="User Journey by Contact" href="/portal2/setting/userjourneys/alertContacts"
                    permission="view-settings-alerts" permissions={props.permissions}
                />
                <MenuItem
                    name="Fine-Grained Alerting" href="/portal2/setting/alerts/manage"
                    permission="view-settings-alerts" permissions={props.permissions}
                />
                <MenuItem
                    name="Lost Sales Settings" href="/portal2/setting/lostSales"
                    permission="view-settings-lost-sales" permissions={props.permissions}
                />
                <MenuItem
                    name="Hosts Settings" href="/portal2/setting/hosts/manage"
                    permission="view-settings-hosts" permissions={props.permissions}
                />
                <MenuItem
                    name="Components Settings" href="/portal2/setting/components"
                    permission="view-settings-components" permissions={props.permissions}
                />
                <MenuItem
                    name="Custom Error Filters Settings" href="/portal2/setting/errorFilters"
                    permission="view-settings-error-filters" permissions={props.permissions}
                />
                <MenuItem
                    name="Manage PMEs" href="/portal2/setting/pme/managePMEs"
                    permission="view-settings-pme" permissions={props.permissions}
                />
                <MenuItem
                    name="Analytics Integration" href="/portal2/setting/analytics/manage"
                    permission="view-settings-analytics" permissions={props.permissions}
                />
                <MenuItem
                    name="User Manager" href="/portal2/admin/userManager"
                    permission="view-permissions" permissions={props.permissions}
                />
                <MenuItem
                    name="Customer Settings" href="/portal2/setting/customer"
                    permission="edit-customer" permissions={props.permissions}
                />

            </Dropdown.Menu>
        </Dropdown>
    );
}

export function AdminMenu(props: { permissions: UserPermissions }) {
    // Don't let prettier mess up the formatting, it's more readable in this more condensed form
    // prettier-ignore
    return (
        <Dropdown as={Nav.Item}>
            <DropdownToggle>Admin</DropdownToggle>
            <Dropdown.Menu>
                <MenuItem name="Unimpersonate" href="/portal2/admin/UnImpersonateAs"
                          permission="!view-admin" permissions={props.permissions}/>
                <MenuItem name="Impersonate As" href="/portal2/admin/impersonateAs"
                          permission="view-admin" permissions={props.permissions}/>
                <Dropdown.Divider/>
                <MenuItem name="Grafana" href="/grafana" target="_blank"
                          permissions={props.permissions}/>
                <MenuItem name="Dashboards - wiki" href="https://thinktribe.atlassian.net/wiki/spaces/DT/pages/2925690884/A+Guide+to+Custom+Dashboards" target="_blank"
                          permissions={props.permissions}/>
                <MenuItem name="All Dashboards (anonymised)" href="/portal2/admin/customDashboards?hideCustomerNames=true"
                          permission="view-admin" permissions={props.permissions}/>
                <MenuItem name="All Dashboards (with customer names)" href="/portal2/admin/customDashboards"
                          permission="view-admin" permissions={props.permissions}/>
                {hasPermission(props.permissions, "view-admin") && <Dropdown.Divider/>}
                <MenuItem name="User Journey Admin" href="/portal2/admin/userJourneys"
                          permission="view-admin" permissions={props.permissions}/>
                <MenuItem name="Group Management" href="/portal2/setting/ujGroups/manage"
                          permission="view-admin" permissions={props.permissions}/>
                <MenuItem name="Customer Manager" href="/portal2/admin/customers/customerManager"
                          permission="view-admin" permissions={props.permissions}/>
                <MenuItem name="Feature Toggles" href="/portal2/admin/featureToggles"
                          permission="view-admin" permissions={props.permissions}/>
                <MenuItem name="Latest Browser Version Settings" href="/portal2/admin/latestBrowserVersions"
                          permission="view-admin" permissions={props.permissions}/>
                <MenuItem name="Manage Injectors" href="/portal2/admin/manageInjectors"
                          permission="view-admin" permissions={props.permissions}/>
                <MenuItem name="Email Reports Overview" href="/portal2/admin/emailReportsOverview"
                          permission="view-admin" permissions={props.permissions}/>
                <MenuItem name="Ballotbox Status" href="/portal2/admin/ballotboxStatus"  target="_blank"
                          permission="view-admin" permissions={props.permissions}/>
                <MenuItem name="Clone UJs" href="/portal2/admin/cloneUJs"
                          permission="view-admin" permissions={props.permissions}/>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export function OverviewMenu(props: { permissions: UserPermissions }) {
    const commonParams: Partial<MenuItemProps> = {
        includeQuery: true,
        changeParams: [{ name: "uj", value: undefined }],
    };
    const commonReportingParams: Partial<MenuItemProps> = {
        ...commonParams,
        permission: "view-reporting",
        permissions: props.permissions,
    };
    const menuItems: MenuItemProps[] = [
        {
            name: "Management Overview",
            href: "/portal2/management/overview",
            ...commonReportingParams,
        },
        {
            name: "Wallboard",
            href: "/wallboard",
            ...commonParams,
        },
        {
            name: "Historic Status",
            href: "/portal2/status/liveHistoric",
            ...commonParams,
        },
        {
            name: "Availability",
            href: "/portal2/report/availability",
            ...commonReportingParams,
        },
        {
            name: "Delivery Time",
            href: "/portal2/report/deliveryTime",
            ...commonReportingParams,
        },
        {
            name: "Lost Sales",
            href: "/portal2/report/lostSales",
            ...commonReportingParams,
        },
        {
            name: "Issues",
            href: "/portal2/report/alertIssues",
            ...commonReportingParams,
        },
        {
            name: "Site Release Manager",
            href: "/portal2/srm/management",
            permission: "view-srm",
            permissions: props.permissions,
        },
    ];
    const activeItem = useActiveItem(menuItems);

    return (
        <Dropdown as={Nav.Item}>
            {activeItem ? (
                <DropdownToggle className={"active"}>
                    {activeItem.name}
                </DropdownToggle>
            ) : (
                <DropdownToggle>Overview</DropdownToggle>
            )}
            <Dropdown.Menu>
                {menuItems.map((item) => (
                    <MenuItem key={item.name} {...item} />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

function useActiveItem<ItemType extends BaseMenuItem>(
    items: ItemType[]
): ItemType | undefined {
    const location = useLocation();
    let activeItem: ItemType | undefined = undefined;
    items.forEach((item) => {
        if (location.pathname.startsWith(item.href)) {
            item.active = true;
            activeItem = item;
        }
    });
    return activeItem;
}

export function UJPageMenu({
    queryRef,
}: {
    queryRef: DropdownMenus_UJPageMenu$key;
}) {
    const user = useFragment(
        graphql`
            fragment DropdownMenus_UJPageMenu on User {
                isAdmin
            }
        `,
        queryRef
    );

    const menuItems: MenuItemProps[] = [
        {
            name: "Performance Graphs",
            href: "/userJourney/graphs",
        },
        {
            name: "UJ Overview",
            href: "/portal2/userJourney/home",
        },
        {
            name: "Errors",
            href: "/portal2/userJourney/errors",
        },
        {
            name: "Alerts",
            href: "/portal2/userJourney/alerts",
        },
        {
            name: "Step Delivery Times",
            href: "/portal2/userJourney/analysis",
        },
        {
            name: "Page Weighting",
            href: "/portal2/userJourney/pageSizes",
        },
        {
            name: "DNS/First Byte - by Host",
            href: "/portal2/userJourney/graphsByHost/shortTermByHost",
        },
        {
            name: "Component Performance",
            href: "/portal2/userJourney/graphsByComponent/trendsByComponent",
        },
    ];
    if (user.isAdmin) {
        menuItems.push({
            name: "Admin",
            href: "/portal2/admin/editUserJourneys",
        });
    }

    const activeItem = useActiveItem(menuItems);
    if (activeItem) {
        return (
            <Dropdown as={Nav.Item}>
                <DropdownToggle className={"active"}>
                    <ItemContent name={activeItem.name} />
                </DropdownToggle>
                <Dropdown.Menu>
                    {menuItems.map((item) => (
                        <MenuItem
                            key={item.name}
                            {...item}
                            includeQuery={true}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    } else {
        return <></>;
    }
}
