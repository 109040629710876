/**
 * @generated SignedSource<<a21100f304c9275a12db3d636870d478>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PrivateRouteQuery$variables = Record<PropertyKey, never>;
export type PrivateRouteQuery$data = {
  readonly currentUser: {
    readonly permissions: {
      readonly allow: ReadonlyArray<string>;
      readonly deny: ReadonlyArray<string>;
    };
  };
};
export type PrivateRouteQuery = {
  response: PrivateRouteQuery$data;
  variables: PrivateRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Permissions",
  "kind": "LinkedField",
  "name": "permissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deny",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrivateRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PrivateRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "31b1656d85260ba46c59045811a03f84",
    "id": null,
    "metadata": {},
    "name": "PrivateRouteQuery",
    "operationKind": "query",
    "text": "query PrivateRouteQuery {\n  currentUser {\n    permissions {\n      allow\n      deny\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b4f2c3ef04d65c4215c39b9a4b8f2a13";

export default node;
