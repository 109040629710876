import * as am5xy from "@amcharts/amcharts5/xy";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect } from "react";
import { PreloadedQuery } from "react-relay";
import { timeRangeToString } from "../../lib/dateUtils";
import { useStrictURLTimerange } from "../../lib/urlParams";
import {
    UJStepGraphQuery,
    UJStepGraphQuery$data,
} from "./__generated__/UJStepGraphQuery.graphql";
import {
    addAnalyticsVisitorsSeries,
    addPerSampleSeries,
    configureLegend,
    getChart,
    getSeries,
    getXAxis,
    makeMarkings,
    snapCursorToSeries,
    updateAnalyticsVisitorsSeries,
    updateXAxisBounds,
    useConfigureLegendLayoutEffect,
    useEventHandlers,
    useExportChartFilename,
    useRoot,
    VISITORS_SERIES_NAMES,
} from "./amChartsUtils";
import {
    DataPoint,
    getDataPoint,
    UJVersion,
    usePreloadedGraphQueries,
    useSamples,
} from "./graphUtils";
import { UJGraph } from "./UJGraph";
import { Analytics } from "../../lib/analytics";
import { Sample } from "../../lib/samples";

interface ConfigureStepSeries {
    (series: am5xy.LineSeries): void;
}

export function UJStepGraph(props: {
    queryRefs: [
        PreloadedQuery<UJStepGraphQuery>,
        PreloadedQuery<UJStepGraphQuery>
    ];
    analytics: Analytics;
    stacked?: true;
}) {
    const [urlTimeRange] = useStrictURLTimerange();
    const urlTimeRangeString = timeRangeToString(urlTimeRange);

    const [staticAPIResponse, volatileAPIResponse] = usePreloadedGraphQueries(
        graphql`
            query UJStepGraphQuery(
                $ujID: Int!
                $timeRange: LimitedTimeRangeInput!
                $backup: Boolean!
            ) {
                ...graphUtils_samples
                ...amChartsUtils_useRoot
                userJourney(ujId: $ujID) {
                    stepResults(timeRange: $timeRange, backup: $backup) {
                        timestamp
                        number
                        pageDelivery
                    }
                }
            }
        `,
        props.queryRefs
    );

    const { preparedSamples, groupedSamples, versions } = useSamples(
        staticAPIResponse,
        volatileAPIResponse
    );
    const allStepResults = [
        ...staticAPIResponse.userJourney.stepResults,
        ...volatileAPIResponse.userJourney.stepResults,
    ];
    const stepSeries = getStepSeries(preparedSamples, allStepResults, versions);

    let configureStepSeries: ConfigureStepSeries | undefined;
    let chartName = "Individual Step Delivery Times Graph";
    if (props.stacked === true) {
        configureStepSeries = (series: am5xy.LineSeries) => {
            series.set("stacked", true);
            series.fills.template.setAll({ visible: true, fillOpacity: 1 });
        };
        chartName = "User Journey Delivery Time Breakdown By Step";
    }

    const rootRef = useRoot(staticAPIResponse, 1, (root) => {
        const chart = getChart(root);
        addAnalyticsVisitorsSeries(chart);
    });

    useEffect(() => {
        const root = rootRef.current!;
        const chart = getChart(root);
        const xAxis = getXAxis(chart);

        updateXAxisBounds(getXAxis(chart), urlTimeRange);

        const plottedSeriesNames = chart.series.values.map(
            (series) => series.get("name") as string
        );
        const stepSeriesNames = stepSeries.map((series) => series.step.name);
        const allSeries: am5xy.XYSeries[] = [];
        const excludeSeriesNames: string[] = [];

        chart.series.values.forEach((series) => {
            if (
                series.get("name") !== undefined &&
                VISITORS_SERIES_NAMES.indexOf(series.get("name")!) === -1
            ) {
                const seriesName = series.get("name") as string;
                if (stepSeriesNames.includes(seriesName)) {
                    allSeries.push(series);
                } else {
                    // Handle series no longer present in the data.
                    // Don't just remove the series as this might cause a lot of
                    // event dispatcher errors, don't include it the legend instead.
                    // This way we also remember the toggle state and the colour of
                    // the series.
                    excludeSeriesNames.push(seriesName);
                    // Clear the series data, otherwise it will show when it comes
                    // back into the view, even before the data query completes.
                    series.data.setAll([]);
                }
            }
        });

        // Add new series that came with the data.
        stepSeriesNames.forEach((seriesName) => {
            if (!plottedSeriesNames.includes(seriesName)) {
                const series = addPerSampleSeries(root, seriesName);
                if (configureStepSeries) {
                    configureStepSeries(series);
                }
                allSeries.push(series);
            }
        });

        snapCursorToSeries(chart, allSeries);

        for (const series of stepSeries) {
            getSeries(chart, series.step.name).data.setAll(series.data);
        }

        // Ensure the order of the step series is maintained, before we configure the legend.
        stepSeriesNames.forEach((name, index) => {
            const series = getSeries(chart, name);
            chart.series.moveValue(series, index);
        });

        configureLegend(chart, excludeSeriesNames);
        updateAnalyticsVisitorsSeries(chart, props.analytics);

        makeMarkings(xAxis, groupedSamples, versions);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlTimeRangeString, groupedSamples, stepSeries, props.analytics]);

    useEventHandlers(rootRef);
    useConfigureLegendLayoutEffect(rootRef);
    useExportChartFilename(rootRef, chartName);

    return <UJGraph />;
}

type StepResults = UJStepGraphQuery$data["userJourney"]["stepResults"];
type StepResult = StepResults[number];
type Step = UJVersion["steps"][number];
type StepSeries = {
    step: Step;
    number: number;
    firstResultIndex: number;
    data: DataPoint[];
};

function getStepSeries(
    preparedSamples: Sample[],
    stepResults: StepResults,
    versions: UJVersion[]
) {
    // Error samples may not have results for all steps. To ensure those steps have gaps, the
    // series need null data points. So:
    // - first find all the series;
    // - then fill in null data points for all samples in each series;
    // - then overwrite the nulls with the actual data where it exists.
    const seriesByStepID: {
        [key: string]: StepSeries;
    } = {};

    let versionsIndex = 0;
    let stepResultsIndex = 0;
    preparedSamples.forEach((sample, sampleIndex) => {
        let sampleStepResults: StepResult[] = [];
        while (
            stepResultsIndex < stepResults.length &&
            stepResults[stepResultsIndex].timestamp === sample.timestamp
        ) {
            sampleStepResults.push(stepResults[stepResultsIndex]);
            stepResultsIndex++;
        }

        while (
            versionsIndex < versions.length - 1 &&
            sample.timestamp > versions[versionsIndex + 1].start
        ) {
            versionsIndex++;
        }
        versions[versionsIndex].steps.forEach((step, number) => {
            if (seriesByStepID[step.id] === undefined) {
                seriesByStepID[step.id] = {
                    step,
                    number,
                    firstResultIndex: sampleIndex,
                    // Pre-fill data with null values. This avoids problems with
                    // stacks rendering incorrectly if there are gaps.
                    data: preparedSamples.map((sample) => ({
                        timestamp: sample.timestamp,
                        value: null,
                    })),
                };
            }

            const value =
                number < sampleStepResults.length
                    ? sampleStepResults[number].pageDelivery
                    : null;
            seriesByStepID[step.id].data[sampleIndex] = getDataPoint(
                sample,
                value
            );
        });
    });

    // Sort by the step number. Sort the steps with the same number
    // (e.g. because a step was added in the range) by the order they appear in the series.
    return Object.values(seriesByStepID).sort((a, b) => {
        if (a.number === b.number) {
            return a.firstResultIndex - b.firstResultIndex;
        }

        return a.number - b.number;
    });
}
