/**
 * @generated SignedSource<<fc27f554025bd02ba4a5f7eb3f7f1923>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationType = "ERROR" | "INFO" | "%future added value";
export type NotificationsQuery$variables = Record<PropertyKey, never>;
export type NotificationsQuery$data = {
  readonly __typename: string;
  readonly localState: {
    readonly notifications: ReadonlyArray<{
      readonly id: number;
      readonly isImportant: boolean;
      readonly link: {
        readonly description: string;
        readonly url: string;
      } | null;
      readonly message: string;
      readonly timestamp: number;
      readonly title: string;
      readonly ttl: number;
      readonly type: NotificationType;
    }>;
  } | null;
};
export type NotificationsQuery = {
  response: NotificationsQuery$data;
  variables: NotificationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LocalState",
        "kind": "LinkedField",
        "name": "localState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "notifications",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ttl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isImportant",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Link",
                "kind": "LinkedField",
                "name": "link",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "37d368f85ce05975d7cfefec5248abe1",
    "id": null,
    "metadata": {},
    "name": "NotificationsQuery",
    "operationKind": "query",
    "text": "query NotificationsQuery {\n  __typename\n}\n"
  }
};
})();

(node as any).hash = "5da46a839542c91194e0ef81ae3790f5";

export default node;
