import { Toast, ToastContainer } from "react-bootstrap";
import { useCallback, useEffect, useId, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
    NotificationsQuery,
    NotificationsQuery$data,
} from "./__generated__/NotificationsQuery.graphql";
import { useNotifications } from "../lib/notifications";
import { getTimestamp, relative } from "../lib/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons/faCircleExclamation";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import styles from "./Notifications.module.scss";

/* Render a relative duration string from the timestamp to now.
 * Regularly update, so that what we show the user is not too out of date.
 */
function Relative(props: { timestamp: number }) {
    const [relativeString, setRelativeString] = useState<string>(
        relative(props.timestamp)
    );

    useEffect(() => {
        const interval = setInterval(() => {
            // re-render every 10 seconds.
            setRelativeString(relative(props.timestamp));
        }, 10000);
        return () => clearInterval(interval);
    }, [props.timestamp]);
    return <>{relativeString}</>;
}

export function NotificationsBell() {
    const result = useLazyLoadQuery<NotificationsQuery>(
        graphql`
            query NotificationsQuery {
                # Add __typename to make relay compiler allow us to query only local state.
                ... on Query {
                    __typename
                }
                localState {
                    notifications {
                        id
                        type
                        title
                        message
                        ttl
                        timestamp
                        isImportant
                        link {
                            url
                            description
                        }
                    }
                }
            }
        `,
        {}
    );

    const [showNotifications, setShowNotifications] = useState<boolean>(false);
    const notifications = result.localState?.notifications || [];
    const [lastView, setLastView] = useState<number>(0);
    const { expireOldNotifications } = useNotifications();
    const haveNewImportantNotifications = notifications.find(
        (n) => n.isImportant && n.timestamp > lastView
    );
    const totalNewNotifications = notifications.reduce(
        (count, notification) =>
            notification.timestamp > lastView ? count + 1 : count,
        0
    );

    const toggleNotifications = useCallback(() => {
        if (showNotifications) {
            // The user has been looking at the notifications and has now finished.
            // So expire any old notifications
            expireOldNotifications();
        } else {
            setLastView(getTimestamp());
        }
        setShowNotifications((prev) => !prev);
    }, [expireOldNotifications, showNotifications]);

    const haveNotifications = notifications.length > 0;

    useEffect(() => {
        // There are no notifications so close the notification box
        if (!haveNotifications && showNotifications) {
            toggleNotifications();
        }
        // There are new important notifications so open the notification box.
        if (haveNewImportantNotifications && !showNotifications) {
            toggleNotifications();
        }
        if (totalNewNotifications > 0 && showNotifications) {
            // The user is looking at the notifications right now, so update the lastView.
            setLastView(getTimestamp());
        }
    }, [
        haveNewImportantNotifications,
        haveNotifications,
        showNotifications,
        toggleNotifications,
        totalNewNotifications,
    ]);

    const iconClass = haveNotifications
        ? styles.haveNotifications
        : styles.noNotifications;

    const id = useId();

    return (
        <>
            <span
                role="button"
                aria-label="Notifications Bell"
                aria-controls={id}
                aria-expanded={showNotifications}
                className={"fa-layers fa-fw " + styles.bellButton}
                onClick={toggleNotifications}
            >
                <FontAwesomeIcon
                    icon={faBell}
                    className={iconClass}
                    transform="grow-20"
                    shake={
                        haveNotifications &&
                        // Stop shaking the bell if the user has clicked to view the notifications,
                        // until there is a new notification
                        lastView <
                            notifications[notifications.length - 1].timestamp
                    }
                />
                {totalNewNotifications && (
                    <span className="fa-layers-text fa-inverse">
                        {totalNewNotifications}
                    </span>
                )}
            </span>
            {showNotifications && (
                <NotificationsDisplayer id={id} notifications={notifications} />
            )}
        </>
    );
}

type Notifications = NonNullable<
    NotificationsQuery$data["localState"]
>["notifications"];

function Notification({
    notification,
}: {
    notification: Notifications[number];
}) {
    const { deleteNotification } = useNotifications();

    return (
        <Toast
            // If a user dismisses it then it is deleted from the store.
            onClose={() => {
                deleteNotification(notification.id);
            }}
        >
            <Toast.Header
                className={
                    notification.type === "ERROR" ? styles.error : styles.info
                }
            >
                <strong className="me-auto">
                    <FontAwesomeIcon
                        role={"img"}
                        icon={
                            notification.type === "ERROR"
                                ? faCircleExclamation
                                : faCircleInfo
                        }
                        className={styles.notificationIcon}
                        title={notification.type + " Icon"}
                    />

                    {notification.title}
                </strong>
                <small>
                    <Relative timestamp={notification.timestamp} />
                </small>
            </Toast.Header>
            <Toast.Body>
                <p>{notification.message}</p>
                {notification.link && (
                    <a href={notification.link.url!}>
                        {notification.link.description}
                    </a>
                )}
            </Toast.Body>
        </Toast>
    );
}

function NotificationsDisplayer({
    id,
    notifications,
}: {
    id: string;
    notifications: Notifications;
}) {
    return (
        // The `Header.User` component forces no wrapping, so we have to set it back.
        <ToastContainer
            id={id}
            position={"top-center"}
            className={"pt-3 text-wrap"}
        >
            {notifications
                .map((notification) => {
                    return (
                        <Notification
                            notification={notification}
                            key={notification.id}
                        />
                    );
                })
                .reverse()}
        </ToastContainer>
    );
}
