import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import React, { useCallback, useContext, useRef, useState } from "react";
import { GlobalContext } from "./GlobalContext";
import { Button, Overlay, Popover } from "react-bootstrap";
import styles from "./ToggleMenu.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { HookToggle, Toggle } from "./Toggles";
import {
    useBackupToggle,
    usePMEToggle,
    useThirdPartyToggles,
} from "../lib/urlParams";
import { TextWithTooltip, TooltipName } from "./TextWithTooltip";
import { ToggleMenu$key } from "./__generated__/ToggleMenu.graphql";
import { urlParams_thirdPartyToggleDefaults$key } from "../lib/__generated__/urlParams_thirdPartyToggleDefaults.graphql";
import { hasPermission } from "../lib/auth";

type CommonProps = {
    queryRef: ToggleMenu$key;
};

type ToggleMenuProps = CommonProps & {
    showBackup?: boolean;
};

export function ToggleMenu(props: ToggleMenuProps) {
    const toggleMenuButtonRef = useRef<HTMLButtonElement>(null);
    const [showToggleMenu, setShowToggleMenu] = useState<boolean>(false);

    const handleOnClick = useCallback(() => {
        setShowToggleMenu((prev) => !prev);
    }, []);

    return (
        <>
            <Button
                ref={toggleMenuButtonRef}
                onClick={handleOnClick}
                className={showToggleMenu ? "active" : ""}
                title={"Data Toggles"}
                variant={"tribe-primary"}
            >
                <FontAwesomeIcon icon={faBars} />
            </Button>
            <Overlay
                target={toggleMenuButtonRef.current}
                show={showToggleMenu}
                placement="left-start"
                rootClose={true}
                // onHide required for rootClose to work
                onHide={() => {
                    setShowToggleMenu(false);
                }}
            >
                <Popover>
                    <Popover.Body>
                        <ToggleMenuContent
                            queryRef={props.queryRef}
                            showBackup={props.showBackup ?? true}
                        />
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
}

function ToggleMenuContent(props: CommonProps & { showBackup: boolean }) {
    const apiResponse = useFragment(
        graphql`
            fragment ToggleMenu on Query {
                currentUser {
                    permissions {
                        allow
                        deny
                    }
                }
                ...urlParams_thirdPartyToggleDefaults
            }
        `,
        props.queryRef
    );
    const showBackup =
        props.showBackup &&
        hasPermission(apiResponse.currentUser.permissions, "view-backup-data");
    const { globalContext, setGlobalContext } = useContext(GlobalContext);

    function setShouldUseLocalTimezone(useLocalTimezone: boolean) {
        setGlobalContext({ useLocalTimezone: useLocalTimezone });
    }

    return (
        <>
            <div className={styles.toggles}>
                {showBackup && (
                    <HookToggle label={"Backup"} hook={useBackupToggle} />
                )}
                <ToggleWithTooltip
                    name={"PME"}
                    toggle={(label) => (
                        <HookToggle label={label} hook={usePMEToggle} />
                    )}
                />
                <ThirdPartyToggles queryRef={apiResponse} />
                <ToggleWithTooltip
                    name={"Use Local Time Zone"}
                    toggle={(label) => (
                        <Toggle
                            label={label}
                            enabled={globalContext.useLocalTimezone}
                            onToggle={setShouldUseLocalTimezone}
                        />
                    )}
                />
            </div>
        </>
    );
}

type ToggleWithTooltipProps = {
    name: TooltipName;
    toggle: (label: JSX.Element) => JSX.Element;
};
function ToggleWithTooltip({ name, toggle }: ToggleWithTooltipProps) {
    const target = useRef(null);
    const label = (
        <TextWithTooltip
            name={name}
            overlayProps={{ target, placement: "left-start" }}
        />
    );
    return <div ref={target}>{toggle(label)}</div>;
}

function ThirdPartyToggles({
    queryRef,
}: {
    queryRef: urlParams_thirdPartyToggleDefaults$key;
}) {
    const [
        showThirdPartyErrors,
        showThirdPartyWarnings,
        setShowThirdPartyErrors,
        setShowThirdPartyWarnings,
    ] = useThirdPartyToggles(queryRef);
    const props: {
        name: TooltipName;
        enabled: boolean;
        onToggle: (v: boolean) => void;
    }[] = [
        {
            name: "Third Party Errors",
            enabled: showThirdPartyErrors,
            onToggle: setShowThirdPartyErrors,
        },
        {
            name: "Third Party Warnings",
            enabled: showThirdPartyWarnings,
            onToggle: setShowThirdPartyWarnings,
        },
    ];
    return (
        <>
            {props.map(({ name, enabled, onToggle }, index) => {
                return (
                    <ToggleWithTooltip
                        name={name}
                        key={index}
                        toggle={(label) => (
                            <Toggle
                                label={label}
                                enabled={enabled}
                                onToggle={onToggle}
                            />
                        )}
                    />
                );
            })}
        </>
    );
}
