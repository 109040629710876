/**
 * @generated SignedSource<<fcbc1362516fdd1d0b53ffba5cbc7598>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userJourneys_userPreferences$data = {
  readonly preferences: {
    readonly userJourneyGroups: ReadonlyArray<{
      readonly groupID: number;
      readonly hidden: boolean;
      readonly name: string;
      readonly ujIDs: ReadonlyArray<number>;
    }>;
  };
  readonly " $fragmentType": "userJourneys_userPreferences";
};
export type userJourneys_userPreferences$key = {
  readonly " $data"?: userJourneys_userPreferences$data;
  readonly " $fragmentSpreads": FragmentRefs<"userJourneys_userPreferences">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userJourneys_userPreferences",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPreferences",
      "kind": "LinkedField",
      "name": "preferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserPreferencesUserJourneyGroup",
          "kind": "LinkedField",
          "name": "userJourneyGroups",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "groupID",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidden",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ujIDs",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "2b499cd220154c459723b65c3d7cf531";

export default node;
