import {mapStackTrace} from "sourcemapped-stacktrace";
import {commitMutation, useRelayEnvironment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {IEnvironment} from "relay-runtime";
import {ErrorInfo, useCallback} from "react";

type ErrorProps = {
    subject: string;
    error?: any;
    errorInfo?: ErrorInfo;
};

function callMutation(
    environment: IEnvironment,
    props: ErrorProps,
    stack: string[],
    componentStack?: string[]
) {
    let message = undefined;
    if (props.error instanceof Error) {
        message = `${props.error.name}: ${props.error.message}`;
    } else if (typeof props.error === "string") {
        message = props.error;
    } else if (props.error) {
        // Many things can't be easily stringified, so just try.
        try {
            message = JSON.stringify(props.error);
        } catch (e) {
            message = `${props.error}`;
        }
    }

    if (message) {
        message = `${props.subject}: ${message}`;
    } else {
        message = props.subject;
    }

    commitMutation(environment, {
        mutation: graphql`
            mutation loggingLogErrorMutation(
                $url: String!
                $message: String!
                $stack: String
                $componentStack: String
            ) {
                logError(
                    url: $url
                    message: $message
                    stack: $stack
                    componentStack: $componentStack
                )
            }
        `,
        variables: {
            url: window.location.href,
            message,
            stack: stack.join("\n"),
            componentStack: componentStack?.join("\n"),
        },
    });
}

export function logError(environment: IEnvironment, props: ErrorProps) {
    mapStackTrace(props.error?.stack ?? new Error().stack, (stack) => {
        if (props.errorInfo?.componentStack) {
            mapStackTrace(props.errorInfo.componentStack, (componentStack) => {
                callMutation(environment, props, stack, componentStack);
            });
        } else {
            callMutation(environment, props, stack);
        }
    });
}

export function useLogError() {
    const environment = useRelayEnvironment();

    return useCallback(
        (props: ErrorProps) => {
            logError(environment, props);
        },
        [environment]
    );
}
