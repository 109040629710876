/**
 * @generated SignedSource<<efa101c3aa5dd05ec296f53e230cefb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type loadingState_loadTotalActiveQueriesQuery$variables = Record<PropertyKey, never>;
export type loadingState_loadTotalActiveQueriesQuery$data = {
  readonly __typename: string;
  readonly localState: {
    readonly queries: {
      readonly totalActiveQueries: number;
    };
  } | null;
};
export type loadingState_loadTotalActiveQueriesQuery = {
  response: loadingState_loadTotalActiveQueriesQuery$data;
  variables: loadingState_loadTotalActiveQueriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LocalState",
        "kind": "LinkedField",
        "name": "localState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Queries",
            "kind": "LinkedField",
            "name": "queries",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalActiveQueries",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "loadingState_loadTotalActiveQueriesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "loadingState_loadTotalActiveQueriesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5a246b219e4effb89e71b4fb942e20aa",
    "id": null,
    "metadata": {},
    "name": "loadingState_loadTotalActiveQueriesQuery",
    "operationKind": "query",
    "text": "query loadingState_loadTotalActiveQueriesQuery {\n  __typename\n}\n"
  }
};
})();

(node as any).hash = "b972fde51e867405a4278826ace1f2ea";

export default node;
