import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { loadingState_loadTotalActiveQueriesQuery } from "./__generated__/loadingState_loadTotalActiveQueriesQuery.graphql";

export function useLoadingDataStatus(): Boolean {
    const localStateResponse =
        useLazyLoadQuery<loadingState_loadTotalActiveQueriesQuery>(
            graphql`
                query loadingState_loadTotalActiveQueriesQuery {
                    # Add __typename to make relay compiler allow us to query only local state.
                    ... on Query {
                        __typename
                    }
                    localState {
                        queries {
                            totalActiveQueries
                        }
                    }
                }
            `,
            {}
        );

    let loadingDataStatus = false;
    if (
        localStateResponse.localState &&
        localStateResponse.localState.queries
    ) {
        loadingDataStatus =
            localStateResponse.localState.queries.totalActiveQueries > 0;
    }
    return loadingDataStatus;
}
