import { RecordProxy, RecordSourceProxy } from "relay-runtime";

let localStateID = 0;

export function getLocalState(store: RecordSourceProxy): RecordProxy {
    const root = store.getRoot();
    let localState = root.getLinkedRecord("localState");
    if (localState === undefined || localState === null) {
        localState = store.create(`localState:${localStateID++}`, "LocalState");
        root.setLinkedRecord(localState, "localState");
    }
    return localState;
}
