import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect } from "react";
import { PreloadedQuery } from "react-relay";
import {
    parseWithMicroseconds,
    TimeRange,
    timeRangeToString,
} from "../../lib/dateUtils";
import { useStrictURLTimerange } from "../../lib/urlParams";
import {
    UJDeliveryTimeGraphQuery,
    UJDeliveryTimeGraphQuery$data,
} from "./__generated__/UJDeliveryTimeGraphQuery.graphql";
import {
    addAnalyticsVisitorsSeries,
    addPerSampleSeries,
    configureLegend,
    getChart,
    getChartItem,
    getSeries,
    getXAxis,
    getYAxis,
    makeMarkings,
    snapCursorToSeries,
    updateAnalyticsVisitorsSeries,
    updateXAxisBounds,
    useConfigureLegendLayoutEffect,
    useEventHandlers,
    useExportChartFilename,
    useRoot,
} from "./amChartsUtils";
import {
    DataPoint,
    getDataPoint,
    getSmoothedSeries,
    isPlottedDataPoint,
    PlottedDataPoint,
    usePreloadedGraphQueries,
    useSamples,
} from "./graphUtils";
import { UJGraph } from "./UJGraph";
import { Analytics } from "../../lib/analytics";

function setYAxisMax(chart: am5xy.XYChart) {
    const yAxis = chart.yAxes.getIndex(
        0
    )! as am5xy.ValueAxis<am5xy.AxisRendererY>;

    // This is the actual max computed by amCharts. It's undefined on the first two passes when
    // the graph loads.
    const yAxisMax = yAxis.getPrivate("max");

    if (yAxisMax !== undefined) {
        const cutoffSeries = getSeries(chart, "Cutoff");
        if (cutoffSeries.data.length > 0 && !cutoffSeries.isHidden()) {
            const maxCutoff = Math.max(
                ...cutoffSeries.data.values
                    .map((dataPoint) => (dataPoint as DataPoint).value)
                    .filter((value): value is number => value !== null)
            );

            // This overrides the computed max. AmCharts may adjust it.
            yAxis.set("max", Math.max(yAxisMax, maxCutoff! * 1.25));
        } else {
            yAxis.remove("max");
        }
    }
}

export function UJDeliveryTimeGraph(props: {
    queryRefs: [
        PreloadedQuery<UJDeliveryTimeGraphQuery>,
        PreloadedQuery<UJDeliveryTimeGraphQuery>
    ];
    analytics: Analytics;
}) {
    const [urlTimeRange, setURLTimeRange] = useStrictURLTimerange();
    const urlTimeRangeString = timeRangeToString(urlTimeRange);

    const [staticAPIResponse, volatileAPIResponse] = usePreloadedGraphQueries(
        graphql`
            query UJDeliveryTimeGraphQuery(
                $ujID: Int!
                $timeRange: LimitedTimeRangeInput!
                $backup: Boolean!
            ) {
                ...amChartsUtils_useRoot
                ...graphUtils_samples
                userJourney(ujId: $ujID) {
                    samples(
                        timeRange: $timeRange
                        pmeOn: false
                        backup: $backup
                    ) {
                        pageDeliveryTotal
                    }
                    cutoffs(timeRange: $timeRange) {
                        start
                        value
                    }
                }
            }
        `,
        props.queryRefs
    );

    const { preparedSamples, groupedSamples, versions } = useSamples(
        staticAPIResponse,
        volatileAPIResponse
    );
    const allSamples = [
        ...staticAPIResponse.userJourney.samples,
        ...volatileAPIResponse.userJourney.samples,
    ];
    // Filter out samples with missing delivery time. If the user switches to the step graph,
    // any new samples are added to the store with no delivery time, because the step query
    // doesn't fetch it.
    //
    // The unusual end-of-iteration test is to spot the end of an array whose length is changing.
    for (let index = 0; allSamples[index] !== undefined; index++) {
        if (allSamples[index].pageDeliveryTotal === undefined) {
            allSamples.splice(index, 1);
            preparedSamples.splice(index, 1);
            // Now all the indexes after this one are shifted to the left,
            // so we have to take a step back to not skip the next item.
            index--;
        }
    }

    const allCutoffs = [
        ...staticAPIResponse.userJourney.cutoffs,
        ...volatileAPIResponse.userJourney.cutoffs,
    ];

    const deliveryTimeData: DataPoint[] = preparedSamples.map((sample, index) =>
        getDataPoint(sample, allSamples[index].pageDeliveryTotal)
    );

    const rootRef = useRoot(staticAPIResponse, 3, (root) => {
        const chart = getChart(root);
        const xAxis = getXAxis(chart);
        const yAxis = getYAxis(chart);

        // This is the main series so make it more prominent by using thicker line.
        const series = addPerSampleSeries(root, "Delivery Time", "#00FF00", 2);
        snapCursorToSeries(chart, [series]);

        const fillBetweenSeries = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "Fill Between",
                valueYField: "value",
                valueXField: "timestamp",
                openValueYField: "cutoff",
                fill: am5.color("#F2AAC8"),
                xAxis,
                yAxis,
            })
        );
        fillBetweenSeries.fills.template.setAll({
            fillOpacity: 0.5,
            visible: true,
        });

        chart.series.push(
            am5xy.SmoothedXLineSeries.new(root, {
                name: "Average Delivery Time",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                valueXField: "timestamp",
                stroke: am5.color("#0000FF"),
                fill: am5.color("#0000FF"), // otherwise the legend shows weird colours
                connect: false,
                // We must set connect: false to get gaps, but then amCharts will also add
                // gaps based on the baseInterval of the axis. So make this huge to stop it.
                autoGapCount: 100 * 5 * 60,
                tension: 0,
            })
        );
        chart.series.push(
            am5xy.StepLineSeries.new(root, {
                name: "Cutoff",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                valueXField: "timestamp",
                stroke: am5.color("#800080"),
                fill: am5.color("#800080"), // otherwise the legend shows weird colours
            })
        );
        addAnalyticsVisitorsSeries(chart);

        const legend = getChartItem(root, "LeftLegend");
        legend.events.on("click", () => {
            const cutoffSeries = getSeries(chart, "Cutoff");
            const cutoffFillBetween = getSeries(chart, "Fill Between");
            const deliveryTimeSeries = getSeries(chart, "Delivery Time");
            // Only show the fill between, if both the cutoff and the delivery time series
            // are not hidden (can't use .isShowing(), it means is it animating to show).
            if (!cutoffSeries.isHidden() && !deliveryTimeSeries.isHidden()) {
                // noinspection JSIgnoredPromiseFromCall
                cutoffFillBetween.show();
            } else {
                // noinspection JSIgnoredPromiseFromCall
                cutoffFillBetween.hide();
            }

            setYAxisMax(chart);
        });
    });

    // This runs each time something relevant (e.g. the time range) changes.
    useEffect(() => {
        // The chart is ready, so populate it with data.
        const root = rootRef.current!;
        const chart = getChart(root);
        const xAxis = getXAxis(chart);
        const excludeSeriesNames = [];

        updateXAxisBounds(getXAxis(chart), urlTimeRange);

        const cutoffSeries = getSeries(chart, "Cutoff");
        const fillBetweenSeries = getSeries(chart, "Fill Between");
        if (shouldShowCutoff(allCutoffs)) {
            addCutoffDataPoints(
                urlTimeRange,
                allCutoffs,
                deliveryTimeData,
                cutoffSeries,
                fillBetweenSeries
            );
        } else {
            cutoffSeries.data.setAll([]);
            fillBetweenSeries.data.setAll([]);
            // Don't show the cutoff or fill between series in the legend, because there is no
            // cutoff set for this time period.
            excludeSeriesNames.push("Cutoff");
        }

        configureLegend(chart, excludeSeriesNames);
        updateAnalyticsVisitorsSeries(chart, props.analytics);

        getSeries(chart, "Delivery Time").data.setAll(deliveryTimeData);

        const loessData = getSmoothedSeries(deliveryTimeData);
        getSeries(chart, "Average Delivery Time").data.setAll(loessData);

        makeMarkings(xAxis, groupedSamples, versions);

        setYAxisMax(chart);
        // todo these deps are a nonsense. e.g. deliveryTimeData is generated fresh everytime,
        //  so we end up calling this useEffect everytime.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        preparedSamples,
        groupedSamples,
        urlTimeRangeString,
        setURLTimeRange,
        deliveryTimeData,
        allCutoffs,
        props.analytics,
    ]);

    useEventHandlers(rootRef);
    useConfigureLegendLayoutEffect(rootRef);
    useExportChartFilename(rootRef, "User Journey Delivery Time Graph");

    return <UJGraph />;
}

type ParsedCutoff = {
    start: number;
    end: number;
    value: number | null;
};

type CutoffsResponse = UJDeliveryTimeGraphQuery$data["userJourney"]["cutoffs"];

function parseCutoffs(
    timeRange: TimeRange,
    apiCutoffs: CutoffsResponse
): ParsedCutoff[] {
    const cutoffs: ParsedCutoff[] = [];
    for (let i = 0; i < apiCutoffs.length; i++) {
        let end;
        if (i === apiCutoffs.length - 1) {
            end = timeRange.end;
        } else {
            end = parseWithMicroseconds(apiCutoffs[i + 1].start);
        }
        cutoffs.push({
            start: parseWithMicroseconds(apiCutoffs[i].start).toMillis(),
            end: end.toMillis(),
            value: apiCutoffs[i].value,
        });
    }

    return cutoffs;
}

type FillDataPoint = DataPoint & {
    readonly cutoff: number | null;
};

function getFillDataPoints(
    deliveryTimeData: DataPoint[],
    cutoffs: ParsedCutoff[]
): FillDataPoint[] {
    const fillDataPoints: FillDataPoint[] = [];
    let cutoffIndex = 0;
    let isInGap = false;
    for (const [i, sampleDataPoint] of deliveryTimeData.entries()) {
        while (
            sampleDataPoint.timestamp >= cutoffs[cutoffIndex].end &&
            cutoffIndex < cutoffs.length - 1
        ) {
            if (i === 0) {
                // Follow cutoff changes before the first sample, because it wants to
                // connect the first sample to the first cutoff data point.
                fillDataPoints.push({
                    timestamp: cutoffs[cutoffIndex].end,
                    value: cutoffs[cutoffIndex].value,
                    cutoff: cutoffs[cutoffIndex].value,
                });
                fillDataPoints.push({
                    timestamp: cutoffs[cutoffIndex + 1].start,
                    value: cutoffs[cutoffIndex + 1].value,
                    cutoff: cutoffs[cutoffIndex + 1].value,
                });
            }

            if (
                cutoffs[cutoffIndex].value === null &&
                cutoffs[cutoffIndex + 1].value !== null &&
                isPlottedDataPoint(sampleDataPoint) &&
                sampleDataPoint.value >= cutoffs[cutoffIndex + 1].value!
            ) {
                // The UJ now has a cutoff, and the first sample is slow. Force it to start
                // the fill vertically.
                fillDataPoints.push({
                    timestamp: sampleDataPoint.timestamp,
                    value: cutoffs[cutoffIndex + 1].value,
                    cutoff: cutoffs[cutoffIndex + 1].value,
                });
            }

            cutoffIndex++;
        }
        const cutoffValue = cutoffs[cutoffIndex].value;

        if (!isPlottedDataPoint(sampleDataPoint)) {
            // We shouldn't plot the current sample, but the previous one was plotted, so we are
            // starting a gap in the graph, in case the previous sample was slow, add an extra
            // data point using the previous timestamp. If the previous sample was slow, it will
            // draw vertically down to the cutoff instead of down diagonally to meet the next point
            // of this trace.
            if (!isInGap && fillDataPoints.length > 0) {
                fillDataPoints.push({
                    timestamp:
                        fillDataPoints[fillDataPoints.length - 1].timestamp,
                    value: cutoffValue,
                    cutoff: cutoffValue,
                });
                isInGap = true;
            }
        } else {
            const currentSampleIsSlowerThanCutoff =
                cutoffValue !== null && sampleDataPoint.value >= cutoffValue;

            if (currentSampleIsSlowerThanCutoff) {
                // The delivery time is above the cutoff.
                // If we are starting to show a cutoff threshold fill
                //   at the start of the graph,
                //   or right after a gap in the graph
                // add an extra data point to start drawing the fill with a sharp vertical start
                // instead of a diagonal line to the previous data point.
                if (i === 0 || isInGap) {
                    // Make it start the fill vertically.
                    fillDataPoints.push({
                        timestamp: sampleDataPoint.timestamp,
                        value: cutoffValue,
                        cutoff: cutoffValue,
                    });
                }
                fillDataPoints.push({
                    timestamp: sampleDataPoint.timestamp,
                    value: sampleDataPoint.value,
                    cutoff: cutoffValue,
                });
            } else {
                // No delivery time, or no cutoff, or delivery time below cutoff. Follow the
                // cutoff so there's no gap between the value and the cutoff to fill.
                fillDataPoints.push({
                    timestamp: sampleDataPoint.timestamp,
                    value: cutoffValue,
                    cutoff: cutoffValue,
                });
            }

            if (i < deliveryTimeData.length - 1) {
                const nextDataPoint = deliveryTimeData[i + 1];
                if (cutoffValue != null && isPlottedDataPoint(nextDataPoint)) {
                    // We are going to plot the current sample
                    // If the current sample is on the other side of the cutoff threshold,
                    // then the previous sample, we need to add a data point at the time when
                    // the graph intersects the cutoff threshold. So we track the delivery time
                    // trace accurately.
                    const intersectTimestamp = getCutoffIntersectionTimestamp(
                        sampleDataPoint,
                        nextDataPoint,
                        cutoffValue
                    );
                    if (intersectTimestamp) {
                        fillDataPoints.push({
                            timestamp: intersectTimestamp,
                            value: cutoffValue,
                            cutoff: cutoffValue,
                        });
                    }
                }
            } else {
                // We are at the end of the graph, if we are showing a fill, then make it finish
                // the fill vertically, by adding an extra datapoint with the same timestamp right
                // on the cutoff value.
                if (currentSampleIsSlowerThanCutoff) {
                    fillDataPoints.push({
                        timestamp: sampleDataPoint.timestamp,
                        value: cutoffValue,
                        cutoff: cutoffValue,
                    });
                }
            }
            isInGap = false;
        }
    }

    // It wants to connect the last fill between data point to the last cutoff data point.
    // That's OK if the cutoff doesn't change. But if it does change we need to force it to
    // follow it.
    fillDataPoints.push({
        timestamp: cutoffs[cutoffIndex].end,
        value: cutoffs[cutoffIndex].value,
        cutoff: cutoffs[cutoffIndex].value,
    });
    for (const cutoff of cutoffs.slice(cutoffIndex + 1)) {
        fillDataPoints.push({
            timestamp: cutoff.start,
            value: cutoff.value,
            cutoff: cutoff.value,
        });
        fillDataPoints.push({
            timestamp: cutoff.end,
            value: cutoff.value,
            cutoff: cutoff.value,
        });
    }

    return fillDataPoints;
}

function shouldShowCutoff(apiCutoffs: CutoffsResponse) {
    return apiCutoffs.some((cutoff) => cutoff.value);
}

function addCutoffDataPoints(
    timeRange: TimeRange,
    apiCutoffs: CutoffsResponse,
    deliveryTimeData: DataPoint[],
    cutoffSeries: am5xy.XYSeries,
    fillBetweenSeries: am5xy.XYSeries
) {
    const cutoffs = parseCutoffs(timeRange, apiCutoffs);
    const cutoffDataPoints = cutoffs.map((cutoff) => ({
        timestamp: cutoff.start,
        value: cutoff.value,
    }));
    cutoffDataPoints.push({
        timestamp: cutoffs[cutoffs.length - 1].end,
        value: cutoffs[cutoffs.length - 1].value,
    });

    // Add the cutoff series itself.
    cutoffSeries.data.setAll(cutoffDataPoints);

    // Fill between the delivery time and the cutoff. This trace is mostly the same as the delivery
    // time, but doesn't go below the cutoff. The trace itself is not plotted, only the fill below
    // it.
    const fillDataPoints = getFillDataPoints(deliveryTimeData, cutoffs);
    fillBetweenSeries.data.setAll(fillDataPoints);
}

/**
 * see maths.py:intersection
 *
 * We have two samples on either side of the cutoff.
 * Find out at what time a straight line between them intersects the cutoff value.
 */
function getCutoffIntersectionTimestamp(
    A: PlottedDataPoint,
    B: PlottedDataPoint,
    cutoff: number
): number | null {
    if (
        (A.value > cutoff && B.value < cutoff) ||
        (A.value < cutoff && B.value > cutoff)
    ) {
        const slope = (B.value - A.value) / (B.timestamp - A.timestamp);
        return A.timestamp + (cutoff - A.value) / slope;
    } else {
        return null;
    }
}
