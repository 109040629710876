import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { faInfo } from "@fortawesome/free-solid-svg-icons/faInfo";
import { ReactNode, useCallback, useRef, useState } from "react";
import { Overlay, OverlayProps, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./TextWithTooltip.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTimeout } from "../lib/hooks";

export type TooltipName =
    | "PME"
    | "Third Party Warnings"
    | "Third Party Errors"
    | "Timeline Navigation"
    | "Use Local Time Zone"
    | "UJ Groups"
    | "Dynamic Data Live Status";
const TOOLTIP_CONTENT: { [key in TooltipName]: ReactNode } = {
    PME: (
        <>
            <p>
                A PME (Planned Maintenance Exclusion) is a period of time during
                which data can be filtered from reports.
            </p>

            <p>
                A PME window is placed on the User Journey to disable alerting
                for a specific period of time, such as during upgrades or
                maintenance to a site. It is shown in grey on graphs.
            </p>

            <p>
                Monitoring of journeys against your site does not stop during
                PMEs, just alerting and standard reporting, so the full data is
                available if needed and can be viewed by using this toggle.
            </p>
        </>
    ),
    "Third Party Warnings": (
        <>
            <p>
                Warnings due to components on third party hosts that do not
                impact your users' experience are of less importance than
                warnings on hosts that do impact your users' experience. This
                toggle allows you to show/hide these less important warnings.
            </p>

            <p>
                You can configure third party hosts in the Host Settings. Hosts
                configured in the 'Third Party Hosts: No Customer Impact' group
                will show as third party warnings.
            </p>
        </>
    ),
    "Third Party Errors": (
        <>
            <p>
                Errors due to components on third party hosts that do not impact
                your users' experience are of less importance than errors on
                hosts that do impact your users' experience. This toggle allows
                you to show/hide these less important errors.
            </p>

            <p>
                You can configure third party hosts in the Host Settings. Hosts
                configured in the 'Third Party Hosts: No Customer Impact' group
                will show as third party errors.
            </p>
        </>
    ),
    "Timeline Navigation": (
        <>
            There are several ways to navigate the timeline:
            <ul>
                <li>
                    By clicking the time range button and
                    <ul>
                        <li>
                            clicking one of the available quick selections; or
                        </li>
                        <li>
                            picking a custom time range using calendar widgets.
                        </li>
                    </ul>
                </li>
                <li>
                    By clicking one of the navigation arrows around the time
                    range button.
                </li>
                <li>By clicking-and-dragging on a graph to zoom in.</li>
                <li>
                    By holding Ctrl and clicking-and-dragging on a graph to pan.
                </li>
            </ul>
        </>
    ),
    "Use Local Time Zone": (
        <>
            <p>Show times in your device's time zone.</p>
            <p>
                The local time zone feature is supported in all pages where you
                see this toggle. Support for more pages is being added soon.
            </p>
        </>
    ),
    "UJ Groups": (
        <>
            <p>
                User Journeys can be 'grouped' to make it easier for users with
                a high number of journeys to see the information that they need.
            </p>

            <p>
                The ability to group journeys and select which groups are
                visible to which users by default, makes it easier to quickly
                find relevant information.
            </p>

            <p>
                Groups which are not visible to users as default can still be
                viewed by using the 'Show' function next to the group name.
            </p>

            <p>
                Groups are set up by Tribe Support. Please contact{" "}
                <SupportEmailLink /> if you need to configure groups for your
                organisation.
            </p>

            <p>
                User preferences for which groups are visible by default as well
                as the order in which groups are displayed can be set in the{" "}
                <a href="/portal2/setting/userPreferences">Preferences</a> page.
            </p>
        </>
    ),
    "Dynamic Data Live Status": (
        <>
            <p>
                These tiles show dynamic targets which currently have problems.
                At most 5 tiles are shown, though more targets may have
                problems.
            </p>
            <p>
                A <span className={styles.errorText}>red</span> tile indicates
                the target is in error.
            </p>
            <p>
                A <span className={styles.slowText}>purple</span> tile indicates
                the target is slow.
            </p>
            <p>
                Click on the tile to see the most recent sample to test the
                target.
            </p>
        </>
    ),
};

function SupportEmailLink() {
    return (
        <>
            <a href="mailto:support@thinktribe.com">Tribe Support</a> on 01227
            768276
        </>
    );
}

type TextWithTooltipProps = {
    name: TooltipName;
    label?: string;
    overlayProps?: Partial<Pick<OverlayProps, "placement" | "target">>;
};
export function TextWithTooltip({
    name,
    label,
    overlayProps,
}: TextWithTooltipProps): ReactNode {
    const [isShown, setShown] = useState(false);
    const defaultTarget = useRef(null);
    const [setHideTimeout, resetHideTimeout] = useTimeout();

    const show = useCallback(() => {
        resetHideTimeout();
        setShown(true);
    }, [resetHideTimeout]);
    const hide = useCallback(() => {
        resetHideTimeout();
        setHideTimeout(() => setShown(false), 150);
    }, [resetHideTimeout, setHideTimeout]);

    // Fall back to the name if explicit label is not given.
    label ??= name;

    return (
        <>
            <span
                ref={defaultTarget}
                className={styles.textWithTooltip}
                aria-label={label === "" ? name : undefined}
                onMouseEnter={show}
                onMouseLeave={hide}
            >
                <span>{label}</span>
                <span className={"fa-stack " + styles.icon}>
                    <FontAwesomeIcon
                        icon={faCircle}
                        className={"fa-stack-1x"}
                    />
                    <FontAwesomeIcon
                        icon={faInfo}
                        className={"fa-stack-1x fa-inverse"}
                        transform="shrink-3"
                    />
                </span>
            </span>
            <Overlay
                show={isShown}
                placement={overlayProps?.placement ?? "bottom-end"}
                target={overlayProps?.target ?? defaultTarget}
            >
                <Popover
                    // Keep the popover open when the mouse is over it.
                    onMouseEnter={show}
                    onMouseLeave={() => setShown(false)}
                    className={styles.popover}
                >
                    <Popover.Header>{name}</Popover.Header>
                    <Popover.Body>
                        {TOOLTIP_CONTENT[name]}
                        <div className={styles.fullGlossaryLink}>
                            <Link to="/portal2/help/glossary" target="_blank">
                                View full glossary
                            </Link>
                        </div>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
}
