/**
 * @generated SignedSource<<6c75a779e71c8b7ac6bd086360db993c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UJMenu_user$data = {
  readonly permissions: {
    readonly allow: ReadonlyArray<string>;
    readonly deny: ReadonlyArray<string>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"userJourneys_userPreferences">;
  readonly " $fragmentType": "UJMenu_user";
};
export type UJMenu_user$key = {
  readonly " $data"?: UJMenu_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UJMenu_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UJMenu_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Permissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allow",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deny",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "userJourneys_userPreferences"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "354a6722f76788918b62ed39751b695b";

export default node;
