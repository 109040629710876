import {
    HeatTiles_heatTiles$data,
    HeatTiles_heatTiles$key,
    SampleStatus,
} from "./__generated__/HeatTiles_heatTiles.graphql";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import takeRightWhile from "lodash/takeRightWhile";
import { SampleLink } from "../../lib/samples";
import { DateTime } from "luxon";
import styles from "./HeatTiles.module.scss";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { TextWithTooltip } from "../../components/TextWithTooltip";
import { RecentDateTime } from "../../lib/dateUtils";

type HeatTileFragmentUserJourney =
    HeatTiles_heatTiles$data["userJourneys"][number];

type Sample = NonNullable<
    NonNullable<
        NonNullable<HeatTiles_heatTiles$data["localState"]>["samples"]
    >[number]["recent"]
>[number] & { ujID: number; helperTarget: string };

type HeatTileUserJourney = HeatTileFragmentUserJourney & {
    helper: NonNullable<HeatTileFragmentUserJourney["helper"]>;
};

const HEAT_TILE_STATUSES = ["ERROR", "SLOW"] as const;
type HeatTileStatus = typeof HEAT_TILE_STATUSES[number];
const HEAT_TILES_SHOW_STATUSES: readonly SampleStatus[] = [
    ...HEAT_TILE_STATUSES,
];

type HeatTileSample = Sample & { status: HeatTileStatus };

const HEAT_TILES_SHOW_COUNT = 5;

function isHeatTileUserJourney(
    uj: HeatTileFragmentUserJourney
): uj is HeatTileUserJourney {
    return !!uj.helper;
}

function hasHelperTarget<T extends { helperTarget?: string | null }>(
    sample: T
): sample is T & { helperTarget: string } {
    return !!sample.helperTarget;
}

export function HeatTiles({
    queryRef,
    timeSpanHours,
    endTime,
}: {
    queryRef: HeatTiles_heatTiles$key;
    timeSpanHours: number;
    endTime: DateTime;
}) {
    const data = useFragment(
        graphql`
            fragment HeatTiles_heatTiles on Query
            @argumentDefinitions(backup: { type: "Boolean!" }) {
                userJourneys {
                    ujID
                    helper {
                        testLabel
                    }
                }
                localState {
                    samples(backup: $backup) {
                        ujID
                        recent {
                            timestamp
                            status
                            helperTarget
                        }
                    }
                }
            }
        `,
        queryRef
    );
    const heatTileUserJourneys = data.userJourneys.filter(
        isHeatTileUserJourney
    );
    const startTimestamp = endTime.minus({ hour: timeSpanHours }).toSeconds();

    if (!data.localState?.samples || !heatTileUserJourneys) {
        return <></>;
    }

    const ujIDToTestLabel: { [key: number]: string } = {};
    heatTileUserJourneys.forEach(({ ujID, helper: { testLabel } }) => {
        ujIDToTestLabel[ujID] = testLabel;
    });

    const samplesByLabel: { [key: string]: Sample[] } = {};
    data.localState.samples.forEach(({ ujID, recent }) => {
        const label = ujIDToTestLabel[ujID];
        if (!label || !recent) {
            return;
        }

        recent.forEach((recentSample) => {
            if (hasHelperTarget(recentSample)) {
                if (recentSample.timestamp <= startTimestamp) {
                    // Don't add problems outside of timeframe
                    return;
                }

                if (samplesByLabel[label] === undefined) {
                    samplesByLabel[label] = [];
                }

                samplesByLabel[label].push({
                    ujID,
                    ...recentSample,
                });
            }
        });
    });

    const sections = Object.keys(samplesByLabel)
        .sort()
        .map((label) => (
            <HeatTilesForLabel
                key={label}
                label={label}
                samples={samplesByLabel[label]}
                timeSpanHours={timeSpanHours}
            />
        ));

    return <>{sections}</>;
}

type HeatTilesProps = {
    label: string;
    samples: Sample[];
    timeSpanHours: number;
};

// Typescript can't do destructured type guards, so we need these aliases to help it.
type TargetSamplePair = [string, Sample];
type TargetHeatTileSamplePair = [string, HeatTileSample];

function isHeatTileSamplePair(
    pair: TargetSamplePair
): pair is TargetHeatTileSamplePair {
    return HEAT_TILES_SHOW_STATUSES.includes(pair[1].status);
}

function HeatTilesForLabel(props: HeatTilesProps) {
    const samplesByTarget = groupBy(
        props.samples,
        (sample) => sample.helperTarget
    );

    // Order each target's samples by their monDateTime.
    Object.keys(samplesByTarget).forEach((target) => {
        samplesByTarget[target] = orderBy(
            samplesByTarget[target],
            (s) => s.timestamp
        );
    });

    let tilesData = Object.entries(samplesByTarget)
        // Collect the latest sample for each target and restrict to those which are error or slow.
        .map(([target, samples]): [string, Sample] => [
            target,
            samples[samples.length - 1],
        ])
        .filter(isHeatTileSamplePair)
        // Figure out the problem start time from the earlier samples for the target.
        .map(([target, latestSample]) => {
            const samples = samplesByTarget[target];
            const samplesInTheLatestProblem = takeRightWhile(
                samples,
                (sample) => {
                    return HEAT_TILES_SHOW_STATUSES.includes(sample.status);
                }
            );

            let problemStartTime;
            if (samples.length === samplesInTheLatestProblem.length) {
                // Problem spans across all the samples we have. We can't tell when it started.
                problemStartTime = null;
            } else {
                problemStartTime = samplesInTheLatestProblem[0].timestamp;
            }

            return {
                problemStartTime,
                ...latestSample,
            };
        });

    const numTargetsWithProblems = tilesData.length;
    if (numTargetsWithProblems === 0) {
        return <></>;
    }

    const title = `${props.label}: ${numTargetsWithProblems} ${
        numTargetsWithProblems === 1
            ? "target has a problem"
            : "targets have a problem"
    }`;
    return (
        <section
            data-testid={`${props.label}`}
            className={`standard-section ${styles.heatTiles}`}
        >
            <div>
                <TextWithTooltip
                    name={"Dynamic Data Live Status"}
                    label={title}
                />
            </div>

            <VariableLengthHeatTiles
                fullDisplayTiles={tilesData}
                timeSpanHours={props.timeSpanHours}
            />
        </section>
    );
}

type ParsedHeatTile = {
    problemStartTime: number | null;
    ujID: number;
    readonly helperTarget: string;
    readonly status: HeatTileStatus;
    readonly timestamp: number;
};

type VariableLengthHeatTilesProps = {
    fullDisplayTiles: ParsedHeatTile[];
    timeSpanHours: number;
};

function VariableLengthHeatTiles(props: VariableLengthHeatTilesProps) {
    const [activatedShowMore, setActivatedShowMore] = useState<boolean>(false);

    let tiles = orderBy(
        props.fullDisplayTiles,
        [(s) => s.problemStartTime ?? "", (s) => s.helperTarget],
        ["desc", "asc"]
    );

    if (!activatedShowMore) {
        tiles = tiles.slice(0, HEAT_TILES_SHOW_COUNT);
    }

    function toggleShowMore() {
        setActivatedShowMore((prev) => !prev);
    }

    return (
        <div>
            <ul>
                {tiles.map(
                    ({
                        ujID,
                        status,
                        timestamp,
                        helperTarget,
                        problemStartTime,
                    }) => (
                        <HeatTile
                            key={helperTarget}
                            timeSpanHours={props.timeSpanHours}
                            ujID={ujID}
                            status={status}
                            helperTarget={helperTarget}
                            lastSeenTimestamp={timestamp}
                            problemStartTimestamp={problemStartTime}
                        />
                    )
                )}
            </ul>

            {props.fullDisplayTiles.length > 5 && (
                <div className={styles.changeTileDisplayButtonRow}>
                    <Button
                        role={"button"}
                        onClick={toggleShowMore}
                        variant={"tribe-primary"}
                    >
                        Show {activatedShowMore ? "Less" : "All"}
                    </Button>
                </div>
            )}
        </div>
    );
}

type HeatTileProps = {
    timeSpanHours: number;
    ujID: number;
    status: HeatTileStatus;
    helperTarget: string;
    lastSeenTimestamp: number;
    problemStartTimestamp: number | null;
};

function HeatTile(props: HeatTileProps) {
    // `styles[props.status]` doesn't work
    const statusStyle = props.status === "ERROR" ? styles.ERROR : styles.SLOW;
    return (
        <li className={statusStyle}>
            <SampleLink ujID={props.ujID} timestamp={props.lastSeenTimestamp}>
                <span className={styles.target}>{props.helperTarget}</span>
                <br />
                Problem started:{" "}
                {props.problemStartTimestamp ? (
                    <RecentDateTime
                        timeSpanHours={props.timeSpanHours}
                        timestamp={props.problemStartTimestamp}
                        showSeconds={true}
                    />
                ) : (
                    `more than ${props.timeSpanHours} hours ago`
                )}
                <br />
                Last seen:{" "}
                <RecentDateTime
                    timeSpanHours={props.timeSpanHours}
                    timestamp={props.lastSeenTimestamp}
                    showSeconds={true}
                />
            </SampleLink>
        </li>
    );
}
