export type UserPermissions = {
    readonly allow: ReadonlyArray<string>;
    readonly deny: ReadonlyArray<string>;
};

export function hasPermission(
    permissions: UserPermissions,
    permission: string
): boolean {
    if (permission.startsWith("!")) {
        return !hasPermission(permissions, permission.slice(1));
    } else {
        return (
            // The user is explicitly allowed
            permissions.allow.indexOf(permission) > -1 ||
            // The user has all permissions and is not denied this one.
            (permissions.allow[0] === "All" &&
                permissions.deny.indexOf(permission) === -1)
        );
    }
}

export function isForbiddenError(error: { message: string }) {
    return error.message.includes("Unauthorised Access");
}
