import { faChartLine, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { startTransition, Suspense, useEffect, useId } from "react";
import { Card, Form } from "react-bootstrap";
import Placeholder from "react-bootstrap/Placeholder";
import { GraphQLTaggedNode, useQueryLoader } from "react-relay";
import { isMoreThanTwoWeeks, timeRangeToString } from "../../lib/dateUtils";
import { useLoadingDataStatus } from "../../lib/loadingState";
import {
    GraphType,
    transitionHooks,
    URLParameters,
    useBackupToggle,
    useGraphType,
    useStrictURLTimerange,
    useURLSelectedUJID,
} from "../../lib/urlParams";
import UJDeliveryTimeGraphQueryGraphql from "./__generated__/UJDeliveryTimeGraphQuery.graphql";
import UJStepGraphQueryGraphql from "./__generated__/UJStepGraphQuery.graphql";
import { CHART_HEIGHT } from "./amChartsUtils";
import { GraphParameters, loadGraphQueries } from "./graphUtils";
import { UJDeliveryTimeGraph } from "./UJDeliveryTimeGraph";
import styles from "./UJPanel.module.scss";
import { UJStepGraph } from "./UJStepGraph";
import { useIsBackButtonNavigation } from "../../lib/backButtonNav";
import { Analytics } from "../../lib/analytics";

function GraphTypeSelect() {
    const [graphType, setGraphType] = useGraphType();
    const id = useId();
    const options: { value: GraphType; text: string }[] = [
        {
            value: "userJourney",
            text: "User Journey Delivery Time",
        },
        { value: "step", text: "Individual Step Delivery Times" },
        {
            value: "stepStacked",
            text: "User Journey Delivery Time Breakdown By Step",
        },
    ];

    return (
        <Form.Group controlId={id} className={styles.graphTypeSelector}>
            <Form.Select
                value={graphType}
                onChange={(event) => {
                    setGraphType(event.target.value as GraphType);
                }}
                className={styles.graphTypeSelect + " shadowInsteadOfBorder"}
            >
                {options.map(({ value, text }) => (
                    <option key={value} value={value}>
                        {text}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );
}

export function LoadingDataIndicator() {
    const loadingDataStatus = useLoadingDataStatus();
    return (
        <>
            {loadingDataStatus && (
                <FontAwesomeIcon
                    title={"Loading ..."}
                    icon={faSpinner}
                    className={"fa-2x fa-spin " + styles.loadingIndicator}
                />
            )}
        </>
    );
}

function useGraphQueryLoaders(
    query: GraphQLTaggedNode
): [
    any /* expressing this type is messy */,
    (parameters: GraphParameters) => void
] {
    const [staticQueryRef, loadStaticQuery] = useQueryLoader(query);
    const [volatileQueryRef, loadVolatileQuery] = useQueryLoader(query);
    return [
        [staticQueryRef, volatileQueryRef],
        (parameters: GraphParameters) => {
            loadGraphQueries(loadStaticQuery, loadVolatileQuery, parameters);
        },
    ];
}

export function UJPanel(props: { analytics: Analytics }) {
    const [graphType] = useGraphType();
    const [ujID] = useURLSelectedUJID(true);
    const [backup] = useBackupToggle();
    const [timeRange] = useStrictURLTimerange();
    const timeRangeString = timeRangeToString(timeRange);

    const [deliveryTimeQueryRefs, loadDeliveryTimeQueries] =
        useGraphQueryLoaders(UJDeliveryTimeGraphQueryGraphql);
    const [stepQueryRefs, loadStepQueries] = useGraphQueryLoaders(
        UJStepGraphQueryGraphql
    );

    function loadQueries(urlParams: URLParameters) {
        if (!isMoreThanTwoWeeks(urlParams.timeRange)) {
            const loader =
                urlParams.graphType === "userJourney"
                    ? loadDeliveryTimeQueries
                    : loadStepQueries;
            loader({
                ujID: urlParams.ujID,
                timeRange: urlParams.timeRange,
                backup: urlParams.backup,
            });
        }
    }
    const urlParams = { ujID, timeRange, backup, graphType };

    useEffect(() => {
        loadQueries(urlParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isBackButtonNavigation = useIsBackButtonNavigation();

    useEffect(() => {
        if (isBackButtonNavigation) {
            startTransition(() => {
                loadQueries(urlParams);
            });
        }
    });

    useEffect(() => {
        // This is called from inside the transition whenever a URL parameter is changed. We
        // must keep updating it, so it has the latest values for parameters which are not being
        // changed.
        transitionHooks.UJPanel_graph = (changedParams) => {
            loadQueries({ ...urlParams, ...changedParams });
        };
        return () => {
            delete transitionHooks.UJPanel_graph;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ujID, timeRangeString, backup, graphType]);

    return (
        <Card className={styles.card}>
            <Card.Body>
                <div className={"d-flex align-items-center"}>
                    <GraphTypeSelect />
                    <LoadingDataIndicator />
                </div>
                <Suspense
                    fallback={
                        <Placeholder
                            as="div"
                            animation="wave"
                            // attempt to match the height of a chart with a one line legend
                            style={{
                                height: `${CHART_HEIGHT + 40}px`,
                            }}
                            className={styles.chartPlaceholderContainer}
                        >
                            <Placeholder
                                as="div"
                                className={
                                    "d-flex justify-content-center align-items-center " +
                                    styles.chartPlaceholder
                                }
                                xs={12}
                            >
                                <FontAwesomeIcon icon={faChartLine} />
                            </Placeholder>
                        </Placeholder>
                    }
                >
                    {graphType === "userJourney" &&
                        deliveryTimeQueryRefs.every((ref: any) => ref) && (
                            <UJDeliveryTimeGraph
                                queryRefs={deliveryTimeQueryRefs}
                                analytics={props.analytics}
                            />
                        )}
                    {graphType === "step" &&
                        stepQueryRefs.every((ref: any) => ref) && (
                            <UJStepGraph
                                queryRefs={stepQueryRefs}
                                analytics={props.analytics}
                            />
                        )}
                    {graphType === "stepStacked" &&
                        stepQueryRefs.every((ref: any) => ref) && (
                            <UJStepGraph
                                queryRefs={stepQueryRefs}
                                analytics={props.analytics}
                                stacked={true}
                            />
                        )}
                </Suspense>
            </Card.Body>
        </Card>
    );
}
