import dropRightWhile from "lodash/dropRightWhile";
import dropWhile from "lodash/dropWhile";
import zip from "lodash/zip";
import styles from "./StatusBar.module.scss";
import {
    getEffectiveStatus,
    Sample,
    SampleDisplayOptions,
    SampleEffectiveStatus,
    SampleLink,
} from "../../lib/samples";

type MaxGap = number | null;

type Event = {
    start: number;
    end: number;
    effectiveStatus: SampleEffectiveStatus;
    lastSampleTimestamp: number;
};

type GapEvent = Omit<Event, "effectiveStatus" | "lastSampleTimestamp"> & {
    effectiveStatus: "gap";
};

type AnyEvent = Event | GapEvent;

type EventRectData = {
    event: Event;
    x: number;
    y: number;
    width: number;
};

// Exported for testing
export class UJDataEventFactory {
    static getEvents(
        samples: ReadonlyArray<Sample>,
        maxGap: MaxGap,
        displayOptions: SampleDisplayOptions
    ) {
        if (samples.length === 0) {
            return [];
        }

        const groups = this.groupSamples(samples, maxGap, displayOptions);
        const events: Event[] = [];
        groups.forEach((group, index) => {
            const nextGroup = groups[index + 1];
            const start = group[0].timestamp;
            const lastSample = group[group.length - 1];
            const effectiveStatus = getEffectiveStatus(
                lastSample,
                displayOptions
            );
            let end = lastSample.timestamp;

            // Adjust the end time of this event to the beginning of the next one, if available,
            // and not to far.
            if (nextGroup) {
                const nextStart = nextGroup[0].timestamp;

                if (!maxGap || nextStart - end <= maxGap) {
                    end = nextStart;
                }
            }

            events.push({
                start,
                end,
                effectiveStatus,
                lastSampleTimestamp: lastSample.timestamp,
            });
        });

        return events;
    }

    static groupSamples(
        samples: ReadonlyArray<Sample>,
        maxGap: MaxGap,
        displayOptions: SampleDisplayOptions
    ) {
        // We'll have at least one group with a single sample.
        const groups = [[samples[0]]];
        const ungroupedSamples = samples.slice(1);

        ungroupedSamples.forEach((sample) => {
            const lastGroup = groups[groups.length - 1];
            const lastSample = lastGroup[lastGroup.length - 1];

            if (this.samplesMatch(lastSample, sample, maxGap, displayOptions)) {
                lastGroup.push(sample);
            } else {
                groups.push([sample]);
            }
        });

        return groups;
    }

    static samplesMatch(
        first: Sample,
        second: Sample,
        maxGap: MaxGap,
        displayOptions: SampleDisplayOptions
    ) {
        // Samples match if both have the same effective status and gap between them is not too big.
        if (
            getEffectiveStatus(first, displayOptions) !==
            getEffectiveStatus(second, displayOptions)
        ) {
            return false;
        }

        if (maxGap) {
            return second.timestamp - first.timestamp <= maxGap;
        }

        return true;
    }
}

function buildEventRectData(
    ujID: number,
    width: number,
    data: {
        events: Event[];
        start: number;
        seconds: number;
    }
) {
    if (!(data.events.length > 0 && data.seconds > 0)) {
        return [];
    }

    const events = prepareEvents(
        data.events,
        data.start,
        data.start + data.seconds
    );

    // Preparing events may have removed all events.
    if (!(events.length > 0)) {
        return [];
    }

    const rectData: EventRectData[] = [];

    const eventPixels = calculatePixels(width, data.seconds, events);
    const eventPixelsSum = eventPixels.reduce((a, b) => a + b);

    if (eventPixelsSum > width) {
        console.warn(
            `Too many pixels on bar, wanted: ${width}, but got ${eventPixelsSum}, for journey ${ujID}`
        );
    }

    let x = 0;
    const eventsWithPixels = zip(events, eventPixels) as [AnyEvent, number][];
    for (const [event, pixels] of eventsWithPixels) {
        // We don't need to actually render any gap events.
        if (event.effectiveStatus !== "gap") {
            rectData.push({
                x,
                y: 0,
                width: pixels,
                event,
            });
        }

        x += pixels;
    }
    return rectData;
}

function insertGapEvents(events: Event[], start: number) {
    let eventsWithGaps: AnyEvent[] = [];

    // Prepend gap event, so we always have last event.
    eventsWithGaps.push({
        start: Math.min(start, events[0].start),
        end: events[0].start,
        effectiveStatus: "gap",
    });

    // Insert gap events where there are gaps between events.
    events.forEach((event) => {
        const lastEvent = eventsWithGaps[eventsWithGaps.length - 1];

        if (lastEvent.end < event.start) {
            eventsWithGaps.push({
                start: lastEvent.end,
                end: event.start,
                effectiveStatus: "gap",
            });
        }

        eventsWithGaps.push(event);
    });

    return eventsWithGaps;
}

function trimEventsToRange(events: AnyEvent[], start: number, end: number) {
    // Drop events which are completely outside the range.
    events = dropWhile(events, (event) => {
        return event.end <= start;
    });
    events = dropRightWhile(events, (event) => {
        return event.start >= end;
    });

    if (events.length > 0) {
        // Trim events to the range.
        // Technically we can draw shapes partially or even completely outside the viewport,
        // but pixel calculations won't work with those cases at the moment.
        events[0].start = Math.max(start, events[0].start);
        events[events.length - 1].end = Math.min(
            end,
            events[events.length - 1].end
        );
    }

    return events;
}

function prepareEvents(
    events: Event[],
    start: number,
    end: number
): AnyEvent[] {
    // Make a copy of events, so we don't modify the original ones. A shallow copy is okay as
    // long as we don't have nested objects.
    let prepared = insertGapEvents(
        events.map((event) => {
            return { ...event };
        }),
        start
    );
    prepared = trimEventsToRange(prepared, start, end);

    return prepared;
}

function calculatePixels(
    maximumPixels: number,
    totalBarDuration: number,
    events: AnyEvent[]
) {
    let totalEventsDuration = 0;
    let totalEventsPixels = 0;
    const pixelList = [];
    const pixelsTolerance = 2.0;
    const pixelToDurationRatio = maximumPixels / totalBarDuration;

    function inRange(a: number, b: number, tolerance: number) {
        return a >= b && b >= a - tolerance;
    }

    function roundUp(value: number) {
        // Rounds the value up.
        // Will not return correct result for a negative number, but we shouldn't get any.
        // If the value is an integer, it will return the next integer. This is fine if the value
        // is 0, because we can't have 0 px events. But for any other integer, this will
        // pointlessly inflate them. What we should have done instead is to ceil the value
        // and bump to 1 if 0. However, this is how we calculated this so far.
        return Math.trunc(value) + 1;
    }

    for (const event of events) {
        const eventDuration = event.end - event.start;
        totalEventsDuration += eventDuration;
        let eventPixels = roundUp(eventDuration * pixelToDurationRatio);
        let tempTotalPixels = totalEventsPixels + eventPixels;
        let shouldBePixels =
            (totalEventsDuration * maximumPixels) / totalBarDuration;

        if (shouldBePixels > maximumPixels) {
            shouldBePixels = maximumPixels;
        }

        if (!inRange(shouldBePixels, tempTotalPixels, pixelsTolerance)) {
            // Correct it.
            // Try stretching the last slice to fit.
            let tempNewPixels = eventPixels + shouldBePixels - tempTotalPixels;
            if (tempNewPixels >= 1) {
                eventPixels = Math.floor(tempNewPixels);
            } else {
                // If fail (will only fail if there are too many pixels) then find some to delete.
                for (let i: number = pixelList.length - 1; i >= 0; i--) {
                    // Try first to resize a previous slice a bit.
                    if (pixelList[i] > 1) {
                        const shouldRemove = roundUp(
                            tempTotalPixels - shouldBePixels
                        );
                        if (pixelList[i] - shouldRemove < 1) {
                            // Possibly reduce this to 1.
                            tempTotalPixels -= pixelList[i] - 1;
                            totalEventsPixels -= pixelList[i] - 1;
                            pixelList[i] = 1;
                        } else {
                            pixelList[i] -= shouldRemove;
                            tempTotalPixels -= shouldRemove;
                            totalEventsPixels -= shouldRemove;
                        }
                    } else if (pixelList[i] === 1 && pixelList[i - 1] === 1) {
                        // Only delete pairs of smallest slices.
                        // Make sure there is a gap of at least 2 between deletions, so it looks
                        // ok.
                        let beforeCounter = 0;
                        for (let n = i - 2; n >= 0; n--) {
                            if (pixelList[n] !== 0) {
                                beforeCounter += 1;
                            } else {
                                break;
                            }
                        }
                        // if we have a gap bigger than 2 it's ok to remove these
                        if (beforeCounter >= 2) {
                            pixelList[i] = 0;
                            pixelList[i - 1] = 0;
                            totalEventsPixels -= 2;
                            tempTotalPixels -= 2;
                        }
                    }

                    if (tempTotalPixels < shouldBePixels) {
                        break;
                    }
                }
            }
        }

        if (eventPixels > maximumPixels) {
            pixelList.push(0);
            pixelList.push(maximumPixels);
            break;
        }

        pixelList.push(eventPixels);
        totalEventsPixels += eventPixels;
    }

    return pixelList;
}

function EventRect(props: { ujID: number; data: EventRectData }) {
    const block = (
        <rect
            x={props.data.x}
            y={props.data.y}
            width={props.data.width}
            height={"100%"}
            className={props.data.event.effectiveStatus}
            data-testid={"bar-rect"}
        />
    );

    if (props.data.event.effectiveStatus !== "OK") {
        return (
            <SampleLink
                ujID={props.ujID}
                timestamp={props.data.event.lastSampleTimestamp}
            >
                {block}
            </SampleLink>
        );
    } else {
        return block;
    }
}

function createBarData(
    start: number,
    end: number,
    samples: ReadonlyArray<Sample>,
    maxGap: MaxGap,
    displayOptions: SampleDisplayOptions
) {
    // todo: Improvement idea: Repaint bars even for UJs which haven't got new samples, so they shift left as time passes.
    // todo: Improvement idea: For current bars, extend the last event to now.
    const seconds = end - start;
    return {
        seconds,
        start,
        events: UJDataEventFactory.getEvents(samples, maxGap, displayOptions),
    };
}

export function StatusBar(props: {
    ujID: number;
    start: number;
    end: number;
    samples: ReadonlyArray<Sample>;
    maxGap: MaxGap;
    displayOptions: SampleDisplayOptions;
    width: number;
}) {
    // todo: Observe comments from wallboard.js
    const data = createBarData(
        props.start,
        props.end,
        props.samples,
        props.maxGap,
        props.displayOptions
    );
    const rectData = buildEventRectData(props.ujID, props.width, data);

    return (
        <svg className={styles.statusBar} data-testid={"bar-svg"}>
            <g shapeRendering="crispEdges">
                {rectData.map((data, index) => (
                    <EventRect ujID={props.ujID} data={data} key={index} />
                ))}
            </g>
        </svg>
    );
}
