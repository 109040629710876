import styles from "./utils.module.scss";
import { ReactNode } from "react";

export function Pipe() {
    return <span className={styles.pipe}></span>;
}

type SymmetricBarProps = {
    left?: ReactNode;
    middle: ReactNode;
    right?: ReactNode;
};

export function SymmetricBar({ left, middle, right }: SymmetricBarProps) {
    return (
        <div className={styles.symmetricBar}>
            <div>{left || <></>}</div>
            <div>{middle}</div>
            <div>{right || <></>}</div>
        </div>
    );
}
