import React, {
    Dispatch,
    ReactNode,
    useContext,
    useEffect,
    useReducer,
} from "react";
import { configureLuxon } from "../lib/dateUtils";
import { SystemZone } from "luxon";

const LOCALE = "en-GB";

type ContextT = {
    useLocalTimezone: boolean;
};

type GlobalContextT = {
    globalContext: ContextT;
    setGlobalContext: Dispatch<ContextT>;
};

// We don't provide a proper default here, because the GlobalContextProvider does it.
// @ts-ignore
export const GlobalContext = React.createContext<GlobalContextT>({});

let reducer = (context: ContextT, newContext: ContextT) => {
    return { ...context, ...newContext };
};

const initialState = JSON.parse(
    localStorage.getItem("globalContext") ||
        JSON.stringify({ useLocalTimezone: false })
);

function getTimezone(globalContext: ContextT): string {
    if (globalContext.useLocalTimezone) {
        return new SystemZone().name;
    } else {
        return "Europe/London";
    }
}

export function GlobalContextProvider(props: { children: ReactNode }) {
    const [globalContext, setGlobalContext] = useReducer(reducer, initialState);

    configureLuxon(getTimezone(globalContext), LOCALE);

    useEffect(() => {
        localStorage.setItem("globalContext", JSON.stringify(globalContext));
    }, [globalContext]);

    return (
        <GlobalContext.Provider value={{ globalContext, setGlobalContext }}>
            {props.children}
        </GlobalContext.Provider>
    );
}

export function useTimezone(): string {
    const { globalContext } = useContext(GlobalContext);
    return getTimezone(globalContext);
}
