import {
    formatDateInUTC,
    getNowRange,
    getTimeRangePeriod,
    isThisYear,
    shiftTimeRange,
    TimeRange,
    getThisTimeRange,
    getLastTimeRange,
    getLastXTimeRange,
    justBeforeIfMidnight,
    midnightIfJustBefore,
} from "../lib/dateUtils";
import { useStrictURLTimerange } from "../lib/urlParams";
import { TextWithTooltip } from "./TextWithTooltip";
import styles from "./TimeRange.module.scss";
import { ReactNode, useState } from "react";
import { Modal, Button, Nav, ButtonGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCaretRight,
    faCaretLeft,
    faStepForward,
    faForward,
    faBackward,
    faMagnifyingGlassMinus,
} from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { MouseEvent } from "react";
import isEqual from "lodash/isEqual";

function FormattedDateTime({ value }: { value: DateTime }) {
    const dateFormat = isThisYear(value) ? "EEE d MMM" : "EEE d MMM yyyy";
    return (
        <>
            <span className={styles.date}>{value.toFormat(dateFormat)}</span>
            {", "}
            {value.toFormat("HH:mm")}
        </>
    );
}

function CustomTimeRangeSelector(props: { onClose: () => void }) {
    const [urlTimeRange, setUrlTimerange] = useStrictURLTimerange();
    const [startDate, setStartDate] = useState(urlTimeRange.start);
    const [endDate, setEndDate] = useState(
        justBeforeIfMidnight(urlTimeRange.end)
    );

    function handleSave() {
        setUrlTimerange({
            start: startDate,
            end: midnightIfJustBefore(endDate),
        });
        props.onClose();
    }

    function handleSetStartDate(date: Date) {
        let localDate = fromSystemZone(date);
        localDate = localDate.startOf("day");
        setStartDate(localDate);
    }

    function handleSetEndDate(date: Date) {
        let localDate = fromSystemZone(date);
        // Round to the end of the day like kibana.
        localDate = localDate.endOf("day");
        setEndDate(localDate);
    }

    function toSystemZone(dateTime: DateTime) {
        // This moves the underlying timestamp, so we get a JS Date belonging to the same date that
        // we started with.
        return dateTime
            .setZone("system", {
                keepLocalTime: true,
            })
            .toJSDate();
    }

    function fromSystemZone(date: Date) {
        // This moves the underlying timestamp back again.
        return DateTime.fromJSDate(date, {
            zone: "system",
        }).setZone("default", { keepLocalTime: true });
    }

    return (
        <>
            <Modal.Body className={styles.customTimeRangeSelector}>
                <div className={"d-flex flex-column"}>
                    <div className={styles.dateSection}>
                        From: <FormattedDateTime value={startDate} />
                    </div>
                    <DatePicker
                        selected={toSystemZone(startDate)}
                        inline
                        onChange={handleSetStartDate}
                        maxDate={toSystemZone(endDate)}
                        chooseDayAriaLabelPrefix="Choose to start"
                    />
                </div>
                <div className={"d-flex flex-column"}>
                    <div className={styles.dateSection}>
                        To: <FormattedDateTime value={endDate} />
                    </div>
                    <DatePicker
                        selected={toSystemZone(endDate)}
                        inline
                        onChange={handleSetEndDate}
                        minDate={toSystemZone(startDate)}
                        chooseDayAriaLabelPrefix="Choose to end"
                    />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="outline-tribe-secondary"
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button variant="outline-tribe-primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </>
    );
}

function QuickLink(props: {
    timeRange: TimeRange;
    onClose: () => void;
    children: ReactNode;
}) {
    const url = useNewTimeRangeURL(props.timeRange);
    const [urlTimeRange, setURLTimeRange] = useStrictURLTimerange();
    const selected = isEqual(props.timeRange, urlTimeRange);

    function handleClick(event: MouseEvent<HTMLAnchorElement>) {
        // Don't let it suspend if the user clicks a quick link, using setURLTimeRange
        // always uses startAnimation, but middle-clicking will still load the url in a new tab,
        // because it won't come to this handler.
        setURLTimeRange(props.timeRange);
        props.onClose();
        event.preventDefault();
    }

    return (
        <Link
            to={url}
            onClick={handleClick}
            className={`nav-link ${selected ? "disabled" : ""}`}
            aria-disabled={selected}
        >
            {props.children}
        </Link>
    );
}

function QuickLinkTimeRangeSelector(props: { onClose: () => void }) {
    const today = getThisTimeRange("day");
    const thisWeek = getThisTimeRange("week");
    const thisMonth = getThisTimeRange("month");

    const yesterday = getLastTimeRange("day");
    const lastWeek = getLastTimeRange("week");
    const lastMonth = getLastTimeRange("month");

    const last24Hours = getLastXTimeRange("hour", 24);
    const last7Days = getLastXTimeRange("day", 7);
    const last14Days = getLastXTimeRange("day", 14);
    const last30Days = getLastXTimeRange("day", 30);

    return (
        <>
            <Modal.Body className={styles.customTimeRangeSelector}>
                <nav className={"nav flex-column"}>
                    <QuickLink onClose={props.onClose} timeRange={today}>
                        Today
                    </QuickLink>
                    <QuickLink onClose={props.onClose} timeRange={thisWeek}>
                        This week
                    </QuickLink>
                    <QuickLink onClose={props.onClose} timeRange={thisMonth}>
                        This month (trends)
                    </QuickLink>
                </nav>
                <nav className={"nav flex-column"}>
                    <QuickLink onClose={props.onClose} timeRange={yesterday}>
                        Yesterday
                    </QuickLink>
                    <QuickLink onClose={props.onClose} timeRange={lastWeek}>
                        Last week
                    </QuickLink>
                    <QuickLink onClose={props.onClose} timeRange={lastMonth}>
                        Last month (trends)
                    </QuickLink>
                </nav>
                <nav className={"nav flex-column"}>
                    <QuickLink onClose={props.onClose} timeRange={last24Hours}>
                        Last 24 hours
                    </QuickLink>
                    <QuickLink onClose={props.onClose} timeRange={last7Days}>
                        Last 7 days
                    </QuickLink>
                    <QuickLink onClose={props.onClose} timeRange={last14Days}>
                        Last 14 days
                    </QuickLink>
                    <QuickLink onClose={props.onClose} timeRange={last30Days}>
                        Last 30 days (trends)
                    </QuickLink>
                </nav>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="outline-tribe-secondary"
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </>
    );
}

function TimeRangeSelector(props: { onClose: () => void }) {
    const [selectorType, setSelectorType] = useState("quick");

    function close() {
        props.onClose();
    }

    return (
        <Modal
            show={true}
            onHide={close}
            dialogClassName={"modal-lg " + styles.timeRangeSelector}
        >
            <Modal.Header>
                <Nav variant={"pills"}>
                    <Nav.Item>
                        <Button
                            className={
                                selectorType === "custom" ? "active" : ""
                            }
                            onClick={() => {
                                setSelectorType("custom");
                            }}
                            variant={"tribe-primary"}
                        >
                            Custom
                        </Button>
                    </Nav.Item>
                    <Nav.Item>
                        <Button
                            className={selectorType === "quick" ? "active" : ""}
                            onClick={() => {
                                setSelectorType("quick");
                            }}
                            variant={"tribe-primary"}
                        >
                            Quick Links
                        </Button>
                    </Nav.Item>
                </Nav>
            </Modal.Header>
            {selectorType === "custom" && (
                <CustomTimeRangeSelector onClose={close} />
            )}
            {selectorType === "quick" && (
                <QuickLinkTimeRangeSelector onClose={close} />
            )}
        </Modal>
    );
}

function useNewTimeRangeURL(timeRange: TimeRange) {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    searchParams.set("start", formatDateInUTC(timeRange.start));
    searchParams.set("end", formatDateInUTC(timeRange.end));
    return {
        pathname: location.pathname,
        search: searchParams.toString(),
    };
}

function TimeRangeButton(props: {
    timeRange: TimeRange;
    iconProps: FontAwesomeIconProps;
    title: string;
}) {
    const url = useNewTimeRangeURL(props.timeRange);
    const setURLTimeRange = useStrictURLTimerange()[1];

    function handleClick(event: MouseEvent<HTMLAnchorElement>) {
        setURLTimeRange(props.timeRange);
        event.preventDefault();
    }

    return (
        <Link
            role="button"
            to={url}
            onClick={handleClick}
            title={props.title}
            className={"btn btn-tribe-primary"}
        >
            <FontAwesomeIcon {...(props.iconProps as FontAwesomeIconProps)} />
        </Link>
    );
}

function getZoomOutTimeRange({ start, end }: TimeRange) {
    const middle = start
        .plus({ milliseconds: end.diff(start).milliseconds / 2 })
        .startOf("second");

    const durationInHours = end.diff(start, "hours").hours;
    if (durationInHours < 6) {
        const start = middle.minus({ hours: 3 });
        return {
            start,
            end: start.plus({ hours: 6 }),
        };
    }
    if (durationInHours < 24) {
        const start = middle.minus({ hours: 12 });
        return {
            start,
            end: start.plus({ days: 1 }),
        };
    }

    const durationInDays = end.diff(start, "days").days;
    if (durationInDays < 7) {
        const start = middle
            .minus({ days: 3 })
            .minus({ hours: 12 })
            .startOf("day");
        return { start, end: start.plus({ days: 7 }) };
    }
    if (durationInDays < 14) {
        const start = middle.minus({ days: 7 }).startOf("day");
        return { start, end: start.plus({ days: 14 }) };
    }

    // We can assume for now that the time range is exactly 2 weeks here, so zoom out to the
    // trends page.
    const newStart = middle.minus({ days: 15 }).startOf("day");
    return { start: newStart, end: newStart.plus({ months: 1 }) };
}

export function TimeRangeDisplay() {
    const [urlTimeRange] = useStrictURLTimerange();
    const [showTimeRangeSelector, setShowTimeRangeSelector] = useState(false);

    const timeRangePeriod = getTimeRangePeriod(urlTimeRange);
    const zoomOutTimeRange = getZoomOutTimeRange(urlTimeRange);

    function handleClick() {
        setShowTimeRangeSelector(true);
    }
    function hideSelector() {
        setShowTimeRangeSelector(false);
    }
    return (
        <>
            <TimeRangeButton
                iconProps={{
                    icon: faMagnifyingGlassMinus,
                    transform: "grow-3",
                }}
                title={`Zoom out to ${
                    getTimeRangePeriod(zoomOutTimeRange).period
                }`}
                timeRange={zoomOutTimeRange}
            />
            <ButtonGroup>
                <TimeRangeButton
                    iconProps={{
                        icon: faBackward,
                        transform: "grow-3",
                    }}
                    title={"Go back in time " + timeRangePeriod.period}
                    timeRange={shiftTimeRange(urlTimeRange, "back", "full")}
                />
                <TimeRangeButton
                    iconProps={{
                        icon: faCaretLeft,
                        transform: "grow-6",
                    }}
                    title={"Go back in time " + timeRangePeriod.halfPeriod}
                    timeRange={shiftTimeRange(urlTimeRange, "back", "half")}
                />
            </ButtonGroup>

            <Button
                onClick={handleClick}
                className={styles.openSelectorButton}
                variant={"tribe-primary"}
                onFocus={
                    /* Otherwise the button is left focused after you cancel */
                    (e) => e.target.blur()
                }
            >
                <FormattedDateTime value={urlTimeRange.start} />
                <FontAwesomeIcon
                    icon={faArrowRight}
                    className={styles.fromToArrow}
                />
                <FormattedDateTime
                    value={justBeforeIfMidnight(urlTimeRange.end)}
                />
                <TextWithTooltip name={"Timeline Navigation"} label={""} />
            </Button>

            <ButtonGroup>
                <TimeRangeButton
                    iconProps={{
                        icon: faCaretRight,
                        transform: "grow-6",
                    }}
                    title={"Go forward in time " + timeRangePeriod.halfPeriod}
                    timeRange={shiftTimeRange(urlTimeRange, "forward", "half")}
                />
                <TimeRangeButton
                    iconProps={{
                        icon: faForward,
                        transform: "grow-3",
                    }}
                    title={"Go forward in time " + timeRangePeriod.period}
                    timeRange={shiftTimeRange(urlTimeRange, "forward", "full")}
                />
                <TimeRangeButton
                    iconProps={{
                        icon: faStepForward,
                        transform: "grow-3",
                    }}
                    title={"Jump to now"}
                    timeRange={getNowRange(urlTimeRange)}
                />
            </ButtonGroup>

            {showTimeRangeSelector && (
                <TimeRangeSelector onClose={hideSelector} />
            )}
        </>
    );
}
