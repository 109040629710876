/**
 * @generated SignedSource<<73abbe6097631befc3e378eaeed73ff4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserJourney_UJsQuery$variables = Record<PropertyKey, never>;
export type UserJourney_UJsQuery$data = {
  readonly currentUser: {
    readonly isAdmin: boolean;
  };
  readonly userJourneys: ReadonlyArray<{
    readonly name: string;
    readonly ujID: number;
  }>;
};
export type UserJourney_UJsQuery = {
  response: UserJourney_UJsQuery$data;
  variables: UserJourney_UJsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ujID",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserJourney_UJsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserJourney",
        "kind": "LinkedField",
        "name": "userJourneys",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserJourney_UJsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserJourney",
        "kind": "LinkedField",
        "name": "userJourneys",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f21c2f4e7a4faf0c650dfa11cd89da90",
    "id": null,
    "metadata": {},
    "name": "UserJourney_UJsQuery",
    "operationKind": "query",
    "text": "query UserJourney_UJsQuery {\n  currentUser {\n    isAdmin\n    id\n  }\n  userJourneys {\n    ujID\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "66c2c5acdd346a7f87873967f3a566b9";

export default node;
